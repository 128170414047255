import { render, staticRenderFns } from "./CampaignNotesBasic.vue?vue&type=template&id=1567c282&functional=true"
import script from "./CampaignNotesBasic.vue?vue&type=script&lang=js"
export * from "./CampaignNotesBasic.vue?vue&type=script&lang=js"
import style0 from "./CampaignNotesBasic.vue?vue&type=style&index=0&id=1567c282&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21_prettier@3.5.3_react@17.0.2_webpack@5.98.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports