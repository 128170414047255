import { render, staticRenderFns } from "./SiteChangePassword.vue?vue&type=template&id=ef71b62c&scoped=true"
import script from "./SiteChangePassword.vue?vue&type=script&lang=js"
export * from "./SiteChangePassword.vue?vue&type=script&lang=js"
import style0 from "./SiteChangePassword.vue?vue&type=style&index=0&id=ef71b62c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21_prettier@3.5.3_react@17.0.2_webpack@5.98.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef71b62c",
  null
  
)

export default component.exports