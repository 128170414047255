<template>
  <GlobalNav
    :title="title"
    @click-title="titleClick"
    :prefix-title-actions="prefixTitleActions"
    :current-resource="!mobile ? resources : null"
    :theme="theUser.isCdpEnabled ? 'light' : 'dark'"
    :layoutMode="theUser.isCdpEnabled ? 'alternate' : 'default'"
    :current-language="defaultLanguage"
    :languages="supportedLanguages"
    @change-language="code => defaultLanguage = code"
    :accounts="!mobile ? accounts : []"
    @change-account="accountIdChanged"
    :account="accountDetails"
    :service-sections="!mobile ? serviceSections : null"
    :notifications="!mobile ? formattedNotifications : null"
    :i18n="i18n"
    :logo="theUser.isCdpEnabled && !onLandingPage ? false : true"
  />
</template>

<script>

import Env from '../classes/utils/Env'
import Utils from '../classes/utils/Utils'
import {dayjs,mysqlFormat} from '../classes/utils/DateUtil'
const SLOW_PROCESS_PING_INTERVAL = 5 * 60 * 1000 // 5 mins
// const SLOW_PROCESS_PING_INTERVAL = 15 * 1000 // 15 seconds
const FAST_PROCESS_PING_INTERVAL = 2 * 1000 // 2 seconds

export default {
  name: 'TopNav',
  data() {
    return {
      accountToSwitchToId: this.theUser && this.theUser.accountId,
      defaultLanguage: this.theUser && this.theUser.defaultLanguage,
      refreshChannel: null,
      Env: Env,
      // background processes
      processes: [],
      dismissingNotification: false,
      backgroundProcessTimerHandle: null,
      browserTimezone: null,
      processTypeMap: {
        'CAMPAIGN_COPY': this.$t('BackgroundProcesses.campaign.copyCampaign'),
        'CONVERSIONS_DOWNLOAD' : this.$t('BackgroundProcesses.conversionsDownload'),
        'EMAILS_DOWNLOAD': this.$t('BackgroundProcesses.emailsDownload'),
        'FORM_RESPONSES_DOWNLOAD': this.$t('BackgroundProcesses.formResponsesDownload'),
        'TRANSACTIONS_DOWNLOAD': this.$t('BackgroundProcesses.transactionsDownload'),
        'PRODUCT_LIST_DOWNLOAD': this.$t('BackgroundProcesses.productListDownload'),
        'MERCH_LIST_EVALUATION': this.$t('BackgroundProcesses.merch.merchListEvaluation')
      }
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    titleClick: {
      type: Function,
      default: () => {},
    },
    resources: {
      type: String,
      default: '',
    },
    mobile:Boolean
  },
  created() {
    if ('BroadcastChannel' in self) {
      this.refreshChannel = new BroadcastChannel('refresh')
    }

    this.eventHub.$on('startBackgroundProcess', () => {
      // console.log('eventhub trigger background process')
      // bit of a hack to give the backend enough time to create process
      setTimeout(this.checkBackendProcesses, 200) 
    })
    this.checkBackendProcesses()
  },
  beforeDestroy() {
    this.eventHub.$off('startBackgroundProcess')
    if (this.backgroundProcessTimerHandle) clearTimeout(this.backgroundProcessTimerHandle)
  },
  async mounted() {
    try {
      this.browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone || this.theUser.accountTimezone
    } catch {
      this.browserTimezone = this.theUser.accountTimezone
    }
    this.accountToSwitchToId = this.theUser.onBehalfOfAccountId

    this.defaultLanguage = this.theUser.defaultLanguage

    // set lang for embedded insights (notifications)
    if (window.INSIGHTS_CONFIG) {
      const lang = this.defaultLanguage || 'en'
      const country = this.getAccountCountry() || 'US'
      window.INSIGHTS_CONFIG.locale = `${lang}-${country}`
    }

    this.$watch('defaultLanguage', async (newVal) => {

      // set lang for embedded insights (notifications)
      if (window.INSIGHTS_CONFIG) {
        const country = this.getAccountCountry() || 'US'
        window.INSIGHTS_CONFIG.locale = `${newVal || 'en'}-${country}`
      }

      this.$ui.progress.start()
      await this.setLanguage(newVal)
      this.theUser.defaultLanguage = newVal

      // save
      await this.api.updateUserLanguage(newVal)

      window.localStorage['portal-language'] = newVal

      // tell other windows to refresh
      if (this.refreshChannel) {
        this.refreshChannel.postMessage({
          hard: false
        })
      }

      // for non dev force reload so things like insights embedded and rulesets are relaoded
      if (!Env.isDevelopment()) {
        window.location.reload()
      }

      this.$ui.progress.done()
    })

    // allows us to preview translations
    if (this.refreshChannel) {
      this.refreshChannel.onmessage = (e) => {
        window.location.reload(e.data.hard)
      }
    }
  },
  methods: {
    chooseLanguage(lang) {
      this.defaultLanguage = lang
    },
    getAccountCountry() {
      return localStorage.getItem('portal-account-country') || 'US'
    },
    accountIdChanged(acc) {
      if (this.mobile) return;
      const id = acc.id
      this.doSwitchNow(id)
    },
    async logout() {
      await this.api.logout()
      this.$router.push('/site/login/')
    },
    async doSwitchNow(accountId) {
      this.$ui.progress.start()
      this.$ui.loader.show(this.$t('creative.loader.switchingAccounts'))
      this.api.switchAccount(accountId)
      .then(async () => {
        await this.doRefreshToken()
        this.accountToSwitchToId = accountId
        const reroutes = {
          '/campaigns/': {
            to: '/campaigns/',
            strict: true
          },
          '/sessionViewer/': {
            to: '/account/conversions/'
          }
        }

        let newRoute = ''
        for (let key of Object.keys(reroutes)) {
          const route = reroutes[key]
          if (route.strict) {
            if (this.$route.path.indexOf(key) === 0 && this.$route.path !== key) {
              newRoute = route.to
              break
            }
          } else {
            if (this.$route.path.indexOf(key) === 0) {
              newRoute = route.to
              break
            }
          }
        }

        if (newRoute) {
          this.$router.push(newRoute)
        } else {
          // HACK: seems to fail in safari? so replaced with window reload
          // but only fails in production ... do not understand!
          // this.$router.go()
          window.location.reload()
        }

        // tell other windows to refresh
        if (this.refreshChannel) {
          this.refreshChannel.postMessage({
            hard: false
          })
        }
      })
      .catch(this.apiCatch)
      .finally(() => {
        this.$ui.progress.done()
      })
    },

    // --------
    // backend process stuff
    checkBackendProcesses() {
      this.api.pingProcessStatus()
        .then((processes) => {
          this.checkEmitDataUpdate(processes)
          this.processes = Utils.sort(processes||[], ['createdOn'], ['desc'])
        })
        .catch(this.apiCatch)
        .finally(() => {
          this.resetProcessPing()
        })
    },
    checkEmitDataUpdate(newProcs) {
      if (!newProcs) return;
      const oldProcs = this.processes
      for (const oldProc of oldProcs) {
        const newProc = newProcs.find(p => p.id === oldProc.id)
        if (newProc && newProc.status === 'task-complete' && oldProc.status !== 'task-complete') {
          // console.log('emit data update', newProc, oldProc)
          this.eventHub.$emit(`background_process:${newProc.type}`)
        }
      }
    },
    resetProcessPing() {
      if (this.backgroundProcessTimerHandle) clearTimeout(this.backgroundProcessTimerHandle)
      this.backgroundProcessTimerHandle = this.activeProcessesExist 
        ? setTimeout(this.checkBackendProcesses, FAST_PROCESS_PING_INTERVAL)
        : setTimeout(this.checkBackendProcesses, SLOW_PROCESS_PING_INTERVAL)
      // console.log('reset timeout', this.activeProcessesExist ? 'fast' : 'slow')
    },  
    goToCampaignUrl(jsonData) {
      const url = jsonData.id ? `/campaigns/${jsonData.id}/details` : `/campaigns` 
      if (url) {
        let routeData = this.$router.resolve(url);
        window.open(routeData.href, '_blank');
      }
    },
    goToMerchLists() {
      let routeData = this.$router.resolve(`/merchandising/product-lists`)
      window.open(routeData.href, '_blank')
    },
    downloadFile(jsonData) {
      if (jsonData.url) {
        window.open(jsonData.url)
      }
    },
    dismissNotification(procId) {
      this.dismissingNotification = true
      this.processes = this.processes.filter(p => p.id !== procId)
      this.api.dismissBackgroundProcess(procId)
        .then(() => {
          this.resetProcessPing()
          this.dismissingNotification = false
        })
        .catch(this.apiCatch)
    },
    goHome() {
      this.$router.push('/home/')
    }
  },
  computed: {
    prefixTitleActions() {
      if (this.$route.name === 'Home') return []
      return [
        {
          label: this.$t('Home'),
          // icon: 'fas fa-home',
          onAction: () => this.goHome()
        }
      ]
    },
    onLandingPage() {
      return this.$route.name === 'Home'
    },
    accounts() {
      const accounts = []
      if (this.theUser.isSuper) {
        accounts.push({
          id: this.theUser.accountId,
          name: this.$t('Super Account')
        })
      }
      if (this.theUser.isAgency) {
        accounts.push({
          id: this.theUser.accountId,
          name: this.$t('Agency Account')
        })
      }
      if (this.theUser.isMultiAccount && !this.theUser.isSuper) {
        if (!this.multiAccounts.length) return []
        const primaryIsSmart = this.theUser.isSmartEnabled && this.theUser.accountId === this.theUser.onBehalfOfAccountId ||
          this.theUser.isCdpEnabled && this.theUser.accountId !== this.theUser.onBehalfOfAccountId
        return [{
          id: this.theUser.accountId,
          name: this.$t(primaryIsSmart ? this.$t('Fanplayr Targeting') : this.$t('Fanplayr 360'))
        }, ...this.multiAccounts.map(a => {
          return {
            id: a.id,
            name: this.$t(primaryIsSmart ? this.$t('Fanplayr 360') : this.$t('Fanplayr Targeting'))
          }
        })]
      }
      return [...accounts, ...this.agencyAccounts]
    },
    accountDetails() {
      const otherItems = !this.mobile ? [
        {
          label: this.$t('Documentation'),
          icon: 'fa fa-book',
          onAction: () => window.open(
            this.theUser.isCdpEnabled ? 'https://docs.fanplayr.com/fanplayr-360' : 'https://docs.fanplayr.com/portal'
          )
        },
        {
          label: this.$t('Help & Support'),
          icon: 'fa fa-life-ring',
          url: '/support/'
        },
        {
          label: this.$t('About'),
          icon: 'fa fa-info-circle',
          url: '/about/'
        }
      ] : []
      if (this.theUser && this.theUser.isSuper) {
        if (!this.mobile) {
          otherItems.unshift({
            label: this.$t('Translations'),
            icon: 'far fa-language',
            url: '/translations'
          })
        }
        if (this.onMobileDevice) {
          otherItems.unshift({
            label: this.mobile ? this.$t('nav.useDesktopVersion') : this.$t('nav.useMobileVersion'),
            icon: 'fa fa-mobile',
            onAction: () => this.$emit('update-mobile', !this.mobile)
          })
        }
      }
      const sections = [
        {
          items: otherItems
        },
        {
          items: [
            {
              label: this.$t('Logout'),
              icon: 'fa fa-sign-out',
              url: '/logout/'
            }
          ]
        }
      ]
      if (!this.mobile) {
        sections.unshift({
          items: [
            {
              label: this.$t('Account'),
              icon: 'fa fa-sliders-h',
              url: '/account/profile'
            }
          ]
        })
      }
      return {
        accountId: this.theUser.onBehalfOfAccountId,
        accountName: this.theUser.accountName,
        userName: `${this.theUser.firstName} ${this.theUser.lastName}`,
        userEmail: this.theUser.email,
        avatarUrl: this.theUser.logoUrl,
        sections
      }
    },
    serviceSections() {
      const sections = []
      
      const coreServiceItems = []
      if (this.theUser.isCdpEnabled) {
        coreServiceItems.push({
          id: 'cdp',
          label: this.$t('Fanplayr 360'),
          icon: 'fa-database',
          url: {name: 'CdpLanding'},
          color: '#ee4570',
        })
      }
      // coreServiceItems.push({
      //   id: 'insights-settings',
      //   label: this.$t('insightsSettings'),
      //   description: this.$t('nav.insightsSettingsDesc'),
      //   icon: 'fa-filter',
      //   url: {name: 'InsightsSettingsLayout'}
      // })
      if (this.theUser.isSmartEnabled) {
        coreServiceItems.push({
          id: 'insights',
          label: this.$t('Insights'),
          description: this.$t('nav.insightsDesc'),
          icon: 'fa-chart-line',
          url: {name: 'Insights'}
        })
        if (this.theUser.hasCampaignAccess) {
          coreServiceItems.push({
            id: 'campaigns',
            label: this.$t('Campaigns'),
            description: this.$t('nav.campaignDesc'),
            icon: 'fa-bullseye-pointer',
            url: {name: 'Campaigns'}
          })
        }
        if (
          (this.theUser.webPushEnabled && this.theUser.hasNotificationAccess) ||
          (this.theUser.smsEnabled && this.theUser.hasSmsAccess))
        {
          coreServiceItems.push({
            id: 'messaging',
            label: this.$t('Messaging'),
            description: this.$t('nav.messagingDesc'),
            icon: 'fa-comment-alt-lines',
            url: {name: 'Messaging'}
          })
        }
        if (this.theUser.hasCreativeAccess) {
          coreServiceItems.push({
            id: 'creative',
            label: this.$t('Creative Editor'),
            description: this.$t('nav.creativeDesc'),
            icon: 'fa-pencil-ruler',
            url: {path: '/creative', external: true}
          })
        }
      }
      if (this.theUser.privacyIdEnabled && this.theUser.hasPrivacyIdAccess) {
        coreServiceItems.push({
          id: 'privacy-id',
          label: this.$t('PrivacyID'),
          description: this.$t('nav.privacyIdDesc'),
          icon: 'fa-shield',
          url: {name: 'PrivacyIdLayout'},
        })
      }
      if (this.theUser.isSmartEnabled) {
        if (this.theUser.recommendationsEnabled && this.theUser.hasRecommendationsAccess) {
          coreServiceItems.push({
            id: 'merchandising',
            label: this.$t('merch.merchandising'),
            description: this.$t('nav.merchDesc'),
            icon: 'fa-tshirt',
            url: {name: 'MerchandisingLayout'}
          })
        }
        if (this.theUser.isSmartEnabled) {
          coreServiceItems.push({
            id: 'streams',
            label: this.$t('Streams'),
            description: this.$t('nav.streamsDesc'),
            icon: 'fa-exchange',
            url: {name: 'StreamsLayout'}
          })
        }
      }
      if (coreServiceItems.length) {
        sections.push({
          title: this.$t('Core services'),
          items: coreServiceItems
        })
      }

      const managementServiceItems = []
      if (this.theUser.isSmartEnabled && this.theUser.hasTransactionAccess) {
        managementServiceItems.push({
          id: 'conversions',
          label: this.$t('Conversions'),
          description: this.$t('nav.conversionsDesc'),
          icon: 'fa-search-dollar',
          url: {name: 'AccountConversions'}
        })
      }
      if (this.theUser.isSmartEnabled) {
        managementServiceItems.push({
          id: 'shared-rulesets',
          label: this.$t('Shared Rulesets'),
          description: this.$t('nav.sharedRulesetsDesc'),
          icon: 'fa-clipboard-list-check',
          url: {name: 'AccountSharedRulesetsList'}
        },{
          id: 'form-responses',
          label: this.$t('Form Responses'),
          description: this.$t('nav.formResponsesDesc'),
          icon: 'fa-inbox',
          url: {name: 'AccountFormResponses'}
        })
      }
      if (this.theUser.isSmartEnabled && this.theUser.hasInvoiceAccess) {
        managementServiceItems.push({
          id: 'invoices',
          label: this.$t('Invoices'),
          description: this.$t('nav.invoicesDesc'),
          icon: 'fa-receipt',
          url: {name: 'AccountInvoices'}
        })
      }
      if (managementServiceItems.length) {
        sections.push({
          title: this.$t('Management'),
          items: managementServiceItems
        })
      }
      const prefItems = [
        {
          id: 'settings',
          label: this.$t('Account Settings'),
          icon: 'fa-cog',
          type: 'link',
          url: {name: 'AccountLayout'}
        }
      ]
      if (this.theUser.isCdpEnabled && this.theUser.isSuper) {
        prefItems.push({
          id: 'cdpOnboarding',
          label: this.$t('cdp.360Onboarding'),
          icon: 'fa-map',
          type: 'link',
          url: {name: 'CdpOnboarding'},
          color: '#ee4570',
        })
      }
      sections.push({
        title: this.theUser.isCdpEnabled ? this.$t('Manage') : this.$t('Preferences'),
        items: prefItems
      })

      return sections
    },
    i18n() {
      return {
        // "Services"
        services: this.$t('nav.services'),
        // "No notifications"
        noNotifications: this.$t('NotificationsApp.No notifications'),
        // Translations for save bar
        saveBar: {
          // Save action label
          save: this.$t('Save'),
          // Discard action label
          discard: this.$t('Discard'),
          // Save bar title when there are changes
          unsavedChanges: this.$t('Unsaved Changes'),
          // Save bar title while saving
          saving: this.$t('Saving'),
          // Translations for confirm discard modal
          discardModal: {
            title: this.$t('nav.discardAllUnsavedChanges'),
            content: this.$t('nav.discardChangesMessage'),
            primaryActionLabel: this.$t('Discard changes'),
            secondaryActionLabel: this.$t('Continue editing')
          }
        }
      }
    },
    activeProcessesExist() {
      if (!this.processes) return false
      return this.processes.some(n => {
        return n.status === 'task-pending'
      })
    },
    formattedNotifications() {
      return this.processes
        .map(p => {
          let jsonData = {}
          try {
            jsonData = p.data ? JSON.parse(p.data) : {}
          } catch {
            // nothing
          }
          let title = this.processTypeMap[p.type]
          let description = null
          let actions = [{
            label: this.$t('Dismiss'),
            onAction: () => this.dismissNotification(p.id),
            disabled: this.dismissingNotification
          }]
          if (p.type === 'CAMPAIGN_COPY') {
            description = jsonData.name || ''
            if (p.status === 'task-complete' && jsonData.id) {
              actions.unshift({
                label: this.$t('View'),
                onAction: () => this.goToCampaignUrl(jsonData)
              })
            }
          } else if (p.type === 'CONVERSIONS_DOWNLOAD' 
            || p.type === 'EMAILS_DOWNLOAD' 
            || p.type === 'FORM_RESPONSES_DOWNLOAD' 
            || p.type === 'TRANSACTIONS_DOWNLOAD'
            || p.type === 'PRODUCT_LIST_DOWNLOAD'
          ) {
            if (p.status === 'task-complete' && jsonData.url) {
              actions.unshift({
                label: this.$t('Download'),
                onAction: () => this.downloadFile(jsonData)
              })
            }
          } else if (p.type === 'MERCH_LIST_EVALUATION') {
            if (p.status === 'task-complete') {
              actions.unshift({
                label: this.$t('View'),
                onAction: this.goToMerchLists,
              })
            }
          }
          return {
            status: p.status,
            date: dayjs.tz(p.createdOn, this.theUser.accountTimezone)
              .tz(this.browserTimezone)
              .format(mysqlFormat),
            title,
            description,
            badge: p.status === 'task-complete' ? this.$t('Complete') : null,
            actions
          }
        })
    }
  }
}
</script>