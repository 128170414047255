import { render, staticRenderFns } from "./PhoneInput.vue?vue&type=template&id=7ed3c402"
import script from "./PhoneInput.vue?vue&type=script&lang=js"
export * from "./PhoneInput.vue?vue&type=script&lang=js"
import style0 from "./PhoneInput.vue?vue&type=style&index=0&id=7ed3c402&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21_prettier@3.5.3_react@17.0.2_webpack@5.98.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports