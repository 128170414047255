import { render, staticRenderFns } from "./SiteLayout.vue?vue&type=template&id=d78f53b4&scoped=true"
var script = {}
import style0 from "./SiteLayout.vue?vue&type=style&index=0&id=d78f53b4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21_prettier@3.5.3_react@17.0.2_webpack@5.98.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d78f53b4",
  null
  
)

export default component.exports