import Vue from 'vue'
import Router from 'vue-router'

// -----------------------------------------
// Site
import SiteLayout from '@/layouts/SiteLayout'
//
import SiteRegister from '@/pages/SiteRegister'
import SiteForgotPassword from '@/pages/SiteForgotPassword'
import SiteChangePassword from '@/pages/SiteChangePassword'
import SiteLogin from '@/pages/SiteLogin'
import SiteReportsLogin from '@/pages/SiteReportsLogin'

// -----------------------------------------
// Other Layout
import InsightsLayout from '@/layouts/InsightsLayout'
import AboutLayout from '@/layouts/AboutLayout'
import SupportLayout from '@/layouts/SupportLayout'
import LogoutLayout from '@/layouts/LogoutLayout'
import RedirectLoginLayout from '@/layouts/RedirectLoginLayout'
import Error404 from '@/pages/404'
import PrintLayout from '@/layouts/PrintLayout'
import OnboarderPrint from '@/pages/cdp/OnboarderPrint'
import ResourceLink from '@/pages/ResourceLink'

// -----------------------------------------
// Session Viewer
const SessionViewerLayout = () => import(/* webpackChunkName: "SessionViewerSection" */ '@/layouts/SessionViewerLayout')

// -----------------------------------------
// Insights Settings
const InsightsSettingsLayout = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/layouts/InsightsSettingsLayout')
//
const InsightsSettingsView = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsView')
const InsightsSettingsViewList = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsViewList')
const InsightsSettingsViewEdit = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsViewEdit')
const InsightsSettingsClick = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsClick')
const InsightsSettingsClickList = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsClickList')
const InsightsSettingsClickEdit = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsClickEdit')
const InsightsSettingsStrategy = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsStrategy')
const InsightsSettingsStrategyList = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsStrategyList')
const InsightsSettingsStrategyEdit = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsStrategyEdit')
const InsightsSettingsFunnel = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsFunnel')
const InsightsSettingsFunnelList = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsFunnelList')
const InsightsSettingsFunnelEdit = () => import(/* webpackChunkName: "InsightsSettingsSection" */ '@/pages/insightsSettings/InsightsSettingsFunnelEdit')

// -----------------------------------------
// Customer Data Platform

const CdpLayout = () => import(/* webpackChunkName: "CdpSection" */ '@/layouts/CdpLayout')
const CdpOnboardingLayout = () => import(/* webpackChunkName: "CdpSection" */ '@/layouts/CdpOnboardingLayout')
//
const CdpLanding = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Landing')
const Welcome = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Welcome')
const CdpInsights = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Insights')
const IntegrationCatalog = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/IntegrationCatalog')
const IntegrationCatalogDetails = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/IntegrationCatalogDetails')
const DestinationList = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/DestinationList')
const DestinationEdit = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/DestinationEdit')
const SourceList = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/SourceList')
const SourceEdit = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/SourceEdit')
const AudienceList = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/AudienceList')
const AudienceEdit = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/AudienceEdit')
const PipelineList = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/PipelineList')
const PipelineEdit = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/PipelineEdit')
const ExportList = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/ExportList')
const ExportEdit = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/ExportEdit')
const DataDictionary = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/DataDictionary')
const Mapping = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Mapping')
const CdpCompliance = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Compliance')
const CdpDataHealth = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/DataHealth')
const Profile = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Profile')
const Profiles = () => import(/* webpackChunkName: "CdpSection" */ '@/pages/cdp/Profiles')


// -----------------------------------------
// Landing Page
const LandingLayout = () => import(/* webpackChunkName: "LandingSection" */ '@/layouts/LandingLayout')

// -----------------------------------------
// Campaigns List
const CampaignsList = () => import(/* webpackChunkName: "CampaignListSection" */ '@/layouts/CampaignsList')

// -----------------------------------------
// Campaigns Edit
const CampaignEditLayout = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/layouts/CampaignEditLayout')
//
const CampaignEditDetails = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/CampaignEditDetails')

const CampaignEditOffers = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/offers/CampaignEditOffers')
const CampaignEditOffersList = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/offers/CampaignEditOffersList')
const CampaignEditOffersEdit = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/offers/CampaignEditOffersEdit')
const OfferBulkEdit = () => import(/* webpackChunName: "OfferBulkEdit" */ '@/pages/offers/OfferBulkEdit')

const CampaignEditSegmentation = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentation/CampaignEditSegmentation')
const CampaignEditSegmentationList = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentation/CampaignEditSegmentationList')
const CampaignEditSegmentationEdit = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentation/CampaignEditSegmentationEdit')
const SegmentationBulkEdit = () => import(/* webpackChunkName: "SegmentationBulkEdit" */ '@/pages/segmentation/SegmentationBulkEdit')

const CampaignEditSegmentActions = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentActions/CampaignEditSegmentActions')
const CampaignEditSegmentActionsList = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentActions/CampaignEditSegmentActionsList')
const CampaignEditSegmentActionsEdit = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentActions/CampaignEditSegmentActionsEdit')
const SegmentActionBulkEdit = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/segmentActions/SegmentActionBulkEdit')

const CampaignEditExperiments = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/experiments/CampaignEditExperiments')
const CampaignEditExperimentsList = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/experiments/CampaignEditExperimentsList')
const CampaignEditExperimentsEdit = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/experiments/CampaignEditExperimentsEdit')
const CampaignEditExperimentBulkEdit = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/experiments/CampaignEditExperimentBulkEdit')

const CampaignEditDomains = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/CampaignEditDomains')
const CampaignEditOptions = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/CampaignEditOptions')
const CampaignEditValidation = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/CampaignEditValidation')
const CampaignEditPublishWrapper = () => import(/* webpackChunkName: "CampaignEditSection" */ '@/pages/CampaignEditPublishWrapper')

// -----------------------------------------
// Account
const AccountLayout = () => import(/* webpackChunkName: "AccountSection" */ '@/layouts/AccountLayout')
//
const AccountProfile = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountProfile')
const AccountChangePassword = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountChangePassword')
const AccountCompany = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountCompany')
const AccountUsers = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountUsers')
const AccountAgency = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountAgency')
const AccountPreferences = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountPreferences')
const AccountCdpSettings = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/cdp/AccountCdpSettings')
const AccountSecurity = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountSecurity')
const AccountShopLinks = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountShopLinks')
const AccountDomains = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountDomains')
const AccountTransactions = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountTransactions')
const AccountConversions = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountConversions')
const AccountInvoices = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountInvoices')
const AccountUsageReports = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountUsageReports')
const AccountSharedRulesetsList = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountSharedRulesetsList')
const AccountSharedRulesetsEdit = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountSharedRulesetsEdit')
const AccountFormResponses = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountFormResponses')
const AccountCustomData = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountCustomData')
const AccountSummaryEmails = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountSummaryEmails')
const AccountExportEmails = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountExportEmails')
const AccountDownloads = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountDownloads')
const AccountScheduledReportsList = () => import(/* webpackChunkName: "AccountScheduledReports" */ '@/pages/AccountScheduledReportsList')
const AccountScheduledReportsEdit = () => import(/* webpackChunkName: "AccountScheduledReports" */ '@/pages/AccountScheduledReportsEdit')
const AccountScheduledReportsRuns = () => import(/* webpackChunkName: "AccountScheduledReports" */ '@/pages/AccountScheduledReportsRuns')
const AccountConversionDefinitions = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountConversionDefinitions')
const AccountAgencyReporting = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountAgencyReporting')
const AccountCampaignExceptions = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountCampaignExceptions')
const AccountAuditLog = () => import(/* webpackChunkName: "AccountSection" */ '@/pages/AccountAuditLog')

// -----------------------------------------
// Notifications
const MessagingApp = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/App.vue')
const MessagingDashboard = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/Dashboard.vue')
const NotificationsEdit = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/NotificationsEdit.vue')
const NotificationsList = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/NotificationsList.vue')
const NotificationsSettings = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/NotificationsSettings.vue')
const SmsEdit = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/SmsEdit.vue')
const SmsList = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/SmsList.vue')
const SmsSettings = () => import(/* webpackChunkName: "MessagingSection" */ '@/apps/messaging/views/SmsSettings.vue')

// -----------------------------------------
// PrivacyID
const PrivacyIdLayout = () => import(/* webpackChunkName: "PrivacySection" */ '@/layouts/PrivacyIdLayout')
const PrivacyIdDashboard = () => import(/* webpackChunkName: "PrivacySection" */ '@/pages/PrivacyIdDashboard')
const PrivacyIdSites = () => import(/* webpackChunkName: "PrivacySection" */ '@/pages/PrivacyIdSites')
const PrivacyIdScripts = () => import(/* webpackChunkName: "PrivacySection" */ '@/pages/PrivacyIdScripts')
const PrivacyIdSetup = () => import(/* webpackChunkName: "PrivacySection" */ '@/pages/PrivacyIdSetup')
const PrivacyIdIntegration = () => import(/* webpackChunkName: "PrivacySection" */ '@/pages/PrivacyIdIntegration')

// -----------------------------------------
// Merchandising
const MerchandisingLayout = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/layouts/MerchandisingLayout')
const MerchandisingDashboard = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingDashboard')
const MerchandisingProductLists = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductLists')
const MerchandisingProductListEdit = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductListEdit')
const MerchandisingProductListProducts = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductListProducts')
const MerchandisingProductRecCatalogs = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductRecCatalogs')
const MerchandisingProductRecCatalogImports = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductRecCatalogImports')
const MerchandisingProductRecCatalogImport = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductRecCatalogImport')
const MerchandisingProductRecCatalogProducts = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductRecCatalogProducts')
const MerchandisingProductRecCatalogEdit = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductRecCatalogEdit')
const MerchandisingProductDisplays = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductDisplays')
const MerchandisingProductDisplayEdit = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingProductDisplayEdit')
const MerchandisingPersonalShoppers = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingPersonalShoppers')
const MerchandisingWidgetApps = () => import(/* webpackChunkName: "MerchandisingSection" */ '@/pages/merchandising/MerchandisingWidgets')


// -----------------------------------------
// Streams
const StreamsLayout = () => import(/* webpackChunkName: "StreamsSection" */ '@/layouts/StreamsLayout')
const StreamsStreams = () => import(/* webpackChunkName: "StreamsSection" */ '@/pages/StreamsStreams')
const StreamsStreamsList = () => import(/* webpackChunkName: "StreamsSection" */ '@/apps/streamApp/components/ListView')
const StreamsStreamsEdit = () => import(/* webpackChunkName: "StreamsSection" */ '@/apps/streamApp/components/EditorView')
const StreamsEndpoints = () => import(/* webpackChunkName: "StreamsSection" */ '@/pages/StreamsEndpoints')

// -----------------------------------------
// Automations CDP
const CdpAutomationsLayout = () => import(/* webpackChunkName: "AutomationsSection" */ '@/layouts/CdpAutomationsLayout')
const CdpAutomationsList = () => import(/* webpackChunkName: "AutomationsSection" */ '@/apps/automationApp/components/ListView')
const CdpAutomationsEdit = () => import(/* webpackChunkName: "AutomationsSection" */ '@/apps/automationApp/components/EditorView')

// -----------------------------------------
// Mobile
const MobileLayout = () => import(/* webpackChunkName: "MobileSection" */ '@/layouts/MobileLayout')
const AccountSwitchMobile = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/AccountSwitchMobile')
const CampaignsListMobile = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignsListMobile')
const CampaignEditMobile = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobile')
const CampaignEditMobileSegmentsList = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileSegmentsList')
const CampaignEditMobileSegment = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileSegment')
const CampaignEditMobileAction = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileAction')
const CampaignEditMobileSegmentActions = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileSegmentActions')
const CampaignEditMobileActionsList = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileActionsList')
const CampaignEditMobileExperiment = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileExperiment')
const CampaignEditMobileSegmentExperiments = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileSegmentExperiments')
const CampaignEditMobileExperimentActions = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileExperimentActions')
const CampaignEditMobileExperimentsList = () => import(/* webpackChunkName: "MobileSection" */ '@/pages/mobile/CampaignEditMobileExperimentsList')

// -----------------------------------------
// Translations
const TranslationsLayout = () => import(/* webpackChunkName: "TranslationsSection" */ '@/layouts/TranslationsLayout')

// -----------------------------------------
const NoSmartLayout = () => import(/* webpackChunkName: "NoSmartSection" */ '@/layouts/NoSmartLayout')

Vue.use(Router)

export default new Router({
  routes: [
    {
      // catch all instead of 404 ?
      path: '*',
      component: Error404,
    },
    {
      path: '/',
      name: 'Base',
      redirect: 'home'
    },
    {
      path: '/insights/',
      name: 'Insights',
      component: InsightsLayout,
    },
    {
      path: '/home',
      name: 'Home',
      component: LandingLayout,
    },
    {
      path: '/campaigns/',
      name: 'Campaigns',
      component: CampaignsList,
    },
    {
      path: '/translations/',
      name: 'Translations',
      component: TranslationsLayout,
    },
    {
      path: '/notEnabled/',
      name: 'NotEnabled',
      component: NoSmartLayout,
    },
    {
      path: '/support/',
      name: 'SupportLayout',
      component: SupportLayout,
    },
    {
      path: '/about/',
      name: 'AboutLayout',
      component: AboutLayout,
    },
    {
      path: '/logout/',
      name: 'LogoutLayout',
      component: LogoutLayout,
    },
    {
      path: '/quick/',
      redirect: '/quick/accounts',
      name: 'MobileLayout',
      component: MobileLayout,
      children: [
        {
          path: 'accounts',
          name: 'AccountSwitchMobile',
          component: AccountSwitchMobile
        },
        {
          path: 'campaigns',
          name: 'CampaignsListMobile',
          component: CampaignsListMobile
        },
        {
          path: 'campaigns/:campaignId',
          name: 'CampaignEditMobile',
          component: CampaignEditMobile
        },
        {
          path: 'campaigns/:campaignId/segments',
          name: 'CampaignEditMobileSegmentsList',
          component: CampaignEditMobileSegmentsList
        },
        {
          path: 'campaigns/:campaignId/segments/:segmentId',
          name: 'CampaignEditMobileSegment',
          component: CampaignEditMobileSegment
        },
        {
          path: 'campaigns/:campaignId/segments/:segmentId/actions',
          name: 'CampaignEditMobileSegmentActions',
          component: CampaignEditMobileSegmentActions
        },
        {
          path: 'campaigns/:campaignId/actions',
          name: 'CampaignEditMobileActionsList',
          component: CampaignEditMobileActionsList
        },
        {
          path: 'campaigns/:campaignId/actions/:actionId',
          name: 'CampaignEditMobileAction',
          component: CampaignEditMobileAction
        },
        {
          path: 'campaigns/:campaignId/segments/:segmentId/experiments',
          name: 'CampaignEditMobileSegmentExperiments',
          component: CampaignEditMobileSegmentExperiments
        },
        {
          path: 'campaigns/:campaignId/experiments',
          name: 'CampaignEditMobileExperimentsList',
          component: CampaignEditMobileExperimentsList
        },
        {
          path: 'campaigns/:campaignId/experiments/:experimentId',
          name: 'CampaignEditMobileExperiment',
          component: CampaignEditMobileExperiment
        },
        {
          path: 'campaigns/:campaignId/experiments/:experimentId/actions',
          name: 'CampaignEditMobileExperimentActions',
          component: CampaignEditMobileExperimentActions
        },
      ]
    },
    {
      path: '/campaigns/:id',
      redirect: '/campaigns/:id/details',
      name: 'CampaignEditLayout',
      component: CampaignEditLayout,
      children: [
        {
          path: 'details',
          name: 'CampaignEditDetails',
          component: CampaignEditDetails
        },
        {
          path: 'offers',
          redirect: '/campaigns/:id/offers/list',
          name: 'CampaignEditOffers',
          component: CampaignEditOffers,
          children: [
            {
              path: 'list',
              name: 'CampaignEditOffersList',
              component: CampaignEditOffersList
            },
            {
              path: 'edit/:offerId',
              name: 'CampaignEditOffersEdit',
              component: CampaignEditOffersEdit
            },
            {
              path: 'create',
              name: 'CampaignEditOffersCreate',
              component: CampaignEditOffersEdit
            },
            {
              path: 'bulkEdit',
              name: 'OfferBulkEdit',
              component: OfferBulkEdit
            }
          ]
        },
        {
          path: 'segment-actions',
          redirect: '/campaigns/:id/segment-actions/list',
          name: 'CampaignEditSegmentActions',
          component: CampaignEditSegmentActions,
          children: [
            {
              path: 'edit/:actionId',
              name: 'CampaignEditSegmentActionsEdit',
              component: CampaignEditSegmentActionsEdit
            },
            {
              path: 'create',
              name: 'CampaignEditSegmentActionsCreate',
              component: CampaignEditSegmentActionsEdit
            },
            {
              path: 'list',
              name: 'CampaignEditSegmentActionsList',
              component: CampaignEditSegmentActionsList
            },
            {
              path: 'bulkEdit',
              name: 'SegmentActionBulkEdit',
              component: SegmentActionBulkEdit
            },
          ]
        },
        {
          path: 'experiments',
          redirect: '/campaigns/:id/experiments/list',
          name: 'CampaignEditExperiments',
          component: CampaignEditExperiments,
          children: [
            {
              path: 'edit/:experimentId',
              name: 'CampaignEditExperimentsEdit',
              component: CampaignEditExperimentsEdit
            },
            {
              path: 'create',
              name: 'CampaignEditExperimentsCreate',
              component: CampaignEditExperimentsEdit
            },
            {
              path: 'list',
              name: 'CampaignEditExperimentsList',
              component: CampaignEditExperimentsList
            },
            {
              path: 'bulkEdit',
              name: 'ExperimentBulkEdit',
              component: CampaignEditExperimentBulkEdit
            },
          ]
        },
        {
          path: 'segmentation',
          redirect: '/campaigns/:id/segmentation/list',
          name: 'CampaignEditSegmentation',
          component: CampaignEditSegmentation,
          children: [
            {
              path: 'segment/:segmentId',
              name: 'CampaignEditSegmentationEdit',
              component: CampaignEditSegmentationEdit
            },
            {
              path: 'create',
              name: 'CampaignEditSegmentationCreate',
              component: CampaignEditSegmentationEdit
            },
            {
              path: 'list',
              name: 'CampaignEditSegmentationList',
              component: CampaignEditSegmentationList
            },
            {
              path: 'bulkEdit',
              name: 'SegmentationBulkEdit',
              component: SegmentationBulkEdit
            }
          ]
        },
        {
          path: 'domains',
          name: 'CampaignEditDomains',
          component: CampaignEditDomains
        },
        {
          path: 'validation',
          name: 'CampaignEditValidation',
          component: CampaignEditValidation
        },
        {
          path: 'options',
          name: 'CampaignEditOptions',
          component: CampaignEditOptions
        },
        {
          path: 'publish',
          name: 'CampaignEditPublish',
          component: CampaignEditPublishWrapper
        },
      ]
    },
    {
      path: '/insights-settings',
      redirect: '/insights-settings/view',
      name: 'InsightsSettingsLayout',
      component: InsightsSettingsLayout,
      children: [
        {
          path: 'view',
          redirect: '/insights-settings/view/list',
          name: 'InsightsSettingsView',
          component: InsightsSettingsView,
          children: [
            {
              path: 'list',
              name: 'InsightsSettingsViewList',
              component: InsightsSettingsViewList
            },
            {
              path: 'edit/:viewId',
              name: 'InsightsSettingsViewEdit',
              component: InsightsSettingsViewEdit
            },
            {
              path: 'create',
              name: 'InsightsSettingsViewCreate',
              component: InsightsSettingsViewEdit
            }
          ]
        },
        {
          path: 'click',
          redirect: '/insights-settings/click/list',
          name: 'InsightsSettingsClick',
          component: InsightsSettingsClick,
          children: [
            {
              path: 'list',
              name: 'InsightsSettingsClickList',
              component: InsightsSettingsClickList
            },
            {
              path: 'edit/:clickId',
              name: 'InsightsSettingsClickEdit',
              component: InsightsSettingsClickEdit
            },
            {
              path: 'create',
              name: 'InsightsSettingsClickCreate',
              component: InsightsSettingsClickEdit
            }
          ]
        },
        {
          path: 'strategy',
          redirect: '/insights-settings/strategy/list',
          name: 'InsightsSettingsStrategy',
          component: InsightsSettingsStrategy,
          children: [
            {
              path: 'list',
              name: 'InsightsSettingsStrategyList',
              component: InsightsSettingsStrategyList
            },
            {
              path: 'edit/:strategyId',
              name: 'InsightsSettingsStrategyEdit',
              component: InsightsSettingsStrategyEdit
            },
            {
              path: 'create',
              name: 'InsightsSettingsStrategyCreate',
              component: InsightsSettingsStrategyEdit
            }
          ]
        },
        {
          path: 'funnel',
          redirect: '/insights-settings/funnel/list',
          name: 'InsightsSettingsFunnel',
          component: InsightsSettingsFunnel,
          children: [
            {
              path: 'list',
              name: 'InsightsSettingsFunnelList',
              component: InsightsSettingsFunnelList
            },
            {
              path: 'edit/:funnelId',
              name: 'InsightsSettingsFunnelEdit',
              component: InsightsSettingsFunnelEdit
            },
            {
              path: 'create',
              name: 'InsightsSettingsFunnelCreate',
              component: InsightsSettingsFunnelEdit
            }
          ]
        },
        {
          path: 'conversion-definitions',
          name: 'InsightsSettingsConversionDefinitions',
          component: AccountConversionDefinitions
        },
      ]
    },
    {
      path: '/messaging',
      redirect: '/messaging/dashboard',
      name: 'Messaging',
      component: MessagingApp,
      children: [
        {
          path: 'dashboard',
          name: 'MessagingDashboard',
          component: MessagingDashboard
        },
        {
          path: 'sms/edit/id/:id/',
          redirect: 'sms/edit/id/:id/page/filters/'
        },
        {
          path: 'sms/edit/id/:id/page/:page/',
          name: 'SmsEdit',
          component: SmsEdit,
        },
        {
          path: 'sms/list/type/manual/',
          redirect: 'sms/list/type/manual/status/draft/',
        },
        {
          path: 'sms/list/',
          redirect: 'sms/list/type/manual/',
        },
        {
          path: 'sms/list/type/manual/',
          redirect: 'sms/list/type/manual/status/draft/',
        },
        {
          path: 'sms/list/type/template/',
          redirect: 'sms/list/type/template/status/draft/',
        },
        {
          path: 'sms/list/type/:type/status/:status/',
          name: 'SmsList',
          component: SmsList,
        },
        {
          path: 'sms/settings',
          name: 'SmsSettings',
          component: SmsSettings
        },
        {
          path: 'notifications/edit/id/:id/',
          name: 'NotificationsEditBase',
          redirect: 'notifications/edit/id/:id/page/filters/'
        },
        {
          path: 'notifications/edit/id/:id/page/:page/',
          name: 'NotificationsEdit',
          component: NotificationsEdit,
        },
        {
          path: 'notifications/list/',
          redirect: 'notifications/list/type/manual/',
        },
        {
          path: 'notifications/list/type/manual/',
          redirect: 'notifications/list/type/manual/status/draft/',
        },
        {
          path: 'notifications/list/type/template/',
          redirect: 'notifications/list/type/template/status/draft/',
        },
        {
          path: 'notifications/list/type/:type/status/:status/',
          name: 'NotificationsList',
          component: NotificationsList,
        },
        {
          path: 'notifications/list-triggered/',
          redirect: 'notifications/list-triggered/status/draft/',
        },
        {
          path: 'notifications/settings/',
          name: 'NotificationsSettings',
          component: NotificationsSettings,
        }
      ]
    },
    {
      path: '/merchandising',
      redirect: '/merchandising/dashboard',
      name: 'MerchandisingLayout',
      component: MerchandisingLayout,
      children: [
        {
          path: 'dashboard',
          name: 'MerchandisingDashboard',
          component: MerchandisingDashboard
        },
        {
          path: 'product-lists',
          name: 'MerchandisingProductLists',
          component: MerchandisingProductLists
        },
        {
          path: 'product-lists/:listId/edit',
          name: 'MerchandisingProductListEdit',
          component: MerchandisingProductListEdit
        },
        {
          path: 'product-lists/create',
          name: 'MerchandisingProductListCreate',
          component: MerchandisingProductListEdit
        },
        {
          path: 'product-lists/:listId/products',
          name: 'MerchandisingProductListProducts',
          component: MerchandisingProductListProducts
        },
        {
          path: 'product-displays/',
          name: 'MerchandisingProductDisplays',
          component: MerchandisingProductDisplays
        },
        {
          path: 'product-displays/:displayId/edit',
          name: 'MerchandisingProductDisplayEdit',
          component: MerchandisingProductDisplayEdit
        },
        {
          path: 'product-displays/create',
          name: 'MerchandisingProductDisplayCreate',
          component: MerchandisingProductDisplayEdit
        },
        {
          path: 'personal-shoppers',
          name: 'MerchandisingPersonalShoppers',
          component: MerchandisingPersonalShoppers
        },
        {
          path: 'widgets',
          name: 'MerchandisingWidgetApps',
          component: MerchandisingWidgetApps
        },
        {
          path: 'product-catalogs',
          name: 'MerchandisingProductRecCatalogs',
          component: MerchandisingProductRecCatalogs
        },
        {
          path: 'product-catalogs/catalog/:catalogId/imports',
          name: 'MerchandisingProductRecCatalogImports',
          component: MerchandisingProductRecCatalogImports
        },
        {
          path: 'product-catalogs/catalog/:catalogId/import',
          name: 'MerchandisingProductRecCatalogImport',
          component: MerchandisingProductRecCatalogImport
        },
        {
          path: 'product-catalogs/catalog/:catalogId/products',
          name: 'MerchandisingProductRecCatalogProducts',
          component: MerchandisingProductRecCatalogProducts
        },
        {
          path: 'product-catalogs/catalog/:catalogId/edit',
          name: 'MerchandisingProductRecCatalogEdit',
          component: MerchandisingProductRecCatalogEdit
        },
        {
          path: 'product-catalogs/catalog/create',
          name: 'MerchandisingProductRecCatalogCreate',
          component: MerchandisingProductRecCatalogEdit
        }
      ]
    },
    {
      path: '/streams',
      redirect: '/streams/streams',
      name: 'StreamsLayout',
      component: StreamsLayout,
      children: [
        {
          path: 'streams',
          name: 'StreamsStreams',
          component: StreamsStreams,
          redirect: '/streams/streams/list/',
          children: [
            {
              name: 'StreamsList',
              path: 'list',
              component: StreamsStreamsList
            },
            {
              path: 'edit/:streamId',
              name: 'StreamsEdit',
              component: StreamsStreamsEdit
            },
          ]
        },
        {
          path: 'endpoints',
          name: 'StreamsEndpoints',
          component: StreamsEndpoints
        },
      ]
    },
    {
      path: '/bdh',
      redirect: '/cdp'
    },
    {
      path: '/cdp-onboarding',
      name: 'CdpOnboarding',
      component: CdpOnboardingLayout
    },
    {
      path: '/cdp',
      component: CdpLayout,
      children: [
        {
          path: '',
          name: 'CdpLanding',
          component: CdpLanding
        },
        {
          path: 'welcome',
          name: 'CdpWelcome',
          component: Welcome
        },
        {
          path: 'insights',
          redirect: 'insights/default'
          // name: 'CdpInsights',
          // component: CdpInsights,
        },
        {
          path: 'insights/:id',
          name: 'CdpInsights',
          component: CdpInsights,
        },
        {
          path: 'audiences',
          redirect: 'audiences/list'
        },
        {
          path: 'audiences/list',
          name: 'CdpAudienceList',
          component: AudienceList,
        },
        {
          path: 'audiences/edit/:audienceId',
          name: 'CdpAudienceEdit',
          component: AudienceEdit,
        },
        {
          path: 'audiences/create',
          name: 'CdpAudienceCreate',
          component: AudienceEdit,
        },
        {
          path: 'pipelines',
          redirect: 'pipelines/list'
        },
        {
          path: 'pipelines/list',
          name: 'CdpPipelineList',
          component: PipelineList,
        },
        {
          path: 'pipelines/edit/:pipelineId',
          name: 'CdpPipelineEdit',
          component: PipelineEdit,
        },
        {
          path: 'pipelines/create',
          name: 'CdpPipelineCreate',
          component: PipelineEdit,
        },
        {
          path: 'exports',
          redirect: 'exports/list'
        },
        {
          path: 'exports/list',
          name: 'CdpExportList',
          component: ExportList,
        },
        {
          path: 'exports/edit/:exportId',
          name: 'CdpExportEdit',
          component: ExportEdit,
        },
        {
          path: 'exports/create',
          name: 'CdpExportCreate',
          component: ExportEdit,
        },
        {
          path: 'integrations/catalog',
          name: 'CdpIntegrationCatalog',
          component: IntegrationCatalog,
        },
        {
          path: 'integrations/catalog/:integrationId',
          name: 'CdpIntegrationCatalogDetails',
          component: IntegrationCatalogDetails,
        },
        {
          path: 'integrations/source/create',
          name: 'CdpIntegrationSourceCreate',
          component: SourceEdit,
        },
        {
          path: 'integrations/destination/create',
          name: 'CdpIntegrationDestinationCreate',
          component: DestinationEdit,
        },
        {
          path: 'integrations/source/list',
          name: 'CdpIntegrationSourceList',
          component: SourceList,
        },
        {
          path: 'integrations/destination/list',
          name: 'CdpIntegrationDestinationList',
          component: DestinationList,
        },
        {
          path: 'integrations/source/edit/:id',
          name: 'CdpIntegrationSourceEdit',
          component: SourceEdit,
        },
        {
          path: 'integrations/destination/edit/:id',
          name: 'CdpIntegrationDestinationEdit',
          component: DestinationEdit,
        },
        {
          path: 'profile',
          name: 'CdpProfile',
          component: Profile,
        },
        {
          path: 'profiles',
          name: 'CdpProfiles',
          component: Profiles,
        },
        {
          path: 'dictionary',
          name: 'CdpDataDictionary',
          component: DataDictionary,
        },
        {
          path: 'mapping',
          name: 'CdpMapping',
          component: Mapping,
        },
        {
          path: 'compliance',
          name: 'CdpCompliance',
          component: CdpCompliance
        },
        {
          path: 'data-health',
          name: 'CdpDataHealth',
          component: CdpDataHealth
        },
        {
          path: 'automations',
          redirect: 'automations/list',
          name: 'CdpAutomationsLayout',
          component: CdpAutomationsLayout,
          children: [
            {
              path: 'list',
              name: 'CdpAutomationsList',
              component: CdpAutomationsList
            },
            {
              path: 'edit/:automationId',
              name: 'CdpAutomationsEdit',
              component: CdpAutomationsEdit
            },
            {
              path: 'create',
              name: 'CdpAutomationsCreate',
              component: CdpAutomationsEdit
            },
          ]
        },
      ]
    },
    {
      path: '/account',
      redirect: '/account/profile',
      name: 'AccountLayout',
      component: AccountLayout,
      children: [
        {
          path: 'profile',
          name: 'AccountProfile',
          component: AccountProfile
        },
        {
          path: 'changePassword',
          name: 'AccountChangePassword',
          component: AccountChangePassword
        },
        {
          path: 'company',
          name: 'AccountCompany',
          component: AccountCompany
        },
        {
          path: 'users',
          name: 'AccountUsers',
          component: AccountUsers
        },
        {
          path: 'agency',
          name: 'AccountAgency',
          component: AccountAgency
        },
        {
          path: 'agency/link/id/:id/accept/:accept/',
          name: 'AccountAgencyLink',
          component: AccountAgency
        },
        {
          path: 'preferences',
          name: 'AccountPreferences',
          component: AccountPreferences
        },
        {
          path: 'cdp-preferences',
          name: 'AccountCdpSettings',
          component: AccountCdpSettings
        },
        {
          path: 'security',
          name: 'AccountSecurity',
          component: AccountSecurity
        },
        {
          path: 'shopLinks',
          name: 'AccountShopLinks',
          component: AccountShopLinks
        },
        {
          path: 'domains',
          name: 'AccountDomains',
          component: AccountDomains
        },
        {
          path: 'conversions',
          name: 'AccountConversions',
          component: AccountConversions
        },
        {
          path: 'transactions',
          name: 'AccountTransactions',
          component: AccountTransactions
        },
        {
          path: 'invoices',
          name: 'AccountInvoices',
          component: AccountInvoices
        },
        {
          path: 'usage-reports',
          name: 'AccountUsageReports',
          component: AccountUsageReports
        },
        {
          path: 'sharedRulesets',
          name: 'AccountSharedRulesetsList',
          component: AccountSharedRulesetsList
        },
        {
          path: 'sharedRulesets/edit/:rulesetId',
          name: 'AccountSharedRulesetsEdit',
          component: AccountSharedRulesetsEdit
        },
        {
          path: 'formResponses',
          name: 'AccountFormResponses',
          component: AccountFormResponses
        },
        {
          path: 'customData',
          name: 'AccountCustomData',
          component: AccountCustomData
        },
        {
          path: 'summaryEmails',
          name: 'AccountSummaryEmails',
          component: AccountSummaryEmails
        },
        {
          path: 'exportEmails',
          name: 'AccountExportEmails',
          component: AccountExportEmails
        },
        {
          path: 'downloads',
          name: 'AccountDownloads',
          component: AccountDownloads
        },
        {
          path: 'scheduledReports',
          name: 'AccountScheduledReportsList',
          component: AccountScheduledReportsList
        },
        {
          path: 'scheduledReports/create/',
          name: 'AccountScheduledReportsCreate',
          component: AccountScheduledReportsEdit
        },
        {
          path: 'scheduledReports/edit/:reportId/',
          name: 'AccountScheduledReportsEdit',
          component: AccountScheduledReportsEdit
        },
        {
          path: 'scheduledReports/runs/',
          name: 'AccountScheduledReportsRuns',
          component: AccountScheduledReportsRuns
        },
        {
          path: 'conversion-definitions',
          name: 'AccountConversionDefinitions',
          component: AccountConversionDefinitions
        },
        {
          path: 'agency-reporting',
          name: 'AccountAgencyReporting',
          component: AccountAgencyReporting
        },
        {
          path: 'campaign-exceptions',
          name: 'AccountCampaignExceptions',
          component: AccountCampaignExceptions
        },
        {
          path: 'audit-log',
          name: 'AccountAuditLog',
          component: AccountAuditLog
        }
      ]
    },
    {
      path: '/privacy-id',
      redirect: '/privacy-id/dashboard',
      name: 'PrivacyIdLayout',
      component: PrivacyIdLayout,
      children: [
        {
          path: 'dashboard',
          name: 'PrivacyIdDashboard',
          component: PrivacyIdDashboard
        },
        {
          path: 'sites',
          name: 'PrivacyIdSites',
          component: PrivacyIdSites
        },
        {
          path: 'scripts',
          name: 'PrivacyIdScripts',
          component: PrivacyIdScripts
        },
        {
          path: 'setup',
          name: 'PrivacyIdSetup',
          component: PrivacyIdSetup
        },
        {
          path: 'integration',
          name: 'PrivacyIdIntegration',
          component: PrivacyIdIntegration
        }
      ]
    },
    {
      path: '/sessionViewer/',
      name: 'SessionViewerLayout',
      component: SessionViewerLayout
    },
    {
      path: '/redirectLogin/',
      name: 'RedirectLoginLayout',
      component: RedirectLoginLayout,
    },
    {
      path: '/print',
      redirect: '/print/onboarder/_/en',
      name: 'PrintLayout',
      component: PrintLayout,
      children: [
        {
          path: 'onboarder/:secret/:locale',
          name: 'OnboarderPrint',
          component: OnboarderPrint
        },
      ]
    },
    {
      path: '/site',
      redirect: '/site/register',
      name: 'SiteLayout',
      component: SiteLayout,
      children: [
        {
          path: 'login',
          name: 'SiteLogin',
          component: SiteLogin
        },
        {
          path: 'register',
          name: 'SiteRegister',
          component: SiteRegister
        },
        {
          path: 'forgotPassword',
          name: 'SiteForgotPassword',
          component: SiteForgotPassword
        },
        {
          path: 'changePassword/id/:id/hash/:hash',
          name: 'SiteChangePassword',
          component: SiteChangePassword
        },
        {
          path: 'changePassword/id/:id/hash/:hash/lang/:lang',
          name: 'SiteChangePasswordWithLang',
          component: SiteChangePassword
        },
        {
          path: 'reportsLogin/accountId/:accountId/onBehalfOfAccountId/:onBehalfOfAccountId/email/:email/hash/:hash/ts/:ts/',
          name: 'SiteReportsLogin',
          component: SiteReportsLogin
        }
      ]
    },
    {
      path: '/resource/:type/:value',
      name: 'ResourceLink',
      component: ResourceLink,
    },
  ]
})