import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
const {isNavigationFailure} = VueRouter
import App from './App.vue'
import Api from './classes/Api.class'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { jwtToPortalUser } from './classes/utils/JwtUtil'
import { dayjs, setLocale } from './classes/utils/DateUtil'

import Env from './classes/utils/Env'

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import posthog from 'posthog-js'

// import LogRocket from 'logrocket'

if (!Env.isDevelopment()) {
  Sentry.init({
    dsn: 'https://c59823087a2e4c669e0b47c460405308@sentry.io/5176610',
    integrations: [new Integrations.Vue({Vue, attachProps: true})],
  })
}

// for page meta such as title, description, keywords
Vue.use(VueMeta)

// the main FP UI
import UI from 'fanplayr-ui'
Vue.use(UI, { customTranslate: true })

// extra global components
import LoadingLayout from './components/LoadingLayout.vue'
Vue.component('LoadingLayout', LoadingLayout)
import ValidationBanner from './components/ui/ValidationBanner.vue'
Vue.component('ValidationBanner', ValidationBanner)

// vee-validate setup stuff
// uses Vue
require('./main/ValidationSetup')

// used in PrivacyID (v-highlight directive)
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight)

// errors
import UnauthenticatedError from '@/classes/errors/UnauthenticatedError'
import ForbiddenError from '@/classes/errors/ForbiddenError'
import UserError from '@/classes/errors/UserError'

// TODO: remove once we use new jwt sessions for insights
// used for shared session
import md5 from 'md5'

// translation stuff
import PortalI18n from 'PortalI18n'
const lang = localStorage.getItem('portal-language') || 'en'
const country = localStorage.getItem('portal-account-country') || 'US'
Vue.use(PortalI18n, {
  defaultLocale: `${lang}-${country}`
})

// util
import { getFilterFromQuery } from './classes/utils/HelperFunctions'

// used to decode JWT
const jwt = require('jose')

Vue.config.productionTip = !!Env.isProduction()

// used for recaptcha
Vue.use(VueReCaptcha, { siteKey: '6LfaBL0UAAAAAAzCKA2vuxd_ZxjRpMCCcb8o4Mb7' })

// an event hub for communicating between components
const eventHub = new Vue()

// used in getter/setter so only one
let localData = new Vue({
  data() {
    return {
      agencyAccounts: null,
      multiAccounts: null,
      theUser: {
        name: '',
        logoUrl: ''
      },
      dialogues: null,
      appMountComplete: null,
      sessionToken: null
    }
  }
})

// ---------------------------------------------------------------
// Authentication

// only used here
let loginRedirect = null
let refreshInterval

async function processPostLogin(loginResponse) {
  if (!loginResponse) {
    return false
  }

  let { token, sessionCookieSeconds } =  loginResponse

  // must be session ID
  if (token.length === 64) {
    Api.get().setToken(token)
    let data = await Api.get().getBaseUserDetails()
    localData.theUser = data
    if (!localData.theUser.onBehalfOfAccountId) {
      localData.theUser.onBehalfOfAccountId = localData.theUser.accountId
    }
    localData.theUser.userId = localData.theUser.id
    localData.sessionToken = token
  } else {
    const sessionToken = md5(token)
    Api.get().setToken(sessionToken)

    localData.sessionToken = sessionToken

    // keep extra user data so there is no flash of missing data
    // while loading extra details
    localData.$set(localData, 'theUser', {
      ...localData.theUser,
      ...jwtToPortalUser(jwt.decodeJwt(token))
    })
  }

  if (window.CdpLib.utils.CdpProvider) {
    // console.log('Set token for CdpProvider', localData.sessionToken)
    window.CdpLib.utils.CdpProvider.setOptions({
      authKey: localData.sessionToken,
      endpoint: localData.theUser.cdpApiHostname
    })
  }

  restartRefreshCounter(sessionCookieSeconds)

  return true
}

async function doLogin(redirectTo = null, message = '') {

  if (redirectTo) {
    loginRedirect = redirectTo
  }

  await processPostLogin(await localData.dialogues.login(message))
  await loadExtraDetails()

  if (loginRedirect) {
    router.push(loginRedirect)
    loginRedirect = null
  }
}

async function loadExtraDetails() {
  // load user / accoutn details that are not in token
  let extraDetails = await Api.get().getExtraUserDetails()
  localData.$set(localData.theUser, 'email', extraDetails.user.email)
  localData.$set(localData.theUser, 'firstName', extraDetails.user.firstName)
  localData.$set(localData.theUser, 'lastName', extraDetails.user.lastName)
  localData.$set(localData.theUser, 'defaultLanguage', window.localStorage['portal-language'] || extraDetails.user.defaultLanguage || 'en')
  localData.$set(localData.theUser, 'accountName', extraDetails.account.name)
  localData.$set(localData.theUser, 'logoUrl', extraDetails.account.logoURL)
  localData.$set(localData.theUser, 'accountCurrency', extraDetails.account.currency)
  localData.$set(localData.theUser, 'serverVersion', extraDetails.version)
  localData.$set(localData.theUser, 'serverDeployVersion', extraDetails.deployVersion)

  localData.agencyAccounts = await Api.get().getAgencyAccounts()
  if (localData.theUser.isMultiAccount) {
    localData.multiAccounts = await Api.get().getMultiAccounts()
  }
  // set account country in localstorage
  if (extraDetails.account.country) {
    localStorage.setItem('portal-account-country', extraDetails.account.country)
  }

  posthog.identify(localData.theUser.userId, {
    name: localData.theUser.firstName + ' ' + localData.theUser.lastName,
    email: localData.theUser.email,
    defaultLanguage: localData.theUser.defaultLanguage,
  })
  
  await setLanguage(localData.theUser.defaultLanguage)
}

async function doRefreshToken() {
  const loginResponse = await Api.get().refreshToken()
  await processPostLogin(loginResponse)
  await loadExtraDetails()
  eventHub.$emit('tokenRefreshed')
}

function restartRefreshCounter(sessionCookieSeconds = 60) {
  clearInterval(refreshInterval)
  const delaySecs = Math.max(15, sessionCookieSeconds - 30)
  // console.log(`Refreshing token in ${delaySecs}s`)
  refreshInterval = setInterval(() => {
    doRefreshToken()
  }, delaySecs * 1000)
}

// ---------------------------------------------------------------
// Languages
// console.log('ui version', UI.version)
async function setLanguage(lang) {
  // Just a little hack to make sure the lang is just the language code
  lang = lang.split('-')[0]
  const country = localStorage.getItem('portal-account-country') || 'US'
  const locale = `${lang}-${country}`
  if (window.CdpLib && window.CdpLib.setLocale) {
    window.CdpLib.setLocale(locale)
  }
  if (UI.setLocale) {
    UI.setLocale(locale)
  }
  setLocale(locale)
  return PortalI18n.setLocale(locale)
}

// ---------------------------------------------------------------
// ERRORS

// send to sentry.io
function sentryError(error) {
  if (localData.theUser) {
    Sentry.setUser({
      'email': `${localData.theUser.email}`,
      'accountId': localData.theUser.accountId,
      'onBehalfOfAccountId': localData.theUser.onBehalfOfAccountId
    })
  }
  Sentry.captureException(error)
}

Vue.config.errorHandler = (error) => {
  console.log('Vue->config->errorHandler')

  // if we're doing a load may as well stop it
  // this.$ui.progress.done()
  if (error instanceof UnauthenticatedError) {
    console.warn(error)
    doLogin()
  } else if (error instanceof ForbiddenError ) {
    UI.alert({ title: PortalI18n.translate('Unauthorized to Access') })
  } else {
    sentryError(error)
    console.error(error)
    UI.modal({
      title: error.message ? error.message : error,
      content: PortalI18n.translate('Error.refreshMessage'),
      primaryAction: {
        id: 'refresh',
        type: 'primary',
        label: PortalI18n.translate('Refresh'),
        onAction: () => {
          window.location.reload()
        }
      }
    })
  }
}
// ---------------------------------------------------------------

// ---------------------------------------------------------------
// Mixins
Vue.mixin({
  data() {
    return {
      get api() {
        return Api.get()
      },
      get eventHub() {
        return eventHub
      }
    }
  },
  mounted() {
  },
  computed: {
    theUser: {
      get() {
        return localData.theUser
      },
      set(newVal) {
        localData.theUser = newVal
      }
    },
    sessionToken() {
      return localData.sessionToken
    },
    currentLocale() {
      return window.PortalI18n && window.PortalI18n.currentLocale || 'en-US'
    },
    currentLanguage() {
      const [lang] = this.currentLocale.split('-')
      return lang
    },
    agencyAccounts: {
      get() {
        if (!localData.agencyAccounts) {
          return null
        }
        return JSON.parse(JSON.stringify(localData.agencyAccounts)).sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          return 0
        })
      },
      set(newVal) {
        localData.agencyAccounts = newVal
      }
    },
    multiAccounts: {
      get() {
        if (!localData.multiAccounts) {
          return null
        }
        return JSON.parse(JSON.stringify(localData.multiAccounts)).sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          return 0
        })
      },
      set(newVal) {
        localData.multiAccounts = newVal
      }
    },
    $dialogue: {
      get() {
        return localData.dialogues
      },
      set(newVal) {
        localData.dialogues = newVal
      }
    },
    appMountComplete: {
      get() {
        return localData.appMountComplete
      },
      set(newVal) {
        localData.appMountComplete = newVal
      }
    },
    supportedLanguages() {
      return PortalI18n.getAvailableLocales().map(locale => ({
        value: locale,
        label: this.$t(`language.${locale}`)
      })).sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
      }).filter((lang) => {
        if (Env.isProduction()) {
          return ['en', 'it', 'es', 'ja'].includes(lang.value)
        } else {
          return true
        }
      })
    },
    onMobileDevice() {
      const userAgent = window.navigator && window.navigator.userAgent
      return userAgent && userAgent.match(/(Tablet|iPad|iPhone|iPod|BlackBerry|Mobile|IEMobile|Opera Mobi|Opera Mini|phone)/i)
    }
  },
  methods: {
    getFilterFromQuery,
    setLanguage,
    apiCatch(error, nextRoute = null) {
      if (error instanceof UnauthenticatedError) {
        doLogin(null, 'unauthenticated')
      } else if (error instanceof ForbiddenError ) {
        UI.alert({ title: PortalI18n.translate('Unauthorized to Access') })
      } else {
        sentryError(error)
        console.error(error)
        if (error instanceof UserError) {
          UI.alert({ title: PortalI18n.translate('Error'), content: PortalI18n.translate(error.message, error.data) })
        }
      }
      if (nextRoute) this.$router.push(nextRoute)
    },
    duplicateRouteCatch(failure) {
      if (!failure) return
      if (!isNavigationFailure(failure)) throw failure
    },
    async doLogin(redirectTo, message = '') {
      return doLogin(redirectTo, message)
    },
    async doRefreshToken() {
      return doRefreshToken()
    },
    async processPostLogin(loginResponse) {
      return processPostLogin(loginResponse)
    },
    async loadExtraDetails() {
      return loadExtraDetails()
    },
    setTableLimit(key, limit) {
      window.localStorage[key] = limit
      return true
    },
    getTableLimit(key) {
      return window.localStorage[key] && parseInt(window.localStorage[key])
    },
    getCdpGuideConfig() {
      let guideConfig = {}
      const strConfig = window.localStorage.getItem('cdpGuideConfig') || '{}'
      try {
        guideConfig = JSON.parse(strConfig)
      } catch {
        // nothing
      }
      return guideConfig
    },
    async guardNavChange(hasChanged, next) {
      if (hasChanged && this.theUser && this.theUser.isCdpEnabled) {
        const confirm = await this.$ui.confirm({
          title: this.$t('nav.discardAllUnsavedChanges', null, true),
          content: this.$t('nav.discardChangesMessage', null, true),
          primaryActionLabel: this.$t('Discard changes', null, true),
          primaryActionType: 'destructive',
          secondaryActionLabel: this.$t('Continue editing', null, true)
        })
        if (!confirm) return next(false)
      }
      next()
    }
  }
})

// ---------------------------------------------------------------
// Router Stuff

router.afterEach(() => {
  if (window.localStorage['portal-version-reload'] === 'TRUE') {
    window.localStorage['portal-version-reload'] = ''
    setTimeout(() => {
      window.location.reload(true)
    }, 1)
  }
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  let path = window.location.pathname + window.location.hash
  if (path.indexOf('/#') > -1) {
    path = path.replace(/\/#/, '')
  }
  window.gtag && window.gtag('event', 'page_view', {
    page_title: document.title,
    page_path: path
  })

  if (Env.isProduction()) {
    if (localData.theUser && localData.theUser.userId) {
      Api.get().securityLogview(JSON.stringify({
        href: window.location.href,
        title: (document.title && document.title.substring(0, document.title.lastIndexOf('|')) || '').trim(),
      }))
    }
  }

  posthog.capture('$pageview', {
    '$current_url': window.location.href.replace('/#', ''),
    '$title': document.title,
  })
})

function allowedNoSmartPath(path) {
  const allowed = [
    '/notEnabled/',
    '/site/',
    '/account/',
    '/logout/',
    '/privacy-id',
    '/insights-settings',
    '/cdp',
    '/about',
    '/support',
    '/cdp-onboarding',
    '/home'
  ]
  for (const p of allowed) {
    if (path.indexOf(p) === 0) {
      return true
    }
  }
  return false
}

router.beforeEach((to, from, next) => {

  if (to.path.indexOf('/site/') === 0 || to.path.indexOf('/redirectLogin/') === 0 || to.path.indexOf('/print/') === 0) {
    // console.log('SITE')
    localData.theUser = null
    next()
    return
  }

  // console.log('P', from.path, ' => ', to.path)
  // if (from.path === to.path) {
  //   return next()
  // }

  if (!localData.appMountComplete) {
    next()
    return
  }
  Api.get().ping()
    .then((response) => {
      // console.log('ping', to.path, localData.theUser)
      if (localData.theUser) {
        if (
          localData.theUser.isCdpEnabled &&
          !localData.theUser.isSmartEnabled &&
          to.path.indexOf('/cdp') !== 0 &&
          !allowedNoSmartPath(to.path)
        ) {
          return next({ path: '/cdp/' })
        }
        if (
          !localData.theUser.isCdpEnabled &&
          to.path.indexOf('/cdp') === 0
        ) {
          return next({ path: '/campaigns/' })
        }
        if (!localData.theUser.isCdpEnabled) {
          if (
            !localData.theUser.isSmartEnabled &&
            !localData.theUser.privacyIdEnabled &&
            !allowedNoSmartPath(to.path)
          ) {
            return next({ path: '/notEnabled/' })
          }
          if (
            !localData.theUser.isSmartEnabled &&
            !allowedNoSmartPath(to.path)
          ) {
            return next({ path: '/notEnabled/' })
          }
          if (
            !localData.theUser.privacyIdEnabled &&
            to.path.indexOf('/privacy-id/') === 0
          ) {
            return next({ path: '/notEnabled/' })
          }
          if (
            localData.theUser.isSmartEnabled &&
            to.path.indexOf('/notEnabled/') === 0
          ) {
            return next({ path: '/campaigns/' })
          }
          if (
            localData.theUser.privacyIdEnabled &&
            to.path.indexOf('/notEnabled/') === 0
          ) {
            return next({ path: '/privacy-id/' })
          }
        }
      }

      // reload for versions
      let localServerVersion = window.localStorage['portal-server-version'] || ''
      // if it's a number
      if (localServerVersion.length < 10) {
        localServerVersion = ''
      }
      if (!localServerVersion) {
        window.localStorage['portal-server-version'] = response.version
      }
      if (dayjs.utc(response.version).isAfter(dayjs.utc(localServerVersion))) {
        window.localStorage['portal-server-version'] = response.version
        window.localStorage['portal-version-reload'] = 'TRUE'
        next()
      } else {
        next()
      }
    })
    .catch((e) => {
      localData.theUser = null
      console.log(e)
      // doLogin(to && to.fullPath, e && e.message)
      next({
        path: `/site/login/?next=${encodeURIComponent(router.currentRoute)}`,
        query: {
          next: to && to.fullPath
        }
      })
    })
})
// ---------------------------------------------------------------

window.app = new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
