import HttpStatus from 'http-status-codes'

import ForbiddenError from './errors/ForbiddenError'
import UnauthenticatedError from './errors/UnauthenticatedError'
import RequiredError from './errors/RequiredError'
import InvalidError from './errors/InvalidError'
import UserError from './errors/UserError'
import Utils from './utils/Utils'
import {dayjs} from '../classes/utils/DateUtil'

import NotificationsApi from '../apps/messaging/classes/Api.class'

import Env from './utils/Env'

export default class Api {

  static inst

  static get() {
    if (!this.inst) {
      Api.inst = new Api()
    }
    return Api.inst
  }

  constructor() {
    // dev
    this.endpoint = 'http://localhost:8081'
    if (Env.isDevelopmentLive()) {
      this.endpoint = 'https://dev-devportal-api.fanplayr.com'
    // staging
    } else if (Env.isStaging()) {
      this.endpoint = 'https://devportal-api.fanplayr.com'
    // prod
    } else if (Env.isProduction()) {
      this.endpoint = 'https://portal-api.fanplayr.com'
    }
    this.notifications = new NotificationsApi()
  }

  setToken(token) {
    this.token = token
    this.notifications.setToken(this.token)
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Public

  // -------------------------
  // authentication
  async login(email, password, defaultLanguage = null) {
    return this.makePublicRequest('auth', 'login', {
      email,
      password,
      defaultLanguage
    })
  }

  async registerNewUser(data) {
    return this.makePublicRequest('auth', 'register', {
      ...data
    })
  }

  async forgotPassword(email) {
    return this.makePublicRequest('user', 'forgotPassword', {
      email
    })
  }

  async checkForEmail(email) {
    return this.makePublicRequest('user', 'checkForEmail', {
      email
    })
  }

  async changeForgottenPassword(userId, hash, newPassword) {
    return this.makePublicRequest('auth', 'changeForgottenPassword', {
      userId,
      hash,
      newPassword
    })
  }

  async getSecurityPasswordComplexity(userId, hash) {
    return this.makePublicRequest('auth', 'getSecurityPasswordComplexity', {
      userId,
      hash
    })
  }

  async twoFactorSendCode() {
    return this.makePublicRequest('auth', 'twoFactorSendCode')
  }

  async twoFactorValidateCode(code) {
    return this.makePublicRequest('auth', 'twoFactorValidateCode', {
      code
    })
  }

  async getCdpOnboardingStateBySecret(secret) {
    return this.makePublicRequest('get', 'cdp-onboarding-state-by-secret', {
      secret
    })
  }

  async getCdpOnboardingIntegrations() {
    return this.makePublicRequest('get', 'cdp-integrations-onboarding')
  }

  // cdp
  async enablePii(password) {
    return this.makeRequest('auth', 'enablePii', {
      password
    })
  }

  // reports
  async reportsLogin(accountId, onBehalfOfAccountId, email, hash, ts) {
    return this.makePublicRequest('auth', 'reportsLogin', {
      accountId,
      onBehalfOfAccountId,
      email,
      hash,
      ts
    })
  }

  // reports
  async externalBecomeAccount(accountId, loginAs, become, hash, ts) {
    return this.makePublicRequest('auth', 'externalBecomeAccount', {
      accountId, loginAs, become, hash, ts
    })
  }

  // -------------------------
  // utils
  async getLocaleGuess() {
    return this.makePublicRequest('get', 'localeGuess')
  }
  async getCountriesList() {
    return this.makePublicRequest('util', 'getCountries')
  }
  async getLanguageList() {
    return this.makePublicRequest('util', 'getLanguages')
  }
  async getCurrenciesList() {
    return this.makePublicRequest('util', 'getCurrencies')
  }
  async getTimezonesList() {
    return this.makePublicRequest('util', 'getTimezones')
  }
  async getPhonePrefixesList() {
    return this.makePublicRequest('util', 'getPhonePrefixes')
  }
  async testRecaptcha(token) {
    return this.makePublicRequest('util', 'testRecaptcha', {
      token
    })
  }

  // ----------------------------------------------------------------------------
  // ----------------------------------------------------------------------------
  // Private

  // ----------------------------------------------------------------------------
  // Authentication

  async ping() {
    return this.makeRequest('auth', 'ping')
  }

  async logout() {
    return this.makeRequest('auth', 'logout')
  }

  async refreshToken() {
    return this.makeRequest('auth', 'refreshToken')
  }

  async getBaseUserDetails() {
    return this.makeRequest('auth', 'getBaseUserDetails')
  }

  async getExtraUserDetails() {
    return this.makeRequest('auth', 'getExtraUserDetails')
  }

  async getAgencyAccounts() {
    return this.makeRequest('auth', 'getAgencyAccounts')
  }

  async getMultiAccounts() {
    return this.makeRequest('auth', 'getMultiAccounts')
  }

  async switchAccount(accountId) {
    return this.makeRequest('auth', 'switchAccount', {
      accountId
    })
  }

  async getSessionKey() {
    return this.makeRequest('auth', 'sessionKey')
  }

  // ----------------------------------------------------------------------------
  // Utils
  async getEspProviders() {
    return this.makeRequest('get', 'espProviders')
  }

  // --------------------------------------------------------------------------------------------------
  //REST

  async getCurrentUserProfile() {
    return this.makeRequest('get', 'profile')
  }

  async getCurrentAccountTwoFactorMethods() {
    return this.makeRequest('get', 'accountTwoFactor')
  }

  async getCurrentAccountSecurity() {
    return this.makeRequest('get', 'accountSecurity')
  }

  async getCurrentAccountLanguages() {
    return this.makeRequest('get', 'accountLanguages')
  }

  async getUsersByAccountId() {
    return this.makeRequest('get', 'accountUsers')
  }

  async getPrimaryAccountUsers() {
    return this.makeRequest('get', 'primaryAccountUsers')
  }

  async getCurrentAccountHasIntegrationLanguage() {
    return this.makeRequest('get', 'accountIntegrationLanguage')
  }

  async unlockUser(userId) {
    return this.makeRequest('unlock', 'accountUser', {
      userId
    })
  }

  async getAgencyUsersByAccountId() {
    return this.makeRequest('get', 'agencyUsers')
  }

  async requestAgencyAuthorizationByEmail(email) {
    return this.makeRequest('agency', 'requestAgencyAuthorizationByEmail', {
      email
    })
  }

  async removeAgency(agencyAccountId) {
    return this.makeRequest('agency', 'remove', {
      agencyAccountId
    })
  }

  async acceptAgencyRequest(linkId) {
    return this.makeRequest('agency', 'acceptRequest', {
      linkId
    })
  }

  async rejectAgencyRequest(linkId) {
    return this.makeRequest('agency', 'rejectRequest', {
      linkId
    })
  }

  async createNewUser(user) {
    return this.makeRequest('insert', 'user', user)
  }

  async createAgencyUser(email) {
    return this.makeRequest('insert', 'agencyUser', { email })
  }

  async getAgencyAccountDetails() {
    return this.makeRequest('get', 'agencyAccountDetails')
  }

  async getAccountsForAgency() {
    return this.makeRequest('get', 'accountsForAgency')
  }

  async updateUserPermissions(changes, isAgency = false) {
    return this.makeRequest('update', 'userPermission', {
      changes,
      isAgency
    })
  }

  async removeUser(userId, type) {
    return this.makeRequest('delete', 'user', {
      id: userId,
      type
    })
  }

  async addPrimaryUserToAccount(userId) {
    return this.makeRequest('user', 'addPrimaryToAccount', {
      userId
    })
  }

  async removePrimaryUserFromAccount(userId) {
    return this.makeRequest('user', 'removePrimaryFromAccount', {
      userId
    })
  }

  async updateUserLanguage(language) {
    return this.makeRequest('user', 'updateLanguage', {
      language
    })
  }

  async updateCurrentUserProfile(user) {
    return this.makeRequest('update', 'profile', user)
  }

  async getCurrentAccount() {
    return this.makeRequest('get', 'account')
  }

  async updateCurrentAccount(account) {
    return this.makeRequest('update', 'account', account)
  }

  async updateCurrentAccountSecurity(security) {
    return this.makeRequest('update', 'accountSecurity', security)
  }

  async resetAccountSecurityItem(type, enable) {
    return this.makeRequest('reset', 'accountSecurity', {
      type,
      enable
    })
  }

  async getCurrentAccountSecurityLogs(filters, limit, offset) {
    return this.makeRequest('get', 'accountSecurityLogs', {
      filters,
      limit,
      offset
    })
  }

  async getCurrentAccountPrefs() {
    return this.makeRequest('get', 'accountPreferences')
  }

  async updateCurrentAccountPrefs(
    accountPreferences,
    emailPreferences,
    anonymizeIP,
    gatherSalesOrderEmails,
    splitWeeklyEmails,
    additionalLanguages
  ) {
    return this.makeRequest('update', 'accountPreferences', {
      accountPreferences,
      emailPreferences,
      anonymizeIP,
      gatherSalesOrderEmails,
      splitWeeklyEmails,
      additionalLanguages
    })
  }

  async getCampaignPrefs(campaignId) {
    return this.makeRequest('get', 'campaignPreferences', { campaignId })
  }

  async updateCampaignPrefs(campaignId, prefs) {
    return this.makeRequest('update', 'campaignPreferences', { campaignId, prefs })
  }

  async getSingleUseDisableThreshold(campaignId) {
    return this.makeRequest('get', 'singleUseDisableThreshold', { campaignId })
  }

  async getDomains() {
    return this.makeRequest('get', 'sites', {
      includeInactive: false
    })
  }

  async getDomainsForSessionViewer() {
    return this.makeRequest('get', 'sites', {
      includeInactive: true
    })
  }

  async addAccountSite(domain) {
    return this.makeRequest('insert', 'sites', {
      domain
    })
  }

  async addSiteToCampaign(campaignId, campaignKey, domain) {
    return this.makeRequest('insert', 'campaignSites', {
      domain,
      campaignId,
      campaignKey
    })
  }

  async removeSiteFromCampaign(domain) {
    return this.makeRequest('delete', 'campaignSites', {
      domain
    })
  }

  async getExportEmailsPreview(filter) {
    return this.makeRequest('preview', 'exportEmails', { filter })
  }

  async getExportEmailsUrl(filter) {
    return this.makeRequest('url', 'exportEmails', { filter })
  }

  async enableSegments(campaignId, segmentIds) {
    return this.makeRequest('enable', 'segments', {
      campaignId,
      segmentIds
    })
  }

  async disableSegments(campaignId, segmentIds) {
    return this.makeRequest('disable', 'segments', {
      campaignId,
      segmentIds
    })
  }

  async deleteSegments(campaignId, segmentIds) {
    return this.makeRequest('delete', 'segments', {
      campaignId,
      segmentIds
    })
  }

  async removeAccountSite(siteId) {
    return this.makeRequest('delete', 'sites', {
      id: siteId
    })
  }

  async updateAccountSite(site) {
    return this.makeRequest('update', 'sites', site)
  }

  async getEndpoints() {
    return this.makeRequest('get', 'accountEndpointConfigs')
  }

  async saveEndpoint(endpoint) {
    if (endpoint.id) {
      // update endpoint
      return this.makeRequest('update', 'accountEndpointConfigs', endpoint)
    } else {
      // create new endpoint
      return this.makeRequest('insert', 'accountEndpointConfigs', endpoint)
    }
  }

  async getStreams() {
    return this.makeRequest('get', 'streams')
  }
  async getStream(id) {
    return this.makeRequest('get', 'stream', {
      id
    })
  }
  async enableStream(id) {
    return this.makeRequest('enable', 'stream', {
      id
    })
  }
  async disableStream(id) {
    return this.makeRequest('disable', 'stream', {
      id
    })
  }
  async archiveStream(id) {
    return this.makeRequest('archive', 'stream', {
      id
    })
  }
  async saveStream(stream) {
    if (stream.id) {
      // update stream
      return this.makeRequest('update', 'stream', stream)
    } else {
      // create new stream
      return this.makeRequest('insert', 'stream', stream)
    }
  }
  async getStreamsBaseData() {
    return this.makeRequest('get', 'streamsBaseData')
  }

  async getCdpAutomations() {
    return this.makeRequest('get', 'cdp-automations')
  }
  async getCdpAutomation(id) {
    return this.makeRequest('get', 'cdp-automation', {
      id
    })
  }
  async enableCdpAutomation(id) {
    return this.makeRequest('enable', 'cdp-automation', {
      id
    })
  }
  async disableCdpAutomation(id) {
    return this.makeRequest('disable', 'cdp-automation', {
      id
    })
  }
  async archiveCdpAutomation(id) {
    return this.makeRequest('archive', 'cdp-automation', {
      id
    })
  }
  async saveCdpAutomation(automation) {
    if (automation.id) {
      // update automation
      return this.makeRequest('update', 'cdp-automation', automation)
    } else {
      // create new automation
      return this.makeRequest('insert', 'cdp-automation', automation)
    }
  }
  async renameCdpAutomation(id, name) {
    return this.makeRequest('rename', 'cdp-automation', {
      id,
      name
    })
  }
  async getCdpAutomationsBaseData() {
    return this.makeRequest('get', 'cdp-automations-base-data')
  }

  async getDataStorageRegions() {
    return this.makeRequest('get', 'dataStorageRegions')
  }

  async getCustomData() {
    return this.makeRequest('get', 'customStoreDataMap')
  }

  async createCustomData(customData) {
    return this.makeRequest('insert', 'customStoreDataMap', customData)
  }

  async updateCustomData(customData) {
    return this.makeRequest('update', 'customStoreDataMap', customData)
  }

  async getAccountForShopLinks() {
    return this.makeRequest('get', 'accountIdKey')
  }

  async getShopLink() {
    return this.makeRequest('get', 'accountShopLink')
  }

  async getFormResponses(filter, limit, offset) {
    return this.makeRequest('get', 'formResponses', {
      filter,
      limit,
      offset
    })
  }

  async getFormResponseDownloadUrl(filter) {
    return this.makeRequest('get', 'formResponseDownloadUrl', {
      filter
    })
  }

  async updateFormResponseStatus(responseId, status) {
    return this.makeRequest('update', 'formResponses', {
      id: responseId,
      status
    })
  }

  async changePassword(oldPassword, newPassword) {
    return this.makeRequest('auth', 'changePassword', {
      oldPassword,
      newPassword
    })
  }

  async logoutOtherSessions() {
    return this.makeRequest('auth', 'logoutOtherSessions')
  }

  async logoutOtherUserSessions(userId) {
    return this.makeRequest('auth', 'logoutOtherUserSessions', {
      userId
    })
  }

  async setMobileFor2faPreLogin(mobileCountryCode, mobileNumber) {
    return this.makeRequest('auth', 'setMobileFor2faPreLogin', {
      mobileCountryCode,
      mobileNumber
    })
  }

  async getSecurityAutoLogout() {
    return this.makeRequest('get', 'accountSecurityAutoLogout')
  }

  async getSecurity2faChoiceRequired() {
    return this.makeRequest('get', 'accountSecurity2faChoiceRequired')
  }

  async getSecurityPasswordChangeRequired() {
    return this.makeRequest('get', 'accountSecurityPasswordChangeRequired')
  }

  async getSummaryEmails(filter, limit, offset) {
    return this.makeRequest('get', 'summaryEmails', {
      filter,
      limit,
      offset
    })
  }

  async getSummaryEmailsTotal(filter) {
    return this.makeRequest('get', 'summaryEmailsTotal', {
      filter
    })
  }

  async getSummaryEmailDownloadLink(file) {
    return this.makeRequest('get', 'summaryEmailDownloadLink', {
      file
    })
  }

  async getDownloads(filter, limit, offset) {
    return this.makeRequest('get', 'downloads', {
      filter,
      limit,
      offset
    })
  }

  async getDownloadSignedUrl(downloadId) {
    return this.makeRequest('get', 'downloadSignedUrl', {
      downloadId
    })
  }

  async getDownloadsTotal(filter) {
    return this.makeRequest('get', 'downloadsTotal', {
      filter
    })
  }

  async getScheduledReports() {
    return this.makeRequest('get', 'scheduledReports')
  }

  async getScheduledReportRuns(reportId, limit, offset) {
    return this.makeRequest('get', 'scheduledReportRuns', {
      reportId,
      limit,
      offset
    })
  }
  async getScheduledReportRunsTotal(reportId) {
    return this.makeRequest('get', 'scheduledReportRunsTotal', {
      reportId
    })
  }

  async getScheduledReport(id) {
    return this.makeRequest('get', 'scheduledReport', {
      id
    })
  }

  async enableScheduledReport(id) {
    return this.makeRequest('enable', 'scheduledReport', {
      id
    })
  }

  async disableScheduledReport(id) {
    return this.makeRequest('disable', 'scheduledReport', {
      id
    })
  }

  async deleteScheduledReport(id) {
    return this.makeRequest('delete', 'scheduledReport', {
      id
    })
  }

  async runScheduledReport(id) {
    return this.makeRequest('run', 'scheduledReport', {
      id
    })
  }

  async runScheduledReportForDates(reportData) {
    return this.makeRequest('runDates', 'scheduledReport', {
      id: reportData.id,
      startDate: reportData.startDate,
      endDate: reportData.endDate
    })
  }

  async loadScheduledReportPreview(report) {
    return this.makeRequest('preview', 'scheduledReport', report)
  }

  // data is sent as FormData to allow for files with "advanced" reports
  async createScheduledReport(report) {
    return this.makeRequest('create', 'scheduledReport', report, true)
  }

  // data is sent as FormData to allow for files with "advanced" reports
  async updateScheduledReport(report) {
    return this.makeRequest('update', 'scheduledReport', report, true)
  }

  async getAdvancedReportTemplateSignedUrl(id) {
    return this.makeRequest('get', 'scheduledReportAdvancedTemplateSignedUrl', {
      reportId: id
    })
  }

  async copyScheduledReport(name, id) {
    return this.makeRequest('copy', 'scheduledReport', {
      name,
      reportId: id
    })
  }

  async getAgencyReportingConfig() {
    return this.makeRequest('get', 'agencyReportingConfig')
  }

  async getAgencyReportingReports(filter) {
    return this.makeRequest('get', 'agencyReportingReports', {
      filter
    })
  }

  async getAgencyReportingReportAggregates() {
    return this.makeRequest('get', 'agencyReportingReportAggregates')
  }

  async getAgencyReportingDownloads(forMonth) {
    return this.makeRequest('get', 'agencyReportingDownloads', {
      forMonth
    })
  }

  async getAgencyReportingDownloadSignedUrl(id) {
    return this.makeRequest('get', 'agencyReportingDownloadSignedUrl', {
      downloadId: id
    })
  }

  async getInsightsSettingsViews() {
    return this.makeRequest('get', 'insightsSettingsViews')
  }

  async getInsightsSettingsView(id) {
    return this.makeRequest('get', 'insightsSettingsView', {id})
  }

  async enableInsightsSettingsView(id) {
    return this.makeRequest('enable', 'insightsSettingsView', {id})
  }

  async disableInsightsSettingsView(id) {
    return this.makeRequest('disable', 'insightsSettingsView', {id})
  }

  async updateInsightsSettingsView(view) {
    return this.makeRequest('update', 'insightsSettingsView', view)
  }

  async createInsightsSettingsView(view) {
    return this.makeRequest('create', 'insightsSettingsView', view)
  }

  async deleteInsightsSettingsView(id) {
    return this.makeRequest('delete', 'insightsSettingsView', {id})
  }

  async copyInsightsSettingsView(name, id) {
    return this.makeRequest('copy', 'insightsSettingsView', {name, id})
  }

  async getInsightsSettingsClicks() {
    return this.makeRequest('get', 'insightsSettingsClicks')
  }

  async getInsightsSettingsClick(id) {
    return this.makeRequest('get', 'insightsSettingsClick', {id})
  }

  async enableInsightsSettingsClick(id) {
    return this.makeRequest('enable', 'insightsSettingsClick', {id})
  }

  async disableInsightsSettingsClick(id) {
    return this.makeRequest('disable', 'insightsSettingsClick', {id})
  }

  async updateInsightsSettingsClick(click) {
    return this.makeRequest('update', 'insightsSettingsClick', click)
  }

  async createInsightsSettingsClick(click) {
    return this.makeRequest('create', 'insightsSettingsClick', click)
  }

  async deleteInsightsSettingsClick(id) {
    return this.makeRequest('delete', 'insightsSettingsClick', {id})
  }

  async copyInsightsSettingsClick(name, id) {
    return this.makeRequest('copy', 'insightsSettingsClick', {name, id})
  }

  async getInsightsSettingsStrategies() {
    return this.makeRequest('get', 'insightsSettingsStrategies')
  }

  async getInsightsSettingsStrategy(id) {
    return this.makeRequest('get', 'insightsSettingsStrategy', {id})
  }

  async enableInsightsSettingsStrategy(id) {
    return this.makeRequest('enable', 'insightsSettingsStrategy', {id})
  }

  async disableInsightsSettingsStrategy(id) {
    return this.makeRequest('disable', 'insightsSettingsStrategy', {id})
  }

  async updateInsightsSettingsStrategy(strategy) {
    return this.makeRequest('update', 'insightsSettingsStrategy', strategy)
  }

  async createInsightsSettingsStrategy(strategy) {
    return this.makeRequest('create', 'insightsSettingsStrategy', strategy)
  }

  async deleteInsightsSettingsStrategy(id) {
    return this.makeRequest('delete', 'insightsSettingsStrategy', {id})
  }

  async copyInsightsSettingsStrategy(name, id) {
    return this.makeRequest('copy', 'insightsSettingsStrategy', {name, id})
  }

  async getInsightsSettingsFunnels() {
    return this.makeRequest('get', 'insightsSettingsFunnels')
  }

  async getInsightsSettingsFunnel(id) {
    return this.makeRequest('get', 'insightsSettingsFunnel', {id})
  }

  async enableInsightsSettingsFunnel(id) {
    return this.makeRequest('enable', 'insightsSettingsFunnel', {id})
  }

  async disableInsightsSettingsFunnel(id) {
    return this.makeRequest('disable', 'insightsSettingsFunnel', {id})
  }

  async updateInsightsSettingsFunnel(funnel) {
    return this.makeRequest('update', 'insightsSettingsFunnel', funnel)
  }

  async createInsightsSettingsFunnel(funnel) {
    return this.makeRequest('create', 'insightsSettingsFunnel', funnel)
  }

  async deleteInsightsSettingsFunnel(id) {
    return this.makeRequest('delete', 'insightsSettingsFunnel', {id})
  }

  async copyInsightsSettingsFunnel(name, id) {
    return this.makeRequest('copy', 'insightsSettingsFunnel', {name, id})
  }

  async getInsightsFilters() {
    return this.makeRequest('get', 'insightsFilters')
  }

  // ----------------------------------------
  // PrivacyID

  async getPrivacyIdScripts() {
    return this.makeRequest('get', 'privacyIdScripts')
  }

  async getPrivacyIdSitesWithScriptsAndCategories() {
    return this.makeRequest('get', 'privacyIdSitesWithScriptsAndCategories')
  }

  async getPrivacyIdSites() {
    return this.makeRequest('get', 'privacyIdSites')
  }

  async getPrivacyIdDashboardData(startDate, endDate, siteKeys) {
    return this.makeRequest('get', 'privacyIdDashboard', {
      startDate,
      endDate,
      siteKeys
    })
  }

  async createPrivacyIdScript(script) {
    return this.makeRequest('create', 'privacyIdScript', script)
  }

  async updatePrivacyIdSite(siteId, name, categories) {
    return this.makeRequest('update', 'privacyIdSite', {
      id: siteId,
      name,
      categories
    })
  }

  async updatePrivacyIdSiteEndpoint(id, endpointPath) {
    return this.makeRequest('update', 'privacyIdSiteEndpoint', {
      id,
      endpointPath
    })
  }

  async updatePrivacyIdSiteProxySubdomain(id, proxySubdomain) {
    return this.makeRequest('update', 'privacyIdSiteProxySubdomain', {
      id,
      proxySubdomain
    })
  }

  async verifyPrivacyIdSiteEndpoint(id) {
    return this.makeRequest('verify', 'privacyIdSiteEndpoint', {id})
  }

  async verifyPrivacyIdSiteProxySubDomain(id) {
    return this.makeRequest('verify', 'privacyIdSiteProxySubDomain', {id})
  }

  async verifyPrivacyIdSiteJs(id) {
    return this.makeRequest('verify', 'privacyIdSiteJs', {id})
  }

  async createPrivacyIdSite(site) {
    return this.makeRequest('create', 'privacyIdSite', site)
  }

  async updatePrivacyIdScript(id, name) {
    return this.makeRequest('update', 'privacyIdScript', {
      id,
      name
    })
  }

  async pausePrivacyIdScript(id) {
    return this.makeRequest('pause', 'privacyIdScript', {
      id
    })
  }

  async activatePrivacyIdScript(id) {
    return this.makeRequest('activate', 'privacyIdScript', {
      id
    })
  }

  async deletePrivacyIdScript(id) {
    return this.makeRequest('delete', 'privacyIdScript', {
      id
    })
  }

  async pausePrivacyIdSite(id) {
    return this.makeRequest('pause', 'privacyIdSite', {
      id
    })
  }

  async activatePrivacyIdSite(id) {
    return this.makeRequest('activate', 'privacyIdSite', {
      id
    })
  }

  async deletePrivacyIdSite(id) {
    return this.makeRequest('delete', 'privacyIdSite', {
      id
    })
  }

  async removePrivacyIdSiteScript(siteId, scriptId) {
    return this.makeRequest('remove', 'privacyIdSiteScript', {
      siteId,
      scriptId
    })
  }

  async addPrivacyIdSiteScript(siteId, scriptId, categoryIds) {
    return this.makeRequest('add', 'privacyIdSiteScript', {
      siteId,
      scriptId,
      categoryIds
    })
  }

  async updatePrivacyIdSiteScript(siteId, scriptId, categoryIds) {
    return this.makeRequest('update', 'privacyIdSiteScript', {
      siteId,
      scriptId,
      categoryIds
    })
  }

  async checkPrivacyIdDomainIsTopLevel(domain) {
    return this.makeRequest('check', 'privacyIdDomainIsTopLevel', {
      domain
    })
  }

  // ------------------
  // Merch

  async getMerchProductLists() {
    return this.makeRequest('get', 'merchandisingProductLists')
  }

  async getMerchProductList(id) {
    return this.makeRequest('get', 'merchandisingProductList', {id})
  }

  async getMerchProductListLimit() {
    return this.makeRequest('get', 'merchandisingProductListLimit')
  }

  async getMerchProductListExportUrl(listId) {
    return this.makeRequest('get', 'merchandisingProductListExportUrl', {listId})
  }

  async getMerchProducts(listId, limit, offset, sortBy) {
    return this.makeRequest('get', 'merchandisingProducts', {
      listId,
      limit,
      offset,
      sortColumn: sortBy.column,
      sortDirection: sortBy.direction
    })
  }

  async getMerchProductsTotal(listId) {
    return this.makeRequest('get', 'merchandisingProductsTotal', {listId})
  }

  async getMerchDashboardLifetimeData() {
    return this.makeRequest('get', 'merchandisingLifetimeDashboardData')
  }
  async getMerchDashboardFilteredData(filter) {
    return this.makeRequest('get', 'merchandisingFilteredDashboardData', {
      filter
    })
  }
  async getMerchDashboardTopProducts(filter, sortBy) {
    return this.makeRequest('get', 'merchandisingDashboardTopProducts', {
      sortBy,
      filter
    })
  }
  async getMerchDashboardTopClickedProducts(filter) {
    return this.makeRequest('get', 'merchandisingDashboardTopClickedProducts', {
      filter
    })
  }
  async getMerchDashboardTopProductsExportUrl(filter, sortBy) {
    return this.makeRequest('get', 'merchandisingDashboardTopProductsExportUrl', {
      sortBy,
      filter
    })
  }
  async getMerchDashboardTopClickedProductsExportUrl(filter) {
    return this.makeRequest('get', 'merchandisingDashboardTopClickedProductsExportUrl', {
      filter
    })
  }
  async getMerchandisingDashboardFilteredAggregates(filter, groupBys) {
    return this.makeRequest('get', 'merchandisingDashboardAggregates', {
      filter,
      groupBys
    })
  }

  async getAccountCanUsePersonalShopper() {
    return this.makeRequest('get', 'accountCanUsePersonalShopper')
  }

  async getAccountCanUseMerchWidgets() {
    return this.makeRequest('get', 'accountCanUseMerchandisingWidgets')
  }

  async getAccountMaxPersonalShoppersPerCatalog() {
    return this.makeRequest('get', 'accountMaxPersonalShoppersPerCatalog')
  }

  async getMerchWidgetApps() {
    return this.makeRequest('get', 'merchandisingWidgetApps')
  }

  async getMerchPersonalShoppers() {
    return this.makeRequest('get', 'merchandisingPersonalShoppers')
  }

  async getMerchProductSources() {
    return this.makeRequest('get', 'merchandisingProductSources')
  }

  async getSignedUrlForMerchPersonalShopperScreenshot() {
    return this.makeRequest('get', 'signedUrlForMerchandisingPersonalShopperScreenshot')
  }

  async createMerchPersonalShopper(shopper) {
    return this.makeRequest('create', 'merchandisingPersonalShopper', shopper)
  }

  async createMerchWidgetApp(app) {
    return this.makeRequest('create', 'merchandisingWidgetApp', app)
  }

  async updateMerchPersonalShopper(shopper) {
    return this.makeRequest('update', 'merchandisingPersonalShopper', shopper)
  }

  async disableMerchPersonalShopper(shopperId) {
    return this.makeRequest('disable', 'merchandisingPersonalShopper', {
      shopperId
    })
  }

  async enableMerchPersonalShopper(shopperId) {
    return this.makeRequest('enable', 'merchandisingPersonalShopper', {
      shopperId
    })
  }

  async deleteMerchPersonalShopper(shopperId) {
    return this.makeRequest('delete', 'merchandisingPersonalShopper', {
      shopperId
    })
  }

  async updateMerchWidgetApp(app) {
    return this.makeRequest('update', 'merchandisingWidgetApp', app)
  }

  async disableMerchWidgetApp(appId) {
    return this.makeRequest('disable', 'merchandisingWidgetApp', {
      appId
    })
  }

  async enableMerchWidgetApp(appId) {
    return this.makeRequest('enable', 'merchandisingWidgetApp', {
      appId
    })
  }

  async deleteMerchWidgetApp(appId) {
    return this.makeRequest('delete', 'merchandisingWidgetApp', {
      appId
    })
  }
  
  async publishMerchPersonalShopper(shopper) {
    return this.makeRequest('publish', 'merchandisingPersonalShopper', shopper)
  }

  async publishMerchWidgetApp(app) {
    return this.makeRequest('publish', 'merchandisingWidgetApp', app)
  }
  
  async getMerchDashboardOrdersExportUrl(filter) {
    return this.makeRequest('get', 'merchandisingDashboardOrdersExportUrl', {
      filter
    })
  }

  async activateMerchProductList(id) {
    return this.makeRequest('activate', 'merchandisingProductList', {id})
  }

  async disableMerchProductList(id) {
    return this.makeRequest('disable', 'merchandisingProductList', {id})
  }

  async updateMerchProductList(list) {
    return this.makeRequest('update', 'merchandisingProductList', list)
  }

  async createMerchProductList(list) {
    return this.makeRequest('create', 'merchandisingProductList', list)
  }

  async evaluateMerchProductList(id) {
    return this.makeRequest('evaluate', 'merchandisingProductList', {id})
  }

  async getMerchProductDisplays() {
    return this.makeRequest('get', 'merchandisingProductDisplays')
  }

  async getMerchProductDisplay(id) {
    return this.makeRequest('get', 'merchandisingProductDisplay', {id})
  }

  async getMerchProductDisplayModels(forDashboard) {
    return this.makeRequest('get', 'merchandisingModelOptions', {
      forDashboard
    })
  }

  async activateMerchProductDisplay(id) {
    return this.makeRequest('activate', 'merchandisingProductDisplay', {id})
  }

  async disableMerchProductDisplay(id) {
    return this.makeRequest('disable', 'merchandisingProductDisplay', {id})
  }

  async createMerchProductDisplay(display) {
    return this.makeRequest('create', 'merchandisingProductDisplay', display)
  }

  async updateMerchProductDisplay(display) {
    return this.makeRequest('update', 'merchandisingProductDisplay', display)
  }

  async previewMerchProductDisplay(display, pageType, userKey, userAgent, categories, productId) {
    return this.makeRequest('preview', 'merchandisingProductDisplay', {
      display,
      pageType,
      userKey,
      userAgent,
      categories,
      productId
    })
  }

  async merchProductCatalogProducts(catalogId, filters) {
    return this.makeRequest('get', 'merchandisingProductCatalogProducts', {
      filters,
      catalogId
    })
  }

  async getCatalogs() {
    return this.makeRequest('get', 'productRecCatalogs')
  }

  async createCatalog(catalog) {
    return this.makeRequest('create', 'productRecCatalog', catalog)
  }

  async updateCatalog(catalog) {
    return this.makeRequest('update', 'productRecCatalog', catalog)
  }

  async getCatalogImportFormats() {
    return this.makeRequest('get', 'productRecCatalogImportFormats')
  }

  async getCatalog(id) {
    return this.makeRequest('get', 'productRecCatalog', {
      id
    })
  }

  async getCatalogSyncState(id) {
    return this.makeRequest('get', 'productRecCatalogSyncState', {
      id
    })
  }

  async getImports(catalogId, limit, offset) {
    const {imports, total} = await this.makeRequest('get', 'productRecImports', {
      catalogId,
      limit,
      offset
    })
    return {
      imports: imports && imports.map((item) => {
        return {
          ...item,
          filename: item.s3Path
            ? item.s3Path.split('/').pop()
            : null
        }
      }),
      total
    }
  }

  async downloadCatalogLogs({
    catalogId,
    importId
  }) {
    return this.makeRequest('download', 'catalogLogs', {
      catalogId,
      importId
    })
  }

  async downloadCatalogImport({
    catalogId,
    importId
  }) {
    return this.makeRequest('download', 'catalogImport', {
      catalogId,
      importId
    })
  }

  async getCatalogImportValidationResults({
    catalogId,
    importId
  }) {
    return this.makeRequest('get', 'catalogImportValidationResults', {
      catalogId,
      importId
    })
  }

  async downloadCatalogValidationVerboseLogs({
    catalogId,
    verboseLogUri
  }) {
    return this.makeRequest('download', 'catalogImportValidationLogs', {
      catalogId,
      verboseLogUri
    })
  }

  async importCatalog({
    catalogId,
    file,
    onProgress
  }) {
    const fileType = file.type || 'text/plain'
    const urlInfo = await this.makeRequest('create', 'importUrl', {
      catalogId,
      fileName: file.name,
      fileType: fileType
    })
    await Utils.uploadWithProgress(
      urlInfo.url,
      {
        method: 'PUT',
        headers: {
          'Content-Type': fileType
        },
        body: file
      },
      onProgress
    )
  }

  async importCatalogV2({
    catalogId,
    s3Key
  }) {
    return  this.makeRequest('import', 'catalog', {
      catalogId,
      s3Key
    })
  }

  async uploadCatalog({
    catalogId,
    file,
    onProgress
  }) {
    // const fileType = file.type || 'text/plain'
    const fileType = file.type || 'text/plain; charset=UTF-8'
    const urlInfo = await this.makeRequest('get', 'catalog-upload-signed-url', {
      catalogId,
      fileType,
      fileName: file.name
    })
    await Utils.uploadWithProgress(
      urlInfo.url,
      {
        method: 'PUT',
        headers: {
          'Content-Type': fileType
        },
        body: file
      },
      onProgress
    )
    return urlInfo
  }

  async validateCatalog({
    catalogId,
    s3Key,
    locale
  }) {
    return this.makeRequest('validate', 'catalog', {
      catalogId,
      s3Key,
      locale
    })
  }

  // ------------------

  async getWidgetNames() {
    const widgets = await this.makeRequest('get', 'widgets')
    return widgets.map(w => {
      return {
        id: w.id,
        name: w.name
      }
    })
  }

  async getCurrentAccountTimezone() {
    return this.makeRequest('get', 'accountTimezone')
  }

  async getCurrentAccountCurrency() {
    return this.makeRequest('get', 'accountCurrency')
  }

  async getInvoices() {
    return this.makeRequest('get', 'invoices')
  }

  async getInvoiceDowloadUrl(invoiceNumber) {
    return this.makeRequest('get', 'invoiceDownloadUrl', {
      invoiceNumber
    })
  }

  async getUsageReports(month) {
    return this.makeRequest('get', 'usageReports', {
      month
    })
  }

  async getConversions(filter, limit, offset) {
   return this.makeRequest('get', 'conversions', {
      filter,
      limit,
      offset
    })
  }

  async getConversionTotals(filter) {
    return this.makeRequest('get', 'conversionTotals', {
      filter
    })
  }

  // --------------------------------------------------------
  // Conversion Defintitions
  async getConversionDefinitions() {
    return this.makeRequest('get', 'conversionDefinitions')
  }

  async getSegmentTags() {
    return this.makeRequest('get', 'segment-tags')
  }

  async getConversionDefinitionCategories() {
    return this.makeRequest('get', 'conversionDefinitionCategories')
  }

  async setConversionDefinitionStatus(id, status) {
    return this.makeRequest('set', 'conversionDefinitionStatus', {
      id,
      status
    })
  }

  async getSitesWithTerms() {
    return this.makeRequest('get', 'sitesWithTerms')
  }

  async getLineItems() {
    return this.makeRequest('get', 'lineItems')
  }

  async getInvoicingPreferences() {
    return this.makeRequest('get','invoicingPreferences')
  }

  async updateConversionCalculationRuleToAll(siteId) {
    return this.makeRequest('update', 'conversionCalculationRuleToAll', {
      siteId
    })
  }

  async updateConversionCalculationRule(siteId, rule) {
    return this.makeRequest('update', 'conversionCalculationRule', {
      siteId,
      rule
    })
  }

  async updateConversionDefinition(def) {
    return this.makeRequest('update', 'conversionDefinition', {
      def
    })
  }

  async addConversionDefinition(def) {
    return this.makeRequest('create', 'conversionDefinition', {
      def
    })
  }
  // --------------------------------------------------------

  async getTransactions(filter, limit, offset) {
    return this.makeRequest('get', 'transactions', {
      filter,
      limit,
      offset
    })
  }

  async getTransactionTotals(filter) {
    return this.makeRequest('get', 'transactionTotals', {
      filter
    })
  }

  async getTransactionsExportUrl(filter) {
    return this.makeRequest('get', 'transactionsExportUrl', { filter })
  }

  async getConversionsBySessionKey(sessionKey) {
    return this.makeRequest('get', 'conversionsSessionKey', {
      sessionKey
    })
  }

  async getSalesOrdersBySessionKey(sessionKey) {
    return this.makeRequest('get', 'ordersSessionKey', {
      sessionKey
    })
  }

  async getConversionsExportUrl(filter) {
    return this.makeRequest('get', 'conversionsExportUrl', { filter })
  }

  async getCampaigns() {
    return this.makeRequest('get', 'campaigns')
  }

  async getCampaignNames() {
    return this.makeRequest('get', 'campaignNames')
  }

  async getCampaign(campaignId) {
    return this.makeRequest('get', 'campaign', {
      campaignId
    })
  }

  async getValueSymbolForCampaign(campaignId) {
    return this.makeRequest('get', 'campaignValueSymbol', {
      campaignId
    })
  }

  async updateCampaignOptions(campaign) {
    let campaignCopy = Object.assign({}, campaign)
    return this.makeRequest('update', 'campaignOptions', campaignCopy)
  }

  // could this be the same as above?
  async updateCampaign(campaign) {
    let campaignCopy = Object.assign({}, campaign)
    return this.makeRequest('update', 'campaign', campaignCopy)
  }

  async createCampaign(newName) {
    return this.makeRequest('create', 'campaign', {
      name: newName
    })
  }

  async startCopyCampaign(campaignId, newName) {
    return this.makeRequest('copy', 'campaign', {
      name: newName,
      campaignId
    })
  }

  async pingProcessStatus() {
    return this.makeRequest('ping', 'processes')
  }

  async dismissBackgroundProcess(id) {
    return this.makeRequest('dismiss', 'process', {id})
  }

  async getCampaignExceptions(filter, limit, offset) {
    return this.makeRequest('get', 'campaignExceptions', {
      filter,
      limit,
      offset
    })
  }


  async getCampaignValidationWarnings(campaignId) {
    return this.makeRequest('validate', 'campaign', {
      campaignId
    })
  }

  async publishCampaign(campaignId) {
    return this.makeRequest('publish', 'campaigns', {
      campaignId
    })
  }

  async trashCampaign(campaignId) {
    return this.makeRequest('trash', 'campaign', {
      campaignId
    })
  }

  async pauseCampaignOffers(campaignId) {
    return this.makeRequest('pause', 'campaignOffers', {
      campaignId
    })
  }

  async pauseCampaignWidgets(campaignId) {
    return this.makeRequest('pause', 'campaignWidgets', {
      campaignId
    })
  }

  async unpauseCampaignOffers(campaignId) {
    return this.makeRequest('unpause', 'campaignOffers', {
      campaignId
    })
  }

  async unpauseCampaignWidgets(campaignId) {
    return this.makeRequest('unpause', 'campaignWidgets', {
      campaignId
    })
  }

  async expireCampaign(campaignId) {
    return this.makeRequest('expire', 'campaign', {
      campaignId
    })
  }

  async makeDraftCampaign(campaignId) {
    return this.makeRequest('draft', 'campaign', {
      campaignId
    })
  }

  async getSegmentActions(campaignId) {
    return this.makeRequest('get', 'segmentActions', {
      campaignId
    })
  }

  async getSegmentAction(actionId) {
    return this.makeRequest('get', 'segmentAction', {
      segmentActionId: actionId
    })
  }

  async disableSegmentActions(campaignId, selectedIds) {
    return this.makeRequest('disable', 'segmentActions', {
      campaignId,
      segmentActionIds: selectedIds
    })
  }

  async deleteSegmentActions(campaignId, selectedIds) {
    return this.makeRequest('delete', 'segmentActions', {
      campaignId,
      segmentActionIds: selectedIds
    })
  }

  async enableSegmentActions(campaignId, selectedIds) {
    return this.makeRequest('enable', 'segmentActions', {
      campaignId,
      segmentActionIds: selectedIds
    })
  }

  async saveBulkSegmentActions(actions, campaignId) {
    return this.makeRequest('update', 'segmentActionsBulk', {
      actions,
      campaignId
    })
  }

  async createSegmentAction(action) {
    return this.makeRequest('create', 'segmentAction', action)
  }

  async updateSegmentAction(action) {
    return this.makeRequest('update', 'segmentAction', action)
  }

  async enableSegmentAction(segmentActionId, campaignId) {
    return this.makeRequest('enable', 'segmentAction', {
      segmentActionId,
      campaignId
    })
  }

  async disableSegmentAction(segmentActionId, campaignId) {
    return this.makeRequest('disable', 'segmentAction', {
      segmentActionId,
      campaignId
    })
  }

  async getExperiments(campaignId) {
    return this.makeRequest('get', 'experiments', {
      campaignId
    })
  }

  async getExperiment(experimentId) {
    return this.makeRequest('get', 'experiment', {
      experimentId
    })
  }

  // returns full object of inserted segment
  async copyExperiment(name, experimentId) {
    return this.makeRequest('copy', 'experiment', {
      experimentId,
      name
    })
  }

  async createExperiment(experiment) {
    return this.makeRequest('create', 'experiment', experiment)
  }

  async updateExperiment(experiment) {
    return this.makeRequest('update', 'experiment', experiment)
  }

  async deleteExperiment(experimentId, campaignId) {
    return this.makeRequest('delete', 'experiment', {
      experimentId,
      campaignId
    })
  }

  async enableExperiment(experimentId, campaignId) {
    return this.makeRequest('enable', 'experiment', {
      experimentId,
      campaignId
    })
  }

  async disableExperiment(experimentId, campaignId) {
    return this.makeRequest('disable', 'experiment', {
      experimentId,
      campaignId
    })
  }

  async disableExperiments(campaignId, selectedIds) {
    return this.makeRequest('disable', 'experiments', {
      campaignId,
      experimentIds: selectedIds
    })
  }

  async deleteExperiments(campaignId, selectedIds) {
    return this.makeRequest('delete', 'experiments', {
      campaignId,
      experimentIds: selectedIds
    })
  }

  async enableExperiments(campaignId, selectedIds) {
    return this.makeRequest('enable', 'experiments', {
      campaignId,
      experimentIds: selectedIds
    })
  }

  async getSegments(campaignId) {
    return this.makeRequest('get', 'segments', {
      campaignId
    })
  }

  async getSegmentsByCampaignIds(campaignIds) {
    return this.makeRequest('get', 'segmentsByCampaignIds', {
      campaignIds
    })
  }

  async getIncentivesByIds(offerIds, campaignId) {
    if (typeof offerIds === 'string') {
      offerIds = [offerIds]
    }
    offerIds = offerIds.map(id => parseInt(id))
    return this.makeRequest('get', 'offersByIds', {
      campaignId,
      offerIds
    })
  }

  async getSegment(segmentId) {
    return this.makeRequest('get', 'segment', {
      segmentId
    })
  }

  async deleteSegment(segmentId, campaignId) {
    return this.makeRequest('delete', 'segment', {
      segmentId,
      campaignId
    })
  }

  async deleteSegmentAction(segmentActionId, campaignId) {
    return this.makeRequest('delete', 'segmentAction', {
      segmentActionId,
      campaignId
    })
  }

  async enableSegment(segmentId, campaignId) {
    return this.makeRequest('enable', 'segment', {
      segmentId,
      campaignId
    })
  }

  async disableSegment(segmentId, campaignId) {
    return this.makeRequest('disable', 'segment', {
      segmentId,
      campaignId
    })
  }

  async createSegment(segment) {
    return this.makeRequest('create', 'segment', segment)
  }

  // returns full object of inserted segment
  async copySegment(name, segmentId, campaignId) {
    return this.makeRequest('copy', 'segment', {
      segmentId,
      name,
      campaignId
    })
  }

  // returns full object of inserted segment
  async copySegmentAction(name, segmentActionId) {
    return this.makeRequest('copy', 'segmentAction', {
      segmentActionId,
      name
    })
  }

  async updateSegment(segment) {
    return this.makeRequest('update', 'segment', segment)
  }

  async saveBulkSegments(changes, campaignId) {
    return this.makeRequest('update', 'segmentsBulk', {
      changes,
      campaignId
    })
  }

  async saveBulkExperiments(experiments, campaignId) {
    return this.makeRequest('update', 'experimentsBulk', {
      experiments,
      campaignId
    })
  }

  async removeActionFromSegment(segmentId, actionId, campaignId) {
    return this.makeRequest('delete', 'segmentActionLink', {
      segmentId,
      actionId,
      campaignId
    })
  }

  async removeActionFromExperiment(experimentId, actionId, campaignId) {
    return this.makeRequest('delete', 'experimentActionLink', {
      experimentId,
      actionId,
      campaignId
    })
  }

  async removeExperimentFromSegment(segmentId, experimentId, campaignId) {
    return this.makeRequest('delete', 'segmentExperimentLink', {
      segmentId,
      experimentId,
      campaignId
    })
  }

  async getIncentive(id) {
    return this.makeRequest('get', 'offer', {
      offerId: id
    })
  }

  async getIncentives(campaignId) {
    return this.makeRequest('get', 'offers', {
      campaignId
    })
  }

  async deleteIncentive(id, campaignId) {
    return this.makeRequest('delete', 'offer', {
      offerId: id,
      campaignId
    })
  }

  async enableIncentive(id, campaignId) {
    return this.makeRequest('enable', 'offer', {
      offerId: id,
      campaignId
    })
  }

  async disableIncentive(id, campaignId) {
    return this.makeRequest('disable', 'offer', {
      offerId: id,
      campaignId
    })
  }

  async enableIncentives(campaignId, offerIds) {
    return this.makeRequest('enable', 'offers', {
      campaignId,
      offerIds
    })
  }

  async disableIncentives(campaignId, offerIds) {
    return this.makeRequest('disable', 'offers', {
      campaignId,
      offerIds
    })
  }

  async deleteIncentives(campaignId, offerIds) {
    return this.makeRequest('delete', 'offers', {
      campaignId,
      offerIds
    })
  }

  async mergeIncentives(campaignId, offerIds, primaryOfferId, name, disableOriginal) {
    return this.makeRequest('merge', 'offers', {
      campaignId,
      offerIds,
      primaryOfferId,
      name,
      disableOriginal
    })
  }

  async saveIncentive(incentive) {
    return this.makeRequest('update', 'offer', incentive)
  }

  async createIncentive(incentive) {
    return this.makeRequest('create', 'offer', incentive)
  }

  async saveBulkIncentives(changes, campaignId) {
    return this.makeRequest('update', 'offersBulk', {
      changes,
      campaignId
    })
  }

  // returns newly created incentive
  async copyIncentive(incentiveId, name) {
    return this.makeRequest('copy', 'offer', {
      offerId: incentiveId,
      name
    })
  }

  async getSharedRulesets() {
    return this.makeRequest('get', 'sharedRulesets')
  }

  async getSharedRuleset(rulesetId) {
    return this.makeRequest('get', 'sharedRuleset', {
      rulesetId
    })
  }

  // returns newly created ruleset
  async createAccountRuleset(name) {
    return this.makeRequest('insert', 'sharedRuleset', {
      name
    })
  }

  async updateAccountRuleset(ruleset) {
    return this.makeRequest('update', 'sharedRuleset', {
      ruleset
    })
  }

  // returns newly created ruleset
  async copyAccountRuleset(ruleset, name) {
    return this.makeRequest('copy', 'sharedRuleset', {
      ruleset,
      name
    })
  }

  async deleteAccountRuleset(ruleset) {
    return this.makeRequest('delete', 'sharedRuleset', {
      ruleset
    })
  }

  async setAccountRulesetOnSegments(id, campaignId, segmentIds) {
    return this.makeRequest('set', 'sharedRulesetOnSegments', {
      id,
      campaignId,
      segmentIds
    })
  }

  async getAccountRulesetsForSegmentation() {
    return this.makeRequest('get', 'accountRulesetsSegmentation')
  }

  async getPublishedWidgetsForAccount() {
    return this.makeRequest('creative', 'getPublishedWidgets')
  }

  async getWidgetsForSegmentation() {
    let widgets = await this.makeRequest('get', 'widgets')
    return widgets
      .filter(w => w.publishedId > 0)
      .map(w => {
        return {
          id: w.id,
          name: w.name,
          type: w.type,
          publishedProductDisplayId: w.publishedProductDisplayId,
          publishedProductDisplayType: w.publishedProductDisplayType,
          publishedHasOfferVars: w.publishedHasOfferVars,
          publishedIsPersistent: w.publishedIsPersistent,
          status: w.status
        }
      })
      .sort((a, b) => {
        if (a.status.toLowerCase().trim() > b.status.toLowerCase().trim()) return 1
        if (a.status.toLowerCase().trim() < b.status.toLowerCase().trim()) return -1

        if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) return 1
        if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) return -1
      })
  }

  async getWidgetInfos() {
    return this.makeRequest('get', 'widgetInfos')
  }

  async getSessionDetails(sessionKey, loadExtra = true) {
    return this.makeRequest('session', 'get', {
      sessionKey,
      loadExtra
    })
  }

  async expireSession(sessionKey, host) {
    return this.makeRequest('session', 'expire', {
      sessionKey,
      host
    })
  }

  async getUserHistory(siteId, userKey, accountKey, includeExtra = false) {
    return this.makeRequest('userHistory', 'get', {
      siteId,
      userKey,
      accountKey,
      includeExtra
    })
  }

  async uploadCodes(formData) {
    return this.makeRequest('upload', 'offerCodes', formData, true)
  }

  async insertCodes(offerId, uniqueName) {
    return this.makeRequest('insert', 'offerCodes', {
      offerId,
      uniqueName
    })
  }

  async getCdpEndpoint() {
    return this.makeRequest('get', 'cdp-account-endpoint')
  }
  async getCdpAudiences() {
    return this.makeRequest('get', 'cdp-audiences')
  }
  async getCdpAudience(audienceId) {
    return this.makeRequest('get', 'cdp-audience', {audienceId})
  }
  async createCdpAudience(audience) {
    return this.makeRequest('create', 'cdp-audience', audience)
  }
  async updateCdpAudience(audience) {
    return this.makeRequest('update', 'cdp-audience', audience)
  }
  async previewCdpAudience(filters) {
    return this.makeRequest('preview', 'cdp-audience', {filters})
  }
  async evaluateCdpAudience(id) {
    return this.makeRequest('evaluate', 'cdp-audience', {
      audienceId: id
    })
  }
  async getCdpAudienceCounts(id) {
    return this.makeRequest('get', 'cdp-audience-counts', {
      audienceId: id
    })
  }
  async getCdpAllAudienceCounts() {
    return this.makeRequest('get', 'cdp-audiences-counts')
  }
  async copyCdpAudience(name, id) {
    return this.makeRequest('copy', 'cdp-audience', {
      name,
      audienceId: id
    })
  }
  async deleteCdpAudience(audienceId) {
    return this.makeRequest('delete', 'cdp-audience', {audienceId})
  }
  async enableCdpAudience(audienceId) {
    return this.makeRequest('enable', 'cdp-audience', {audienceId})
  }
  async disableCdpAudience(audienceId) {
    return this.makeRequest('disable', 'cdp-audience',{audienceId})
  }

  async getCdpPipelines() {
    return this.makeRequest('get', 'cdp-pipelines')
  }
  async getCdpPipeline(pipelineId) {
    return this.makeRequest('get', 'cdp-pipeline', {pipelineId})
  }
  async createCdpPipeline(pipeline) {
    return this.makeRequest('create', 'cdp-pipeline', pipeline)
  }
  async updateCdpPipeline(pipeline) {
    return this.makeRequest('update', 'cdp-pipeline', pipeline)
  }
  async copyCdpPipeline(name, id) {
    return this.makeRequest('copy', 'cdp-pipeline', {
      name,
      pipelineId: id
    })
  }
  async deleteCdpPipeline(pipelineId) {
    return this.makeRequest('delete', 'cdp-pipeline', {pipelineId})
  }
  async enableCdpPipeline(pipelineId) {
    return this.makeRequest('enable', 'cdp-pipeline', {pipelineId})
  }
  async disableCdpPipeline(pipelineId) {
    return this.makeRequest('disable', 'cdp-pipeline',{pipelineId})
  }

  async getCdpExports() {
    return this.makeRequest('get', 'cdp-exports')
  }
  async getCdpExport(exportId) {
    return this.makeRequest('get', 'cdp-export', {exportId})
  }
  async getCdpExportDownloadUrl(exportId) {
    return this.makeRequest('get', 'cdp-export-signed-url', {exportId})
  }
  async createCdpExport(exp, runNow) {
    return this.makeRequest('create', 'cdp-export', {
      rawExport: exp,
      runNow
    })
  }
  async updateCdpExport(exp) {
    return this.makeRequest('update', 'cdp-export', exp)
  }
  async copyCdpExport(name, id) {
    return this.makeRequest('copy', 'cdp-export', {
      name,
      exportId: id
    })
  }
  async deleteCdpExport(exportId) {
    return this.makeRequest('delete', 'cdp-export', {exportId})
  }
  async enableCdpExport(exportId) {
    return this.makeRequest('enable', 'cdp-export', {exportId})
  }
  async disableCdpExport(exportId) {
    return this.makeRequest('disable', 'cdp-export',{exportId})
  }
  async previewCdpExport(filters, type) {
    return this.makeRequest('preview', 'cdp-export', {filters, type})
  }
  async evaluateCdpExport(exportId) {
    return this.makeRequest('evaluate', 'cdp-export', {exportId})
  }
  async downloadCdpExport(exportId) {
    return this.makeRequest('download', 'cdp-export', {exportId})
  }

  async getCdpDictionaryEvents() {
    return this.makeRequest('get', 'cdp-dictionary-events')
  }
  async getCdpDictionaryEventSchema(type, event = '') {
    return this.makeRequest('get', 'cdp-dictionary-event-schema', {
      event,
      type
    })
  }
  async getCdpDictionaryEventAttributes() {
    return this.makeRequest('get', 'cdp-dictionary-event-attributes')
  }
  async getCdpDictionaryUserAttributes() {
    return this.makeRequest('get', 'cdp-dictionary-user-attributes')
  }

  async getCdpDataHealth(datePeriod, eventName = null) {
    return this.makeRequest('get', 'cdp-data-health', {
      datePeriod,
      eventName
    })
  }

  async getCdpDataHealthErrorsByEvent(datePeriod, eventName) {
    return this.makeRequest('get', 'cdp-data-health-errors-event', {
      eventName,
      datePeriod
    })
  }

  async getCdpDataHealthSpecificErrorData(datePeriod, eventName, errorCode) {
    return this.makeRequest('get', 'cdp-data-health-error-data', {
      eventName,
      errorCode,
      datePeriod
    })
  }

  async getCdpPiiComplianceStatistics() {
    return this.makeRequest('get', 'cdp-pii-compliance-statistics')
  }
  async getCdpPiiLogs(limit, offset, requestPii = false, addPiiLog = false) {
    return this.makeRequest('get', 'cdp-pii-logs', {
      limit,
      offset,
      requestPii,
      addPiiLog
    })
  }

  async getCdpProfile(userKey, requestPii = false) {
    return this.makeRequest('get', 'cdp-user-profile', {
      userKey,
      requestPii
    })
  }
  async getCdpProfileExists(userKey) {
    return this.makeRequest('get', 'cdp-user-profile-exists', {
      userKey
    })
  }
  async getCdpProfileEvents(userKey, requestPii = false, endDate = null) {
    return this.makeRequest('get', 'cdp-user-profile-events', {
      userKey,
      requestPii,
      endDate
    })
  }

  async createCdpDictionaryEvent(object) {
    return this.makeRequest('create', 'cdp-dictionary-event', object)
  }
  async updateCdpDictionaryEvent(object) {
    return this.makeRequest('update', 'cdp-dictionary-event', object)
  }
  async updateCdpDictionaryEventAttribute(object) {
    return this.makeRequest('update', 'cdp-dictionary-event-attribute', object)
  }
  async updateCdpDictionaryUserAttribute(object) {
    return this.makeRequest('update', 'cdp-dictionary-user-attribute', object)
  }
  async createCdpDictionaryEventAttribute(object) {
    return this.makeRequest('create', 'cdp-dictionary-event-attribute', object)
  }
  async createCdpDictionaryUserAttribute(object) {
    return this.makeRequest('create', 'cdp-dictionary-user-attribute', object)
  }

  async getCdpEventMapping() {
    return this.makeRequest('get', 'cdp-event-mapping')
  }

  async updateCdpEventMapping(object) {
    return this.makeRequest('update', 'cdp-event-mapping', object)
  }

  async updateCdpEventAttributeMapping(object) {
    return this.makeRequest('update', 'cdp-event-attribute-mapping', object)
  }

  async deleteCdpEventMapping(id) {
    return this.makeRequest('delete', 'cdp-event-mapping', {id})
  }

  async deleteCdpEventAttributeMapping(id) {
    return this.makeRequest('delete', 'cdp-event-attribute-mapping', {id})
  }

  async createCdpEventMapping(object) {
    return this.makeRequest('create', 'cdp-event-mapping', object)
  }

  async createCdpEventAttributeMapping(object) {
    return this.makeRequest('create', 'cdp-event-attribute-mapping', object)
  }

  async getCdpEventAttributeMapping() {
    return this.makeRequest('get', 'cdp-event-attribute-mapping')
  }

  async getCdpIntegrationConfigs() {
    return this.makeRequest('get', 'cdp-integration-configs')
  }
  async getCdpIntegrationConsents() {
    return this.makeRequest('get', 'cdp-integration-consents')
  }
  async getCdpDestinations(includeFullJson = false) {
    return this.makeRequest('get', 'cdp-destinations', {
      includeFullJson
    })
  }
  async getCdpDestinationReferences(id = null) {
    return this.makeRequest('get', 'cdp-destination-references', {
      destinationId: id
    })
  }
  async getCdpSourceReferences(id = null) {
    return this.makeRequest('get', 'cdp-source-references', {
      sourceId: id
    })
  }
  async getCdpDestination(id) {
    return this.makeRequest('get', 'cdp-destination', {
      destinationId: id
    })
  }
  async getCdpDestinationActivity(id, dateRange) {
    return this.makeRequest('get', 'cdp-destination-activity', {
      destinationId: id,
      dateRange
    })
  }
  async createCdpDestination(destination) {
    return this.makeRequest('create', 'cdp-destination', destination)
  }
  async updateCdpDestination(destination) {
    return this.makeRequest('update', 'cdp-destination', destination)
  }
  async enableCdpDestination(id) {
    return this.makeRequest('enable', 'cdp-destination', {
      destinationId: id
    })
  }
  async disableCdpDestination(id) {
    return this.makeRequest('disable', 'cdp-destination', {
      destinationId: id
    })
  }
  async deleteCdpDestination(id) {
    return this.makeRequest('delete', 'cdp-destination', {
      destinationId: id
    })
  }
  async copyCdpDestination(name, id) {
    return this.makeRequest('copy', 'cdp-destination', {
      name,
      destinationId: id
    })
  }

  async getCdpSources(includeFullJson = false) {
    return this.makeRequest('get', 'cdp-sources', {
      includeFullJson
    })
  }
  async getCdpSource(id) {
    return this.makeRequest('get', 'cdp-source', {
      sourceId: id
    })
  }
  async createCdpSource(source) {
    return this.makeRequest('create', 'cdp-source', source)
  }
  async updateCdpSource(source) {
    return this.makeRequest('update', 'cdp-source', source)
  }
  async enableCdpSource(id) {
    return this.makeRequest('enable', 'cdp-source', {
      sourceId: id
    })
  }
  async disableCdpSource(id) {
    return this.makeRequest('disable', 'cdp-source', {
      sourceId: id
    })
  }
  async deleteCdpSource(id) {
    return this.makeRequest('delete', 'cdp-source', {
      sourceId: id
    })
  }
  async copyCdpSource(name, id) {
    return this.makeRequest('copy', 'cdp-source', {
      name,
      sourceId: id
    })
  }

  async createCdpIntegrationConnection(integrationId, capabilityId, connection) {
    return this.makeRequest('create', 'cdp-integration-connection', {
      integrationId,
      capabilityId,
      connection
    })
  }

  async createEntityCdpIntegration(integrationId, capabilityId, intToken, properties) {
    return this.makeRequest('create', 'cdp-integration-entity', {
      integrationId,
      capabilityId,
      intToken,
      properties
    })
  }

  async disconnectCdpIntegration(id, intToken, json, type) {
    return this.makeRequest('disconnect', 'cdp-integration-connection', {
      id,
      intToken,
      json,
      type
    })
  }

  async testCdpIntegrationConnection(integrationId, capabilityId, intToken, connection = null, optionProvider = null) {
    return this.makeRequest('test', 'cdp-integration-connection', {
      integrationId,
      capabilityId,
      intToken,
      connection,
      optionProvider
    })
  }

  async validateCdpIntegrationOAuth(integrationId, capabilityId, connection) {
    return this.makeRequest('validate', 'cdp-integration-oauth', {
      integrationId,
      capabilityId,
      connection
    })
  }

  async getCdpErrors() {
    return this.makeRequest('get', 'cdp-errors')
  }

  async getCdpDashboards() {
    return this.makeRequest('get', 'cdp-dashboards')
  }

  async getCdpLandingData() {
    return this.makeRequest('get', 'cdp-landing-data')
  }

  async getCdpSettings() {
    return this.makeRequest('get', 'cdp-settings')
  }
  async updateCdpSettings(settings) {
    return this.makeRequest('update', 'cdp-settings', settings)
  }

  async getCdpOnboardingState() {
    return this.makeRequest('get', 'cdp-onboarding-state')
  }
  async insertCdpOnboardingState(name, def, download, locale) {
    return this.makeRequest('insert', 'cdp-onboarding-state', {
      name,
      definition: def,
      download,
      locale,
      devFlag: Env.isDevelopmentLive()
    })
  }

  // ----------------------------------------
  // image library
  async getImages() {
    return this.makeRequest('images', 'query')
  }

  async uploadImages(formData) {
    return this.makeRequest('images', 'upload', formData, true)
  }

  async removeImage(id) {
    return this.makeRequest('images', 'delete', {
      id
    })
  }

  // ----------------------------------------
  // notes
  async getCampaignNotes(campaignId, limit, selected, filter) {
    return this.makeRequest('campaignNotes', 'query', {
      campaignId,
      selected,
      limit, 
      filter
    })
  }
  async addCampaignNote(campaignId, notes) {
    return this.makeRequest('campaignNotes', 'record', {
      campaignId,
      notes
    })
  }
  async removeCampaignNote(campaignId, note) {
    return this.makeRequest('campaignNotes', 'delete', {
      campaignId,
      id: note.id
    })
  }

  async getAccountNotes(limit, selected, filter) {
    return this.makeRequest('accountNotes', 'query', {
      selected,
      limit,
      filter
    })
  }

  // async addRevisionNote(revisionId, notes) {
  //   return this.makeRequest('creative', 'notes/create', {
  //     revisionId,
  //     notes
  //   })
  // }
  // async getRevisionNotes(revisionId) {
  //   return this.makeRequest('creative', 'notes/list', {
  //     revisionId
  //   })
  // }
  // async getRevisionNotesByWidget(widgetId) {
  //   return this.makeRequest('creative', 'notes/listByWidget', {
  //     widgetId
  //   })
  // }
  // async getRevisionNotesCount(revisionId) {
  //   return this.makeRequest('creative', 'notes/count', {
  //     revisionId
  //   })
  // }
  // async deleteRevisionNote(id) {
  //   return this.makeRequest('creative', 'notes/delete', {
  //     id
  //   })
  // }

  async addCookieForProtectedUrl() {
    return this.makeRequest('auth', 'setCookiesForProtectedUrl')
  }

  // ----------------------------------------
  // notifications
  async getMessagingData() {
    return this.makeRequest('messaging', 'baseData')
  }

  // simply returns a jobId
  async getNotificationCountStart(notification) {
    return this.makeRequest('notifications', 'count-start', notification)
  }
  // returns the actual counts
  async getNotificationCountCheck(jobId) {
    return this.makeRequest('notifications', 'count-check', {
      jobId: jobId
    })
  }

  // notifications dashboard
  async getNotificationDashboardSubscriberCounts(filter) {
    return this.makeRequest('notifications', 'dashboard-subscribers', {
      filter
    })
  }
  async getNotificationDashboardNotifications(filter) {
    return this.makeRequest('notifications', 'dashboard-notifications', {
      filter
    })
  }
  async getNotificationDashboardInteractions(filter) {
    return this.makeRequest('notifications', 'dashboard-interaction-trends', {
      filter
    })
  }
  async getNotificationDashboardPermissions(filter) {
    return this.makeRequest('notifications', 'dashboard-permission-trends', {
      filter
    })
  }

  // ------------------------------------------
  // sms
  async getSmsMessages() {
    return this.makeRequest('get', 'smsMessages')
  }

  async getSmsMessage(id) {
    return this.makeRequest('get', 'smsMessage', {id})
  }

  async getSmsMessagingServiceNumber(siteId) {
    return this.makeRequest('get', 'smsMessagingServiceNumber', {siteId})
  }

  async getSmsDashboardCount(startDate, endDate) {
    return this.makeRequest('get', 'smsDashboardCount', {
      startDate,
      endDate
    })
  }

  async getSmsMessagesDashboard(startDate, endDate) {
    return this.makeRequest('get', 'smsMessagesDashboard', {
      startDate,
      endDate
    })
  }

  async updateSmsMessage(message) {
    return this.makeRequest('update', 'smsMessage', message)
  }

  async deleteSmsMessage(id) {
    return this.makeRequest('delete', 'smsMessage', {id})
  }

  async pauseSmsMessage(id) {
    return this.makeRequest('pause', 'smsMessage', {id})
  }

  async activateSmsMessage(id) {
    return this.makeRequest('activate', 'smsMessage', {id})
  }

  async getSmsSiteSettings() {
    return this.makeRequest('get', 'smsSiteSettings')
  }

  async getSmsCountryOverrides(siteId) {
    return this.makeRequest('get', 'smsCountryOverrides', {siteId})
  }

  async getSmsSettings() {
    return this.makeRequest('get', 'smsSettings')
  }

  async updateSmsSettings(settingObj) {
    return this.makeRequest('update', 'smsSettings', settingObj)
  }

  async copySmsMessage(id, name) {
    return this.makeRequest('copy', 'smsMessage', {
      id,
      startDate: dayjs().subtract(1, 'years').format('YYYY-MM-DD 00:00:00'),
      endDate: dayjs().format('YYYY-MM-DD 00:00:00'),
      name
    })
  }

  async createSms(name, siteId, type, version = null) {
    return this.makeRequest('create', 'smsMessage', {
      name,
      siteId,
      type,
      version
    })
  }

  async sendTestSmsMessage(smsId, phoneNumber) {
    return this.makeRequest('send', 'smsTestMessage', {
      smsId,
      phoneNumber
    })
  }

  async getSmsMessageCountStart(message) {
    return this.makeRequest('get', 'smsMessageCountStart', message)
  }
  async getSmsMessageCountCheck(jobId) {
    return this.makeRequest('get', 'smsMessageCountCheck', {
      jobId
    })
  }

  async getSmsPricePerMessage(siteId, currency) {
    return this.makeRequest('get', 'smsMessagePrice', {
      siteId,
      currency
    })
  }



  // ----------------------------------------
  // insights
  async getInsightsDashboardsForAccount() {
    return this.makeRequest('get', 'insightsDashboardsForAccount')
  }

  async getInsightsDashboardsPermissionsForAccount() {
    return this.makeRequest('get', 'insightsDashboardsPermissionsForAccount')
  }

  // ----------------------------------------
  // translations
  async translateJson(json, add, override) {
    return this.makeRequest('translations', 'translateJson', {
      json,
      add,
      override
    })
  }

  async getBaseTranslations(app) {
    if (!app) {
      app = null
    }
    return this.makeRequest('translations', 'getBase', {
      app
    })
  }

  async getTranslations(language, app) {
    if (!app) {
      app = null
    }
    return this.makeRequest('translations', 'getTranslations', {
      app,
      language
    })
  }

  async saveTranslation(id, value, approved, app) {
    if (!app) app = null
    return this.makeRequest('translations', 'save', {
      id,
      value,
      approved,
      app
    })
  }

  async saveTranslationAndRegenerate(id, value, approved, app, languages) {
    if (!app) app = null
    return this.makeRequest('translations', 'saveRegenerate', {
      id,
      value,
      approved,
      app,
      languages
    })
  }

  async saveBulkJsonTranslation(transObj, app, languages) {
    if (!app) app = null
    return this.makeRequest('translations', 'saveBulkJson', {
      transObj,
      app,
      languages
    })
  }

  async createNewLanguage(lang) {
    return this.makeRequest('translations', 'createLanguage', {
      lang
    })
  }

  async createTranslation(key, value, languages, app) {
    if (!app) app = null
    return this.makeRequest('translations', 'create', {
      key,
      value,
      languages,
      app
    })
  }

  async removeTranslation(key, app) {
    if (!app) app = null
    return this.makeRequest('translations', 'remove', {
      key,
      app
    })
  }

  async renameTranslationKey(oldKey, newKey, app) {
    if (!app) app = null
    return this.makeRequest('translations', 'rename', {
      oldKey,
      newKey,
      app
    })
  }

  async downloadTranslations(language, app) {
    if (!app) app = null
    return this.makeRequest('translations', 'download', {
      language,
      app
    })
  }

  async downloadAllTranslations(app) {
    if (!app) app = null
    return this.makeRequest('translations', 'downloadAll', {
      app
    })
  }

  async getResourceLinkData(type, value) {
    return this.makeRequest('get', 'resourceLinkData', {
      type,
      value
    })
  }

  async showScheduledReports() {
    return this.makeRequest('get', 'showScheduledReports')
  }

  async securityLogview(details) {
    return this.makeRequest('security', 'logView', {
      details
    })
  }

  async getAuditLogs(limit, offset, filter) {
    return this.makeRequest('security', 'getAuditLog', {
      limit,
      offset,
      filter
    })
  }

  async getAuditLogsTotal(filter) {
    return this.makeRequest('security', 'getAuditLogTotal', {
      filter
    })
  }

  // ----------------------------------------

  async makePublicRequest(type, action, body = {}, isFormData = false) {
    return this.makeRequest(type, action, body, isFormData, true)
  }

  async makeRequest(type, action, body = {}, isFormData = false, isPublic = false) {

    const urlPrefix = isPublic ? 'pub' : 'rest'
    const url = `${this.endpoint}/${urlPrefix}/${type}/${action}/`

    const headers = {}
    if (!isFormData) {
      headers['content-type'] = 'application/json'
    }

    const response = await fetch(url, {
      headers: headers,
      mode: 'cors',
      method: 'post',
      credentials: 'include',
      body: isFormData ? body : JSON.stringify(body)
    })
    if (response.ok) {
      const json = await response.json()
      if (json.error) {
        if (json.type === 'REQUIRED_ERROR') {
          throw new RequiredError(json.message, json.fields)
        }
        if (json.type === 'INVALID_ERROR') {
          throw new InvalidError(json.message, json.errors)
        }
        if (json.user) {
          throw new UserError(json.message, json.data)
        }
        throw new Error(json.message)
      }
      return json.data
    } else {
      const json = await response.json()
      if (response.status === HttpStatus.UNAUTHORIZED) {
        throw new UnauthenticatedError(json.message)
      } else if (response.status === HttpStatus.FORBIDDEN) {
        throw new ForbiddenError(json.message)
      }
    }
    throw new Error(response.statusText)
  }


}
