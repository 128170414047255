import { render, staticRenderFns } from "./RedirectLoginLayout.vue?vue&type=template&id=78b64c40"
import script from "./RedirectLoginLayout.vue?vue&type=script&lang=js"
export * from "./RedirectLoginLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.98.0__lodash@4.17.21_prettier@3.5.3_react@17.0.2_webpack@5.98.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports