<template>
  <Sheet
    :visible="visible"
    :title="$t('Notes')"
    :width="700"
    @close="visible = false"
    padded
  >
    <div id="AccountNotesModal">
      <SkeletonCard v-if="initialLoading"/>
      <Card v-else>
        <CardSection style="padding-bottom: 8px;">
          <TextField
            v-model="filter"
            :placeholder="$t('Filter')"
            @keydown.enter="reloadNotes"
          >
            <template #right>
              <Button
                @click="reloadNotes"
                type="primary"
              >{{$t('Filter')}}</Button>
            </template>
          </TextField>
        </CardSection>
        <ScrollShadow>
          <Tabs
            :tabs="tabs"
            v-model="selected"
          />
        </ScrollShadow>
        <div class="Modal-notes">
          <div v-if="loading" class="Modal-notes-none">{{$t('Loading')}} ...</div>
          <div v-if="!loading && notes.length === 0" class="Modal-notes-none">
            <span v-if="filter && showLoadMore">{{$t('notes.loadMoreFilteringMessage')}}</span>
            <span v-else-if="!loadingMore">{{$t('Nothing to see here')}} ...</span>
          </div>
          <template v-if="notes.length">
            <transition-group :name="'slide'">
              <div
                v-for="note in notes"
                :key="note.id"
                class="note"
              >
                <div class="head">
                  <span class="type">
                    <!-- <Icon :icon="getNoteTypeIcon(note)"/> -->
                    <PageLink v-if="getObjectLink(note)" :url="getObjectLink(note)" external>{{ getNoteTypeName(note) }}</PageLink>
                    <span v-else>{{ getNoteTypeName(note) }}</span>
                    <div><Caption>{{ getNoteType(note) }}</Caption></div>
                  </span>
                  <div class="note-info">
                    <Formatter class="note-date" type="dateTimeShort" :value="note.createdOn" />
                    <div class="note-author">{{ note.createdByName }}</div>
                  </div>
                </div>
                <div class="details">
                  <template v-if="typeof note.notes === 'string'">
                    {{parseSimpleNote(note.objectType, note.notes)}}
                  </template>
                  <template v-else-if="note.objectType === 'DOMAIN' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedDomain')}}: {{note.notes && note.notes.domain}}
                  </template>
                  <template v-else-if="note.objectType === 'ENDPOINT' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedEndpoint')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'STREAM' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedStream')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'CDP_AUTOMATION' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedCdpAutomation')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'CDP_AUTOMATION' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.removedCdpAutomation')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'CUSTOMDATA' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedCustomData')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'SMS' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedSmsMessage')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'SMS' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.deletedSmsMessage')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_VIEW' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedInsightsSettingsView')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_VIEW' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.deletedInsightsSettingsView')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_CLICK' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedInsightsSettingsClick')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_CLICK' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.deletedInsightsSettingsClick')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_STRATEGY' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedInsightsSettingsStrategy')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_STRATEGY' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.deletedInsightsSettingsStrategy')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_FUNNEL' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedInsightsSettingsFunnel')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'INSIGHTS_SETTINGS_FUNNEL' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.deletedInsightsSettingsFunnel')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'MAPPING_EVENT' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedMappingEntry')}}: {{note.notes.fromEventName}} → {{note.notes.toEventName}}
                  </template>
                  <template v-else-if="note.objectType === 'MAPPING_EVENT' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.removedMappingEntry')}}: {{note.notes.fromEventName}} → {{note.notes.toEventName}}
                  </template>
                  <template v-else-if="note.objectType === 'MAPPING_EVENT_ATTRIBUTE' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedMappingEntry')}}: {{note.notes.fromAttributeName}} → {{note.notes.toAttributeName}}
                  </template>
                  <template v-else-if="note.objectType === 'MAPPING_EVENT_ATTRIBUTE' && note.objectAction === 'DELETE'">
                    {{$t('AccountNotes.removedMappingEntry')}}: {{note.notes.fromAttributeName}} → {{note.notes.toAttributeName}}
                  </template>
                  <template v-else-if="note.objectType === 'DATA_DICTIONARY_EVENT_ATTRIBUTE' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedEventAttribute')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'DATA_DICTIONARY_EVENT' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedDictionaryEvent')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'DATA_DICTIONARY_USER_ATTRIBUTE' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedUserAttribute')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'PRIVACY_ID_SCRIPT' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.addedPrivacyIdScript')}}: {{note.objectName}}
                  </template>
                  <template v-else-if="note.objectType === 'SMSSETTINGS' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.createdSmsSettings')}}
                  </template>
                  <template v-else-if="note.objectType === 'MERCH_PRODUCT_DISPLAY' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.createdProductDisplay')}}
                  </template>
                  <template v-else-if="note.objectType === 'MERCH_CATALOG' && note.objectAction === 'CREATE'">
                    {{$t('AccountNotes.createdCatalog')}}
                  </template>
                  <template v-else-if="note.objectType === 'USER' && note.objectAction === 'CREATE'">
                    <template v-if="note.notes && note.notes.isAgency">
                      {{$t('AccountNotes.addedAgencyUser')}}: {{note.objectName}}
                    </template>
                    <template v-else>
                      {{$t('AccountNotes.addedUser')}}: {{note.objectName}}
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'USER' && note.objectAction === 'DELETE'">
                    <template v-if="note.notes && note.notes.isAgency">
                      {{$t('AccountNotes.removedAgencyUser')}}: {{note.objectName}}
                    </template>
                    <template v-else>
                      {{$t('AccountNotes.removedUser')}}: {{note.objectName}}
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'CONVERSIONDEFINITION'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedConversionDefinition')}}
                      <div class="inner-stats">
                        <StatGroup>
                          <Stat :label="$t('ID')" :value="note.notes && note.notes.id"/>
                          <Stat :label="$t('Name')" :value="note.notes && note.notes.name"/>
                          <Stat :label="$t('ConversionDefinitions.ruleX', { value: 1 })" :value="getConversionRuleLabel(note.notes && note.notes.action1Type)"/>
                          <Stat :label="$t('ConversionDefinitions.ruleXReference', { value: 1 })" :value="note.notes && note.notes.action1Reference"/>
                          <Stat v-if="note.notes && note.notes.action2Type" :label="$t('ConversionDefinitions.ruleX', { value: 2 })" :value="getConversionRuleLabel(note.notes && note.notes.action2Type)"/>
                          <Stat v-if="note.notes && note.notes.action2Type" :label="$t('ConversionDefinitions.ruleXReference', { value: 2 })" :value="note.notes && note.notes.action2Reference"/>
                          <Stat v-if="note.notes && note.notes.action3Type" :label="$t('ConversionDefinitions.ruleX', { value: 3 })" :value="getConversionRuleLabel(note.notes && note.notes.action3Type)"/>
                          <Stat v-if="note.notes && note.notes.action3Type" :label="$t('ConversionDefinitions.ruleXReference', { value: 3 })" :value="note.notes && note.notes.action3Reference"/>
                          <Stat :label="$t('ConversionDefinitions.chargeType')" :value="getConvDefChargeType(note.notes)"/>
                          <Stat v-if="note.notes && note.notes.chargeType === 'P'" type="percent" :label="$t('ConversionDefinitions.chargeRate')" :value="note.notes && note.notes.chargeRate / 100"/>
                          <Stat v-else-if="note.notes && note.notes.chargeType === 'C'" type="currency" :currency="theUser ? theUser.accountCurrency: 'USD'" :label="$t('ConversionDefinitions.chargeRate')" :value="note.notes && note.notes.chargeRate"/>
                          <Stat v-else :label="$t('ConversionDefinitions.chargeRate')" value="—"/>
                          <Stat :label="$t('ConversionDefinitions.enforceOrder')" :value="note.notes && note.notes.enforceOrder ? $t('filters.TRUE.label') : $t('filters.FALSE.label')"/>
                        </StatGroup>
                      </div>
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in parseConversionDefinitionNotes(note.notes).other" :key="index" class="simple-note-content">
                        <div v-html="$t('CampaignNotes.fromTo', {
                          attr: parseAttributeForTrans(noteInner.attribute, note.objectType),
                          old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                          new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                        })"/>
                      </div>
                      <template v-if="parseConversionDefinitionNotes(note.notes).rules">
                        <div class="simple-note-content">
                          <b>{{$t('CampaignNotes.rulesUpdated')}}</b>
                          <div class="sub-object">
                            <div class="change-sub-object">
                              <div class="sub-object-text">
                                <b>{{$t('Old')}}</b>
                                <div 
                                  v-for="(oldValue, index) in parseConversionDefinitionNotes(note.notes).rules.old"
                                  :key="index"
                                  class="old"
                                >
                                  <span class="ConversionDefinitionType">{{oldValue.type}}</span>
                                  <span v-if="oldValue.operator"> {{oldValue.operator}}</span>
                                  <span class="ConversionDefinitionReference"> {{oldValue.reference}}</span>
                                </div>
                                <div class="new">
                                  <b>{{$t('New')}}</b> 
                                </div>
                                <div 
                                  v-for="(newValue, index) in parseConversionDefinitionNotes(note.notes).rules.new"
                                  :key="index"
                                >
                                  <span class="ConversionDefinitionType">{{newValue.type}}</span>
                                  <span v-if="newValue.operator"> {{newValue.operator}}</span>
                                  <span class="ConversionDefinitionReference"> {{newValue.reference}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'SHAREDRULESET'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedSharedRuleset', {value: note.objectName})}}
                    </template>
                    <template v-else-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.deletedSharedRuleset', {value: note.objectName})}}
                    </template>
                    <template v-else>
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'RULESET'">
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: $t('Name'),
                            old: getOldValueJson(noteInner).name,
                            new: getNewValueJson(noteInner).name,
                          })"/>
                        </div>
                        <div v-else class="sub-object">
                          <div v-if="getIsBothJson(noteInner)" class="change-sub-object">
                            <div class="rule-text">
                              <rule-summary :rule="getOldValueJson(noteInner)" state="removed"/>
                              <rule-summary :rule="getNewValueJson(noteInner)"/>
                            </div>
                          </div>
                          <div v-else-if="getNewValueJson(noteInner) && !getOldValueJson(noteInner)" class="new-sub-object">
                            <div class="sub-object-text">
                              <rule-summary :rule="getNewValueJson(noteInner)"/>
                            </div>
                          </div>
                          <div v-else-if="!getNewValueJson(noteInner) && getOldValueJson(noteInner)" class="delete-sub-object">
                            <div class="sub-object-text">
                              <rule-summary :rule="getOldValueJson(noteInner)" state="removed"/>
                            </div>
                          </div>
                          <!-- <pre>{{noteInner}}</pre> -->
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'CDP_SETTINGS'">
                    <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                      <div v-if="noteInner.attribute === 'cdp.maintainSessionsForSources.label'" v-html="$t('CampaignNotes.fromTo', {
                        attr: parseAttributeForTrans(noteInner.attribute),
                        old: (getOldValueJson(noteInner) || []).map(s => s.name).join(', ') || `<i>${$t('merch.empty')}</i>`,
                        new: (getNewValueJson(noteInner) || []).map(s => s.name).join(', ') || `<i>${$t('merch.empty')}</i>`,
                      })"/>
                      <div v-else v-html="$t('CampaignNotes.fromTo', {
                        attr: parseAttributeForTrans(noteInner.attribute),
                        old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                        new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                      })"/>
                    </div>
                  </template>
                  <template v-else-if="['INSIGHTS_SETTINGS_VIEW', 'INSIGHTS_SETTINGS_CLICK'].includes(note.objectType)">
                    <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                      <template v-if="getIsBothJson(noteInner)">
                        <strong>{{noteInner.widgetName || '-'}}</strong>: {{noteInner.attribute === 'VARIANTS' ? $t('AccountNotes.variantListUpdated') : $t('AccountNotes.componentListUpdated')}}
                      </template>
                      <template v-else-if="getNewValueJson(noteInner)">
                        {{$t('AccountNotes.addedWidget')}}: {{getNewValueJson(noteInner).name}}
                      </template>
                      <template v-else-if="getOldValueJson(noteInner)">
                        {{$t('AccountNotes.removedWidget')}}: {{getOldValueJson(noteInner).name}}
                      </template>
                      <div v-else v-html="$t('CampaignNotes.fromTo', {
                        attr: parseAttributeForTrans(noteInner.attribute),
                        old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                        new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                      })"/>
                    </div>
                  </template>
                  <template v-else-if="['INSIGHTS_SETTINGS_FUNNEL', 'INSIGHTS_SETTINGS_STRATEGY'].includes(note.objectType)">
                    <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                      <template v-if="getIsBothJson(noteInner)">
                        {{insightsSettingsChangeLabelMap[noteInner.attribute] || '-'}}
                      </template>
                      <div v-else v-html="$t('CampaignNotes.fromTo', {
                        attr: parseAttributeForTrans(noteInner.attribute),
                        old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                        new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                      })"/>
                    </div>
                  </template>
                  <template v-else-if="note.objectType === 'MERCH_PRODUCT_LIST'">
                    <template v-if="note.objectAction === 'NOTE'">
                      <div>{{$t(note.notes.text)}}</div>
                      <List class="list-component">
                        <ListItem v-for="(name, ind) in note.notes.names" :key="ind">{{name}}</ListItem>
                      </List>
                    </template>
                    <template v-else-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedProductList')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute !== 'RULE'">
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                        <div v-else class="sub-object">
                          <div v-if="getIsBothJson(noteInner)" class="change-sub-object">
                            <div class="sub-object-text">
                              <div class="old">
                                <div><b>{{$t('merch.rule')}}</b>: {{$t(`merch.${getOldValueJson(noteInner).rule}.label`)}}</div>
                                <div v-if="getOldValueJson(noteInner).operator"><b>{{$t('merch.operator')}}</b>: {{$t(`filters.operator.${getOldValueJson(noteInner).operator}.label`)}}</div>
                                <div class="merch-value"><b>{{$t('Value')}}</b>: {{parseProductRule(getOldValueJson(noteInner).value1, getOldValueJson(noteInner).rule)}}</div>
                                <div v-if="getOldValueJson(noteInner).value2" class="merch-value"><b>{{$t('valueX', {value:2})}}</b>: {{getOldValueJson(noteInner).value2}}</div>
                              </div>
                              <div class="new">
                                <div><b>{{$t('merch.rule')}}</b>: {{$t(`merch.${getNewValueJson(noteInner).rule}.label`)}}</div>
                                <div v-if="getNewValueJson(noteInner).operator"><b>{{$t('merch.operator')}}</b>: {{$t(`filters.operator.${getNewValueJson(noteInner).operator}.label`)}}</div>
                                <div class="merch-value"><b>{{$t('Value')}}</b>: {{parseProductRule(getNewValueJson(noteInner).value1, getNewValueJson(noteInner).rule)}}</div>
                                <div v-if="getOldValueJson(noteInner).value2" class="merch-value"><b>{{$t('valueX', {value:2})}}</b>: {{getNewValueJson(noteInner).value2}}</div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="getNewValueJson(noteInner) && !getOldValueJson(noteInner)" class="new-sub-object">
                            <div class="sub-object-text">
                              <div><b>{{$t('merch.rule')}}</b>: {{$t(`merch.${getNewValueJson(noteInner).rule}.label`)}}</div>
                              <div v-if="getNewValueJson(noteInner).operator"><b>{{$t('merch.operator')}}</b>: {{$t(`filters.operator.${getNewValueJson(noteInner).operator}.label`)}}</div>
                              <div class="merch-value"><b>{{$t('Value')}}</b>: {{parseProductRule(getNewValueJson(noteInner).value1, getNewValueJson(noteInner).rule)}}</div>
                              <div v-if="getOldValueJson(noteInner).value2" class="merch-value"><b>{{$t('valueX', {value:2})}}</b>: {{getNewValueJson(noteInner).value2}}</div>
                            </div>
                          </div>
                          <div v-else-if="!getNewValueJson(noteInner) && getOldValueJson(noteInner)" class="delete-sub-object">
                            <div class="sub-object-text">
                              <div><b>{{$t('merch.rule')}}</b>: {{$t(`merch.${getOldValueJson(noteInner).rule}.label`)}}</div>
                              <div v-if="getOldValueJson(noteInner).operator"><b>{{$t('merch.operator')}}</b>: {{$t(`filters.operator.${getOldValueJson(noteInner).operator}.label`)}}</div>
                              <div class="merch-value"><b>{{$t('Value')}}</b>: {{parseProductRule(getOldValueJson(noteInner).value1, getOldValueJson(noteInner).rule)}}</div>
                              <div v-if="getOldValueJson(noteInner).value2" class="merch-value"><b>{{$t('valueX', {value:2})}}</b>: {{getOldValueJson(noteInner).value2}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'MERCH_PERSONAL_SHOPPER'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedPersonalShopper')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.deletedPersonalShopper')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'JSON'">
                          <b>{{$t('AccountNotes.definitionUpdated')}}</b>
                        </div>
                        <div v-else-if="noteInner.attribute === 'PUBLISH'">
                          <b>{{$t('AccountNotes.publishedPersonalShopper')}}</b>
                        </div>
                        <div v-else v-html="$t('CampaignNotes.fromTo', {
                          attr: parseAttributeForTrans(noteInner.attribute),
                          old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                          new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                        })"/>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'MERCH_WIDGET_APP'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedWidgetApp')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.deletedWidgetApp')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'JSON'">
                          <b>{{$t('AccountNotes.definitionUpdated')}}</b>
                        </div>
                        <div v-else-if="noteInner.attribute === 'PUBLISH'">
                          <b>{{$t('AccountNotes.publishedWidgetApp')}}</b>
                        </div>
                        <div v-else v-html="$t('CampaignNotes.fromTo', {
                          attr: parseAttributeForTrans(noteInner.attribute),
                          old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                          new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                        })"/>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'PRIVACY_ID_SITE'">
                    <template v-if="note.objectAction === 'NOTE'">
                      <div>{{$t(note.notes.text)}}</div>
                    </template>
                    <template v-else-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedPrivacyIdSite')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'CATEGORY'" class="sub-object">
                          <div style="margin-bottom: 5px;">{{$t('AccountNotes.categoryChanged')}}</div>
                          <div v-if="getNewValueJson(noteInner) && !getOldValueJson(noteInner)" class="new-sub-object">
                            <div class="sub-object-text">
                              <div>{{$t('Name')}}: {{getNewValueJson(noteInner).name}}</div>
                              <div>{{$t('Code')}}: {{getNewValueJson(noteInner).code}}</div>
                            </div>
                          </div>
                          <div v-else-if="!getNewValueJson(noteInner) && getOldValueJson(noteInner)" class="delete-sub-object">
                            <div class="sub-object-text">
                              <div>{{$t('Name')}}: {{getOldValueJson(noteInner).name}}</div>
                              <div>{{$t('Code')}}: {{getOldValueJson(noteInner).code}}</div>
                            </div>
                          </div>
                        </div>
                        <div v-else-if="noteInner.attribute === 'SCRIPT'" class="sub-object">
                          <div style="margin-bottom: 5px;">{{$t('AccountNotes.siteScriptChanged')}}</div>
                          <div v-if="getIsBothJson(noteInner)" class="change-sub-object">
                            <div class="sub-object-text">
                              <div class="old">
                                <div style="font-weight: bold;">{{getOldValueJson(noteInner).name}}</div>
                                <div v-for="(cat, ind) in getOldValueJson(noteInner).categories" :key="ind">
                                  <div>{{$t('Category Name')}}: {{cat.name}}</div>
                                </div>
                              </div>
                              <div class="new">
                                <div style="font-weight: bold;">{{getNewValueJson(noteInner).name}}</div>
                                <div v-for="(cat, ind) in getNewValueJson(noteInner).categories" :key="ind">
                                  <div>{{$t('Category Name')}}: {{cat.name}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="getNewValueJson(noteInner) && !getOldValueJson(noteInner)" class="new-sub-object">
                            <div class="sub-object-text">
                              <div style="font-weight: bold;">{{getNewValueJson(noteInner).name}}</div>
                              <div v-for="(cat, ind) in getNewValueJson(noteInner).categories" :key="ind">
                                <div>{{$t('Category Name')}}: {{cat.name}}</div>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="!getNewValueJson(noteInner) && getOldValueJson(noteInner)" class="delete-sub-object">
                            <div class="sub-object-text">
                              <div style="font-weight: bold;">{{getOldValueJson(noteInner).name}}</div>
                              <div v-for="(cat, ind) in getOldValueJson(noteInner).categories" :key="ind">
                                <div>{{$t('Category Name')}}: {{cat.name}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'SCHEDULED_REPORT'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedScheduledReport')}}: {{note.objectName}}
                    </template>
                    <template v-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.removedScheduledReport')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'FILTERS'">
                          <b>{{$t('AccountNotes.filtersUpdated')}}</b>
                        </div>
                        <div v-else-if="noteInner.attribute === 'FIELDS'">
                          <b>{{$t('AccountNotes.outputFieldsUpdated')}}</b>
                        </div>
                        <div v-else-if="noteInner.attribute === 'SQL'">
                          <b>{{$t('AccountNotes.sqlUpdated')}}</b>
                        </div>
                        <div v-else-if="noteInner.attribute === 'CONFIG'">
                          <b>{{$t('AccountNotes.advancedReportConfigUpdated')}}</b>
                        </div>
                        <div v-else-if="noteInner.attribute === 'FILE_DETAILS'">
                          <b>{{$t('AccountNotes.advancedReportFileUpdated')}}</b>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'AUDIENCE'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedAudience')}}: {{note.objectName}}
                    </template>
                    <template v-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.removedAudience')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'FILTERS'">
                          <b>{{$t('AccountNotes.filtersUpdated')}}</b>
                          <div v-if="(getNewValueJson(noteInner)||{}).datePeriod" class="date-period">
                            {{formatDatePeriod(getNewValueJson(noteInner).datePeriod)}}
                          </div>
                          <template v-if="(getNewValueJson(noteInner)||{}).filters">
                            <div
                              v-for="(filter,ind) in (getNewValueJson(noteInner).filters||[])"
                              class="filter-line"
                              :key="ind"
                            >
                              <FilterText :filter="filter" :locale="currentLocale" />
                            </div>
                          </template>
                        </div>
                        <div v-else-if="noteInner.attribute === 'DESTINATIONS'">
                          <b>{{$t('AccountNotes.cdpAudienceDestinationsUpdated')}}</b>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'PIPELINE'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedPipeline')}}: {{note.objectName}}
                    </template>
                    <template v-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.removedPipeline')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'SOURCES'">
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: $t('cdp.sources'),
                            old: (getOldValueJson(noteInner) || []).map(item => item.name).join(', ') || `<i>${$t('merch.empty')}</i>`,
                            new: (getNewValueJson(noteInner) || []).map(item => item.name).join(', ') || `<i>${$t('merch.empty')}</i>`,
                          })"/>
                        </div>
                        <div v-else-if="noteInner.attribute === 'EVENTS'">
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: $t('Events'),
                            old: (getOldValueJson(noteInner) || []).map(item => item.label).join(', ') || `<i>${$t('merch.empty')}</i>`,
                            new: (getNewValueJson(noteInner) || []).map(item => item.label).join(', ') || `<i>${$t('merch.empty')}</i>`,
                          })"/>
                        </div>
                        <div v-else-if="noteInner.attribute === 'DESTINATION_OPTIONS'">
                          <b>{{$t('AccountNotes.destinationDefinitionUpdated')}}</b>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'EXPORT'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedExport')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.removedExport')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'FILTERS'">
                          <b>{{$t('AccountNotes.filtersUpdated')}}</b>
                          <div v-if="(getNewValueJson(noteInner)||{}).datePeriod" class="date-period">
                            {{formatDatePeriod(getNewValueJson(noteInner).datePeriod)}}
                          </div>
                          <template v-if="(getNewValueJson(noteInner)||{}).filters">
                            <div
                              v-for="(filter,ind) in (getNewValueJson(noteInner).filters||[])"
                              class="filter-line"
                              :key="ind"
                            >
                              <FilterText :filter="filter" :locale="currentLocale" />
                            </div>
                          </template>
                        </div>
                        <div v-else-if="noteInner.attribute === 'FIELDS'">
                          <b>{{$t('AccountNotes.fieldsUpdated')}}</b>
                          <!-- <div
                            v-for="(field,ind) in getNewValueJson(noteInner)"
                            :key="ind"
                          >{{field}}</div> -->
                        </div>
                        <div v-else-if="noteInner.attribute === 'DESTINATIONS'">
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: $t('cdp.destinations'),
                            old: (getOldValueJson(noteInner) || []).map(item => item.name).join(', ') || `<i>${$t('merch.empty')}</i>`,
                            new: (getNewValueJson(noteInner) || []).map(item => item.name).join(', ') || `<i>${$t('merch.empty')}</i>`,
                          })"/>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'CDP_INTEGRATION_DESTINATION'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedDestination')}}: {{note.objectName}}
                    </template>
                    <template v-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.removedDestination')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'DEFINITION'">
                          <b>{{$t('AccountNotes.definitionUpdated')}}</b>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'CDP_INTEGRATION_SOURCE'">
                    <template v-if="note.objectAction === 'CREATE'">
                      {{$t('AccountNotes.addedSource')}}: {{note.objectName}}
                    </template>
                    <template v-if="note.objectAction === 'DELETE'">
                      {{$t('AccountNotes.removedSource')}}: {{note.objectName}}
                    </template>
                    <template v-else-if="note.objectAction === 'UPDATE'">
                      <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                        <div v-if="noteInner.attribute === 'DEFINITION'">
                          <b>{{$t('AccountNotes.definitionUpdated')}}</b>
                        </div>
                        <div v-else>
                          <div v-html="$t('CampaignNotes.fromTo', {
                            attr: parseAttributeForTrans(noteInner.attribute),
                            old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                            new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                          })"/>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="note.objectType === 'DATA_DICTIONARY_EVENT'">
                    <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                      <template v-if="noteInner.attribute === 'cdp.attributes'">
                        <strong>{{$t('AccountNotes.cdp.updatedEventAttributes')}}</strong>
                      </template>
                      <div v-else v-html="$t('CampaignNotes.fromTo', {
                        attr: parseAttributeForTrans(noteInner.attribute),
                        old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                        new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                      })"/>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="(noteInner, index) in note.notes" :key="index" class="simple-note-content">
                      <template
                        v-if="getDoAsJson(getOldValueJson(noteInner), getNewValueJson(noteInner))
                          && typeof getOldValueJson(noteInner) === 'object'
                          && typeof getNewValueJson(noteInner) === 'object'"
                      >
                        <template
                          v-if="(note.objectType === 'ENDPOINT' && note.objectAction === 'UPDATE') && (getOldValueJson(noteInner).espName !== getNewValueJson(noteInner).espName)"
                        >
                          <table class="table table-bordered">
                            <tr>
                              <td class="endpoint-table-cell">
                                <b>{{$t('Old Value')}}</b>
                              </td>
                              <td>
                                <b>{{$t('New Value')}}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="endpoint-table-cell">
                                <pre>{{getOldValueJson(noteInner)}}</pre>
                              </td>
                              <td>
                                <pre>{{getNewValueJson(noteInner)}}</pre>
                              </td>
                            </tr>
                          </table>
                        </template>
                        <template v-else>
                          <b>{{parseAttributeForTrans(noteInner.attribute)}}</b>
                          <table style="margin-top: 2px;" class="table table-bordered change-table">
                            <tr>
                              <td></td>
                              <td><b>{{$t('Old Value')}}</b></td>
                              <td><b>{{$t('New Value')}}</b></td>
                            </tr>
                            <template v-for="(key, i) of Object.keys({ ...getOldValueJson(noteInner), ...getNewValueJson(noteInner) })">
                              <tr v-if="!isEqualOldNew(getOldValueJson(noteInner)[key], getNewValueJson(noteInner)[key])" :key="i">
                                <td>{{niceKey(key)}}</td>
                                <td>{{getOldValueJson(noteInner)[key]}}</td>
                                <td>{{getNewValueJson(noteInner)[key]}}</td>
                              </tr>
                            </template>
                          </table>
                        </template>
                      </template>
                      <template v-else-if="getDoAsJson(getOldValueJson(noteInner), getNewValueJson(noteInner))">
                        <b>{{parseAttributeForTrans(noteInner.attribute)}}</b>
                        <table style="margin-top: 2px;" class="table table-bordered change-table">
                          <template v-for="(key, i) of Object.keys(getNewValueJson(noteInner))">
                            <tr v-if="getNewValueJson(noteInner)[key] !== null && getNewValueJson(noteInner)[key] !== undefined" :key="i">
                              <td>{{niceKey(key)}}</td>
                              <td>{{getNewValueJson(noteInner)[key]}}</td>
                            </tr>
                          </template>
                        </table>
                      </template>
                      <template v-else-if="noteInner.newValue !== undefined">
                        <div v-html="$t('CampaignNotes.fromTo', {
                          attr: parseAttributeForTrans(noteInner.attribute),
                          old: fixValue(noteInner.attribute, noteInner.oldValue, note.objectType),
                          new: fixValue(noteInner.attribute, noteInner.newValue, note.objectType),
                        })"/>
                      </template>
                      <template v-else-if="noteInner.newValue === undefined && noteInner.oldValue === undefined">
                        {{parseAttributeForTrans(noteInner.attribute)}}
                      </template>
                    </div>
                  </template>
                </div>
                <!-- <pre>{{note}}</pre> -->
              </div>
            </transition-group>
          </template>
        </div>
        <div v-if="showLoadMore" class="footer">
          <Button v-if="!loadingMore" :fullWidth="true" @click="loadMoreNotes">{{$t('CampaignNotes.Load More')}}</Button>
          <SkeletonBodyText v-else/>
        </div>
      </Card>
    </div>

  </Sheet>
</template>

<script>
// HACK: This whole file is the biggest hack you can imagine. It is shit. It works. But it is shit.
import ScrollShadow from '../ui/ScrollShadow'
import rules from '../../pages/data/filters/ConversionDefinitionRules.ts'
import {capitalize} from 'lodash'
import {getAllDatePeriodOptions} from '../../cdp/datePeriodUtils'
import {dayjs} from '../../classes/utils/DateUtil'
const {formatDatePeriod} = window.CdpLib.utils

const ELEMENTS_SHOWN = 50

function parseNotes(notes) {
  const rv = []
  if (!notes) return rv
  for (const note of notes) {
    let n = note
    try {
      const obj = JSON.parse(note.notes)
      n.notes = obj
    } catch (e) {
      rv.push(note)
      continue
    }
    rv.push(n)
  }
  return rv
}
export default {
  name: 'AccountNotesSheet',
  data() {
    return {
      visible: false,
      notes: [],
      languages: [],
      loading: false,
      initialLoading: true,
      loadingMore: false,
      selected: 'ALL',
      filter: '',
      newNote: '',
      conversionRules: null,
      // options: {},
      saving: false,
      // initialsColor: {},
      limit: ELEMENTS_SHOWN
    }
  },
  created() {
    this.eventHub.$on('showAccountNotes', (type = 'ALL', filter = '') => {
      this.notes = []
      this.languages = []
      this.accountRulesets = []
      this.visible = true
      this.limit = ELEMENTS_SHOWN
      this.filter = filter ? `#${filter}` : ''
      this.selected = type
      this.$watch('selected', this.reloadNotes)
      this.$ui.progress.start()
      this.initialLoading = true
      const apiCalls = [
        this.api.getAccountNotes(this.limit, this.selected, this.filter),
        this.api.getSessionKey(),
        this.api.getLanguageList(),
        this.theUser && this.theUser.isSmartEnabled ? this.api.getAccountRulesetsForSegmentation() : [],
      ]
      Promise.all(apiCalls)
        .then((response) => {
          const [notes, sessionKey, langs, accountRulesets] = response
          this.notes = parseNotes(notes.data || notes)
          this.languages = langs
          if (this.theUser && this.theUser.isSmartEnabled) {
            this.conversionRules = rules.map(dr => {
              return {
                ...dr,
                label: this.$t(dr.label),
                description: this.$t(dr.description)
              }
            })

            window.RulesetEditorLoader
              .load()
              .then(lib => {
                this.$options.components.RuleSummary = lib.createRuleSummaryComponent({
                  locale: this.theUser.defaultLanguage,
                  authKey: sessionKey,
                  campaignId: 0,
                  accountRulesets
                })
                this.initialLoading = false
              })
          } else {
            this.conversionRules = []
            this.accountRulesets = []
            this.initialLoading = false
          }
        })
        .catch(this.apiCatch)
        .finally(() => {
          this.$ui.progress.done()
        })
    })
  },
  beforeDestroy() {
    this.eventHub.$off('showAccountNotes')
  },
  computed: {
    showLoadMore() {
      return !this.loading && this.notes.length && this.notes.length >= this.limit
    },
    insightsSettingsChangeLabelMap() {
      return {
        'CAMPAIGNS': this.$t('AccountNotes.strategyCampaignsChange'),
        'SEGMENTS': this.$t('AccountNotes.strategySegmentsChange'),
        'VIEWS': this.$t('AccountNotes.strategyViewsChange'),
        'CLICKS': this.$t('AccountNotes.strategyClicksChange'),
        'CONVERSIONDEFINITIONS': this.$t('AccountNotes.conversionDefinitionsChange'),
        'STEPS': this.$t('AccountNotes.funnelStepsChange')
      }
    },
    tabs() {
      const tabs = [
        { id: 'ALL', label: this.$t('All') },
        { id: 'ACCOUNT', label: this.$t('Account')},
        { id: 'USER', label: this.$t('Users')},
      ]
      if (this.theUser && this.theUser.isAdmin) {
        tabs.push({ id: 'ACCOUNTSECURITY', label: this.$t('Security') })
      }
      if (this.theUser && this.theUser.isSmartEnabled) {
        tabs.push(...[
          { id: 'STREAM', label: this.$t('Streams') },
          { id: 'DOMAIN', label: this.$t('Domains') },
          { id: 'INSIGHTS_SETTINGS', label: this.$t('insightsSettings') },
          { id: 'ENDPOINT', label: this.$t('Endpoints') },
          { id: 'WIDGETREVISION', label: this.$t('AccountNotes.widgetRevisions')},
          { id: 'CUSTOMDATA', label: this.$t('Custom Data') },
          { id: 'SHAREDRULESET', label: this.$t('Shared Rulesets') },
        ])
        if (this.theUser.isSuper) {
          tabs.push({ id: 'SCHEDULED_REPORT', label: this.$t('Scheduled Reports') })
        }
        if (this.theUser.hasConversionDefinitionAccess) {
          tabs.push({ id: 'CONVERSIONDEFINITION', label: this.$t('Conversion Definitions') })
        }
        if (this.theUser && this.theUser.smsEnabled && this.theUser.hasSmsAccess) {
          tabs.push({ id: 'SMS', label: this.$t('SMS') })
        }
        if (this.theUser && this.theUser.recommendationsEnabled && this.theUser.hasRecommendationsAccess) {
          tabs.push({ id: 'MERCHANDISING', label: this.$t('merch.merchandising') })
        }
      }
      if (this.theUser && this.theUser.hasPrivacyIdAccess && this.theUser.privacyIdEnabled) {
        tabs.push({ id: 'PRIVACY_ID', label: this.$t('PrivacyID')})
      }
      if (this.theUser && this.theUser.isCdpEnabled && this.theUser.isSuper) {
        if (this.theUser.hasCdpAccess.automations) tabs.push({ id: 'CDP_AUTOMATION', label: this.$t('streamApp.common.automations') })
        if (this.theUser.hasCdpAccess.audiences) tabs.push({ id: 'AUDIENCE', label: this.$t('cdp.audiences') })
        if (this.theUser.hasCdpAccess.pipelines) tabs.push({ id: 'PIPELINE', label: this.$t('cdp.pipelines') })
        if (this.theUser.hasCdpAccess.integrations) tabs.push({ id: 'CDP_INTEGRATION', label: this.$t('AccountEndpoints.Integrations') })
        if (this.theUser.hasCdpAccess.exports) tabs.push({ id: 'EXPORT', label: this.$t('cdp.exports') })
        if (this.theUser.hasCdpAccess.dataDictionary) tabs.push({ id: 'DATA_DICTIONARY', label: this.$t('cdp.dataDictionary') })
        if (this.theUser.hasCdpAccess.mapping) tabs.push({ id: 'MAPPING', label: this.$t('cdp.mapping') })
      }
      return tabs
    }
  },
  methods: {
    formatDatePeriod(datePeriod) {
      return formatDatePeriod(datePeriod, getAllDatePeriodOptions())
    },
    parseProductRule(value, rule) {
      if (['STOCK_STATUS'].includes(rule)) {
        return value ? value.split(',').map(v => this.$t(`merch.${v.trim()}.label`)).join(', ') : ''
      }
      return value
    },
    reloadNotes() {
      this.loading = true
      this.notes = []
      this.api.getAccountNotes(this.limit, this.selected, this.filter)
        .then((response) => {
          this.notes = parseNotes(response.data || response)
          this.loading = false
        })
        .catch(this.apiCatch)
    },
    loadMoreNotes() {
      this.limit += ELEMENTS_SHOWN
      this.loadingMore = true
      this.api.getAccountNotes(this.limit, this.selected, this.filter)
        .then((response) => {
          this.notes = parseNotes(response.data || response)
          this.loadingMore = false
        })
        .catch(this.apiCatch)
    },
    getObjectLink(note) {
      if (note.objectType === 'CONVERSIONDEFINITION') {
        return `/#/account/conversion-definitions`
      }
      if (note.objectType === 'SHAREDRULESET' && note.objectAction !== 'DELETE') {
        return `/#/account/sharedRulesets/edit/${note.objectId}`
      }
      if (note.objectType === 'SMS' && note.objectAction !== 'DELETE') {
        return `/#/messaging/sms/edit/id/${note.objectId}`
      }
      if (note.objectType === 'MERCH_PRODUCT_DISPLAY' && note.objectAction !== 'DELETE') {
        return `/#/merchandising/product-displays/${note.objectId}/edit`
      }
      if (note.objectType === 'MERCH_CATALOG' && note.objectAction !== 'DELETE') {
        return `/#/merchandising/product-catalogs/catalog/${note.objectId}/edit`
      }
      if (note.objectType === 'MERCH_PRODUCT_LIST' && (note.objectAction === 'CREATE' || note.objectAction === 'UPDATE')) {
        return `/#/merchandising/product-lists/${note.objectId}/edit`
      }
      if (note.objectType === 'MERCH_PERSONAL_SHOPPER') {
        return `/#/merchandising/personal-shoppers`
      }
      if (note.objectType === 'MERCH_WIDGET_APP' && note.objectAction !== 'DELETE') {
        return `/#/merchandising/widgets`
      }
      if (note.objectType === 'STREAM' && note.objectAction !== 'DELETE') {
        return `/#/streams/streams/edit/${note.objectId}/`
      }
      if (note.objectType === 'CDP_AUTOMATION' && note.objectAction !== 'DELETE') {
        return `/#/cdp/automations/edit/${note.objectId}/`
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_VIEW' && note.objectAction !== 'DELETE') {
        return `/#/insights-settings/view/edit/${note.objectId}/`
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_CLICK' && note.objectAction !== 'DELETE') {
        return `/#/insights-settings/click/edit/${note.objectId}/`
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_STRATEGY' && note.objectAction !== 'DELETE') {
        return `/#/insights-settings/strategy/edit/${note.objectId}/`
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_FUNNEL' && note.objectAction !== 'DELETE') {
        return `/#/insights-settings/funnel/edit/${note.objectId}/`
      }
      if (note.objectType === 'SCHEDULED_REPORT' && note.objectAction !== 'DELETE') {
        return `/#/account/scheduledReports/edit/${note.objectId}/`
      }
      if (note.objectType === 'AUDIENCE' && note.objectAction !== 'DELETE') {
        return `/#/cdp/audiences/edit/${note.objectId}/`
      }
      if (note.objectType === 'PIPELINE' && note.objectAction !== 'DELETE') {
        return `/#/cdp/pipelines/edit/${note.objectId}/`
      }
      if (note.objectType === 'CDP_INTEGRATION_DESTINATION' && note.objectAction !== 'DELETE') {
        return `/#/cdp/integrations/destination/edit/${note.objectId}/`
      }
      if (note.objectType === 'CDP_INTEGRATION_SOURCE' && note.objectAction !== 'DELETE') {
        return `/#/cdp/integrations/source/edit/${note.objectId}/`
      }
      if (note.objectType === 'EXPORT' && note.objectAction !== 'DELETE') {
        return `/#/cdp/exports/edit/${note.objectId}/`
      }
      return ''
    },
    getNoteTypeName(note) {
      const transMap = [
        'AccountNotes.evaluation'
      ]
      if (note.objectName) {
        if (transMap.includes(note.objectName)) {
          return this.$t(note.objectName)
        }
        return note.objectName
      }
      if (note.objectType === 'CONVERSIONDEFINITION') {
        return this.$t('Conversion Definition')
      }
      if (note.objectType === 'WIDGETREVISION') {
        return `#${note.objectId} — ${this.$t('Widget Revision')}`
      }
      if (note.objectType === 'ACCOUNT' || note.objectType === 'ACCOUNTPREFERENCES' || note.objectType === 'CDP_SETTINGS') {
        return this.$t('Account')
      }
      if (note.objectType === 'DOMAIN') {
        return this.$t('Domain')
      }
      if (note.objectType === 'ENDPOINT') {
        return this.$t('Endpoint')
      }
      if (note.objectType === 'STREAM') {
        return this.$t('Stream')
      }
      if (note.objectType === 'CDP_AUTOMATION') {
        return this.$t('streamApp.common.automation')
      }
      if (note.objectType === 'CUSTOMDATA') {
        return this.$t('Custom Data')
      }
      if (note.objectType === 'SHAREDRULESET') {
        return this.$t('Shared Ruleset')
      }
      if (note.objectType === 'USER') {
        return this.$t('User')
      }
      if (note.objectType === 'SMS') {
        return this.$t('SMS')
      }
      if (note.objectType === 'SMSSETTINGS') {
        return this.$t('SMS Settings')
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_VIEW') {
        return this.$t('insightsSettings.view')
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_STRATEGY') {
        return this.$t('insightsSettings.strategy')
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_CLICK') {
        return this.$t('insightsSettings.click')
      }
      if (note.objectType === 'INSIGHTS_SETTINGS_FUNNEL') {
        return this.$t('insightsSettings.funnel')
      }
      if (note.objectType === 'SCHEDULED_REPORT') {
        return this.$t('Scheduled Report')
      }
      if (note.objectType === 'AUDIENCE') {
        return this.$t('cdp.audience')
      }
      if (note.objectType === 'PIPELINE') {
        return this.$t('cdp.pipeline')
      }
      if (note.objectType === 'CDP_INTEGRATION_DESTINATION') {
        return this.$t('cdp.destination')
      }
      if (note.objectType === 'CDP_INTEGRATION_SOURCE') {
        return this.$t('cdp.source')
      }
      if (note.objectType === 'EXPORT') {
        return this.$t('Export')
      }
      if (note.objectType === 'DATA_DICTIONARY_EVENT') {
        return this.$t('cdp.dictionaryEvent')
      }
      if (note.objectType === 'DATA_DICTIONARY_EVENT_ATTRIBUTE') {
        return this.$t('cdp.dictionaryEventAttribute')
      }
      if (note.objectType === 'DATA_DICTIONARY_USER_ATTRIBUTE') {
        return this.$t('cdp.dictionaryUserAttribute')
      }
      if (note.objectType === 'MAPPING_EVENT') {
        return this.$t('cdp.eventMapping')
      }
      if (note.objectType === 'MAPPING_EVENT_ATTRIBUTE') {
        return this.$t('cdp.eventAttributeMapping')
      }
      return this.$t('Unknown')
    },
    getNoteType(note) {
      const t = this.tabs.find(t => t.id === note.objectType)
      const generalTabMap = {
        'INSIGHTS_SETTINGS_VIEW': this.$t('insightsSettings.insightsSettingsViews'),
        'INSIGHTS_SETTINGS_CLICK': this.$t('insightsSettings.insightsSettingsClicks'),
        'INSIGHTS_SETTINGS_STRATEGY': this.$t('insightsSettings.insightsSettingsStrategies'),
        'INSIGHTS_SETTINGS_FUNNEL': this.$t('insightsSettings.insightsSettingsFunnels'),
        'SMS': this.$t('SMS'),
        'SMSSETTINGS': this.$t('SMS Settings'),
        'MERCH_PRODUCT_LIST': this.$t('merch.productLists'),
        'MERCH_PRODUCT_DISPLAY': this.$t('merch.productDisplays'),
        'MERCH_CATALOG': this.$t('merch.productCatalogs'),
        'MERCH_PERSONAL_SHOPPER': this.$t('merch.personalShopper'),
        'MERCH_WIDGET_APP': this.$t('Widgets'),
        'PRIVACY_ID_SITE': this.$t('privacyId.privacyIdSite'),
        'PRIVACY_ID_SCRIPT': this.$t('privacyId.privacyIdScript'),
        'ACCOUNT': this.$t('Account'),
        'ACCOUNTPREFERENCES': this.$t('Preferences'),
        'CDP_SETTINGS': this.$t('Preferences'),
        'DATA_DICTIONARY_EVENT': this.$t('cdp.dictionaryEvent'),
        'DATA_DICTIONARY_EVENT_ATTRIBUTE': this.$t('cdp.dictionaryEventAttribute'),
        'DATA_DICTIONARY_USER_ATTRIBUTE': this.$t('cdp.dictionaryUserAttribute'),
        'MAPPING_EVENT': this.$t('cdp.eventMapping'),
        'MAPPING_EVENT_ATTRIBUTE': this.$t('cdp.eventAttributeMapping'),
        'CDP_INTEGRATION_DESTINATION': this.$t('cdp.destination'),
        'CDP_INTEGRATION_SOURCE': this.$t('cdp.source'),
      }
      return generalTabMap[note.objectType] || (t && t.label) || '?'
    },
    getConversionRuleLabel(rule) {
      const foundRule = this.conversionRules && this.conversionRules.find(r => r.id === rule)
      return foundRule && foundRule.label || ''
    },
    parseConversionDefinitionNotes(notes) {
      let ruleNotes = null
      const otherNotes = []
      const formatActionValue = (obj) => {
        if (!obj) return null
        // see if operator should be shown in rule text
        const rule = this.conversionRules && this.conversionRules.find(r => r.id === obj.type)
        const operator = rule && !rule.hideOperatorsInList 
          ? rule.operators && rule.operators[0] && rule.operators[0].value
          : ''

        // try to format options in rule text (if they exist)
        let reference = (obj.reference || '')
          .split(',')
          .map(r => {
            const option = (rule && rule.options || []).find(o => o.value === r.trim())
            return option && this.$t(option.label)
          })
          .filter(r => !!r)
          .join(', ')
        reference = reference || obj.reference

        // render placeholder if it exists  
        if (!reference && rule && rule.valuePlaceholder) {
          reference = this.$t(rule.valuePlaceholder)
        }
        return {
          type: rule && rule.label || obj.type,
          operator: operator && this.$t(`filters.operator.${operator}.label`).toLowerCase() || '',
          reference: reference || ''
        }
      }
      notes.forEach(note => {
        if (note.attribute === 'actions') {
          const newArr = this.getNewValueJson(note)
          const oldArr = this.getOldValueJson(note)
          ruleNotes = {
            new: (newArr || []).map(newValue => formatActionValue(newValue)).filter(a => !!a),
            old: (oldArr || []).map(oldValue => formatActionValue(oldValue)).filter(a => !!a),
          }
        } else if (![
          'action1Type', 
          'action1Reference', 
          'action2Type', 
          'action2Reference', 
          'action3Type', 
          'action3Reference'
        ].includes(note.attribute)) {
          otherNotes.push(note)
        }
      })
      return {
        other: otherNotes,
        rules: ruleNotes
      }
    },
    getConvDefChargeType(notes) {
      if (!notes) return '—'
      if (notes.chargeType === 'P') {
        return this.$t('ConversionDefinitions.Percent')
      }
      if (notes.chargeType === 'C') {
        return this.$t('ConversionDefinitions.Fixed')
      }
      return '—'
    },
    shouldShowDelete(note) {
      if (this.theUser && note.objectType === 'NOTES' && dayjs.tz(dayjs(), this.theUser.accountTimezone).diff(dayjs.tz(note.createdOn, this.theUser.accountTimezone)) / 1000 / 60 < 10) {
        return true
      }
      return false
    },
    isEqualOldNew(oldValue, newValue) {
      if (oldValue === null || oldValue === undefined) oldValue = ''
      if (newValue === null || newValue === undefined) newValue = ''
      return JSON.stringify(oldValue) === JSON.stringify(newValue)
    },
    parseSimpleNote(objectType, note){
      let returnNoteStr = null
      try {
        returnNoteStr = decodeURIComponent(note.replace(/\\n/g, '<br />'))
      } catch (e) {
        returnNoteStr = note.replace(/\\n/g, '<br />')
      }
      returnNoteStr = this.$t(returnNoteStr) //translates any messages coming from DB
      if (objectType === 'SHAREDRULESET') {
        returnNoteStr = returnNoteStr.replace(/^Added shared ruleset/, this.$t('CampaignNotes.Added shared ruleset'))
        returnNoteStr = returnNoteStr.replace(/^Deleted shared ruleset/, this.$t('CampaignNotes.Deleted shared ruleset'))
      }
      return returnNoteStr
    },
    niceKey(val) {
      return capitalize(val)
    },
    parseAttributeForTrans(attribute, objectType = null) {
      if (attribute === 'instanceCollisionBehavior') {
        return this.$t('streamApp.common.instanceCollisionBehavior.title')
      }
      if (attribute === 'listName') {
        return this.$t('merch.productList')
      }
      if (objectType === 'CONVERSIONDEFINITION') {
        const map = {
          'isFPAttribution': 'ConversionDefinitions.isFPAttribution',
          'name': 'Name',
          'termLineItemId': 'ConversionDefinitions.termLineItem',
          'chargeType': 'ConversionDefinitions.chargeType',
          'chargeRate': 'ConversionDefinitions.chargeRate',
          'minCharge': 'ConversionDefinitions.minimumCharge',
          'maxCharge': 'ConversionDefinitions.maximumCharge',
          'enforceOrder': 'ConversionDefinitions.enforceOrder',
          'category': 'ConversionDefinitions.Category',
          'siteId': 'ConversionDefinitions.forDomain'
        }
        if (map[attribute]) {
          return this.$t(map[attribute])
        }
      }
      return this.$t(`${attribute}`)
    },
    getDoAsJson(oldValueJson, newValueJson) {
      let doAsJson = false
      if (oldValueJson && newValueJson && (typeof oldValueJson === 'object')) {
        doAsJson = true
      }
      return doAsJson
    },
    getIsBothJson(noteInner) {
      let isBothJson = true
      try {
        JSON.parse(noteInner.oldValue)
      } catch(e) {
        isBothJson = false
      }
      try {
        JSON.parse(noteInner.newValue)
      } catch(e) {
        isBothJson = false
      }
      return isBothJson
    },
    getOldValueJson(noteInner) {
      let oldValueJson
      try {
        oldValueJson = JSON.parse(noteInner.oldValue)
      } catch (e) {
        return ''
      }
      return oldValueJson
    },
    getNewValueJson(noteInner) {
      let newValueJson
      try {
        newValueJson = JSON.parse(noteInner.newValue)
      } catch (e) {
        return ''
      }
      return newValueJson
    },
    niceRuleValue(val) {
      if (val === true) {
        return this.$t('TRUE')
      }
      if (val === false) {
        return this.$t('FALSE')
      }
      if (val === 0) {
        return '0'
      }
      if (!val) {
        return ''
      }
      return val
    },
    fixValue(attribute, value, objectType = null) {
      const freq = {
        'Off': this.$t('AccountNotes.off'),
        'Weekly': this.$t('AccountNotes.weekly'),
        'Monthly': this.$t('AccountNotes.monthly'),
        'Weekly and Monthly': this.$t('AccountNotes.weeklyAndMonthly'),
      }
      const trans = {
        'campaignUpdateFrequency': freq, // reverse compat
        'Campaign Update Frequency': freq,
        'Campaign Update Email Template': {
          'services': this.$t('AccountPreferences.services'),
          'retail': this.$t('AccountPreferences.retail')
        },
        'listName': {
          'merch.allProducts': this.$t('merch.allProducts')
        }
      }
      if (value === null || value === undefined || value === '') {
        return `<i>${this.$t('merch.empty')}</i>`
      }
      if (typeof(value) === 'string' && objectType === 'MERCH_PRODUCT_DISPLAY') {
        if (['model', 'Model'].includes(attribute)) {
          if (value === 'RANKING-NONE') return this.$t('None')
          /**
           * Slight hack.. Should really be using actual 
           * names from account_products.placements (models), but instead
           * using existing creative translations to get labels used in creative
           */
          const modelRenameMap = {
            'recommended_for_you': 'RFY',
            'others_you_may_like': 'OYML',
            'recently_viewed_default': 'RV',
            'frequently_bought_together': 'FBT',
          }
          return this.$t(`creative.productRec.${modelRenameMap[value] || value}.label`)
        } else if (['sortBy', 'Sort By'].includes(attribute)) {
          return this.$t(`merch.${value}.label`)
        }
      }
      if (typeof(value) === 'string' && objectType === 'MERCH_CATALOG') {
        if (attribute === 'merch.importMode') {
          return this.$t(`merch.importMode.${value}.label`)
        }
      }
      if (typeof(value) === 'boolean') {
        return this.$t(value ? 'filters.TRUE.label' : 'filters.FALSE.label')
      }
      if (typeof(value) === 'string' && attribute === 'instanceCollisionBehavior' && objectType === 'CDP_AUTOMATION') {
        return this.$t(`streamApp.common.${value}.title`)
      }
      if (typeof(value) === 'string' && attribute === 'Event Type' && objectType === 'CDP_AUTOMATION') {
        return this.$t(`streamApp.trigger.type.${value}.label`)
      }
      if (typeof(value) === 'string' && attribute === 'scheduledReports.period' && objectType === 'SCHEDULED_REPORT') {
        const map = {
          'DAY': this.$t('Daily'),
          'WEEK': this.$t('Weekly'),
          'MONTH': this.$t('Monthly'),
          'QUARTER': this.$t('Quarterly'),
          'NEVER': this.$t('Never')
        }
        return map[value] || value
      }
      if (typeof(value) === 'string' && ['Campaign Update Email Default Language', 'Default Language', 'Language'].includes(attribute)) {
        const langObj = this.languages.find(l => l.value === (value || '').toLowerCase())
        return langObj && langObj.label || ''
      }
      if (typeof(value) === 'string' && attribute === 'Translation Languages') {
        let arr = []
        try {
          arr = value ? value.split(',') : []
        } catch {
          //nothing
        }
        return arr.map(lang => {
          const langObj = this.languages.find(l => l.value === lang)
          return langObj && langObj.label || ''
        }).join(', ')
      }
      if (typeof(value) === 'string' && attribute === 'Status') {
        return this.$t(capitalize(value))
      }
      if (typeof(value) === 'string' && objectType === 'SMS') {
        if (attribute === 'Scheduled Date') {
          return this.$ui.format(value, 'dateShort')
        } else if (attribute === 'Scheduled Time') {
          return dayjs(value, ['HH:mm:ss']).format('LT')
        } else if (['Segments Start Date', 'Segments End Date'].includes(attribute)) {
          return this.$ui.format(value, 'dateTimeShort')
        }
        return this.$t(capitalize(value))
      }
      if (typeof(value) === 'string' && ['INSIGHTS_SETTINGS_VIEW', 'INSIGHTS_SETTINGS_CLICK'].includes(objectType) && attribute === 'Type') {
        const insightsTypeMap = {
          'offer': this.$t('Offer'),
          'display': this.$t('merch.productDisplay'),
          'productRec': this.$t('streamApp.common.Product Recommendation'),
          'webPush': this.$t('insightsSettings.click.webPushRequest'),
        }
        return insightsTypeMap[value] || ''
      }
      if (typeof(value) === 'string' && ['DATA_DICTIONARY_EVENT_ATTRIBUTE','DATA_DICTIONARY_USER_ATTRIBUTE'].includes(objectType) && attribute === 'PII Restriction') {
        const map = {
          'low': this.$t('creative.sensitivity.low'),
          'medium': this.$t('creative.sensitivity.medium'),
          'high': this.$t('creative.sensitivity.high'),
        }
        return map[value] || ''
      }
      if (
        typeof(value) === 'string' 
        && objectType === 'DATA_DICTIONARY_EVENT' 
        && ['cdp.missingAttributeBehavior.label', 'cdp.unexpectedAttributeBehavior.label', 'cdp.invalidAttributeDatatypeBehavior.label'].includes(attribute)
      ) {
        return this.$t(`cdp.dictionary.behavior.${value}.label`)
      }
      if (
        typeof(value) === 'string' 
        && ['DATA_DICTIONARY_USER_ATTRIBUTE', 'DATA_DICTIONARY_EVENT_ATTRIBUTE'].includes(objectType) 
        && attribute === 'DataType'
      ) {
        return this.$t(`cdp.dictionary.datatype.${value}`)
      }
      if (typeof(value) === 'string' && objectType === 'CONVERSIONDEFINITION') {
        if (attribute === 'chargeType') {
          return this.getConvDefChargeType({ chargeType: value })
        }
      }
      if (typeof(value) === 'string' && trans[attribute] && trans[attribute][value]) {
        return trans[attribute][value]
      }
      if (typeof(value) === 'object') {
        return JSON.stringify(value)
      }
      return value
    }
  },
  components: {
    ScrollShadow,
    FilterText: window.CdpLib.components.FilterText
  }
}
</script>

<style lang="scss">
#AccountNotesModal {
  font-size: 14px;
  .Modal-notes {
    // height: 50vh;
    overflow: hidden;
    // border: 1px solid #ccc;
    // overflow-y: scroll;
    .delete {
      margin-left: 15px;
      margin-top: -2px;
      cursor: pointer;
    }
    .note {
      hr {
        width: 80%;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .head {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 10px;
        border-top: 1px solid #ddd;
        .type {
          flex: 1;
        }
        .note-info {
          color: #666;
          font-size: 80%;
          text-align: right;
        }
      }
      .details {
        margin: 15px;
        font-size: 110%;
        .list-component {
          margin-top: 10px;
        }
        .inner-stats {
          margin: 10px;
        }
        .simple-note-content:first-of-type {
          margin-top: 0px;
        }
        .simple-note-content {
          margin-top: 8px;
          .date-period {
            margin-top: 8px;
            color: #0070bb;
          }
        }
      }
    }
  }

  .filter-line {
    margin-top: 5px;
  }

  .footer {
    padding: 10px 15px;
    border-top: 1px solid #ccc;
  }
  .red {
    color: red;
  }

  ul > li {
    margin-top: 8px;
  }
  ul > li:first-of-type {
    margin-top: 0px;
  }

  .endpoint-table-cell {
    max-width: 296px;
    vertical-align: top;
    pre {
      overflow-x: auto;
      white-space: pre-wrap;
    }
  }

  .sub-object:first-of-type {
    margin-top: 5px;
  }
  .sub-object {
    .delete-sub-object {
      margin-bottom: 1px;
      background: #fef0ef;
      border-left: 4px solid #f01800;
      text-decoration: line-through;
    }
    .new-sub-object {
      margin-bottom: 1px;
      background: #e9f3e9;
      border-left: 4px solid green;
    }
    .change-sub-object {
      margin-bottom: 1px;
      background: #edf6ff;
      border-left: 4px solid #026ebb;
    }
    .sub-object-text {
      font-size: 14px;
      padding: 8px 16px 8px 12px;
      .merch-value {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .old {
        text-decoration: line-through;
      }
      .new {
        margin-top: 10px;
      }
    }
    .ConversionDefinitionType {
      color: green;
    }
    .ConversionDefinitionReference {
      color: purple;
    }
  }


  .Modal-notes-none {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 120%;
    color: #666;
  }
  table.change-table {
    margin-top: 5px;
    // margin-left: -9px;
    font-size: 90%;
    border-radius: 0px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    td {
      padding: 3px;
      vertical-align: top;
      line-height: 22px;
      text-align: left;
    }
  }
}
</style>
